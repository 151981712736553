//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
