//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    buttonType: {
      type: String,
      default: () => "confirm",
    },
    productOptions: {
      type: Object,
      default: () => null,
    },
    selectedOptionIdObj: {
      type: Array,
      default: () => [], //选中的option --主要用于显示 设置update 默认值
    },
    showOptionName: {
      type: String,
      default: () => "",
    },
    qtyNum: {
      type: Number,
      default: () => 1,
    },
    // isAddToCart:{
    //   type: Boolean,
    // },
  },
  name: "OptionsProductDialog",
  data() {
    return {
      isShowDialog: false,
      selectColorIndex: null, //选中的颜色索引
      selectOtherIndex: {},
      tempColorOptions: {},
      tempOtherOptions: [],
      // qtyNum: 1, //qty 数量
      unSelectedOptionObj: [], //未选中的必选option --用于显示必选提示
      optionBigGallery: {
        preventClicks: true,
        autoplay: {
          delay: 3000,
        },
        speed: 1000,
        loop: false,
        autoplayDisableOnInteraction: false,
        on: {},
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        spaceBetween: 10,
      },
      selectedOptionIdArr: [],
      qtyNumD: this.qtyNum,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    selectedOptionPrice() {
      let selectedOptionIdList = [];
      let tempPriceObj = {};
      console.log(this.selectedOptionIdArr, "selectedOptionIdArrselectedOptionIdArr");
      if (this.selectedOptionIdArr.length > 0) {
        selectedOptionIdList = this.selectedOptionIdArr;
        selectedOptionIdList.sort();
        let tempIdStr = selectedOptionIdList.join("-");
        tempPriceObj = this.productOptions.allOptionVariants.find((item) => item.sortedOptionValueId === tempIdStr);
        return tempPriceObj;
      } else {
        for (let i in this.selectedOptionIdObj) {
          let optionItem = this.productOptions.options[i];
          let tempItem = optionItem.values[this.selectedOptionIdObj[i]];
          selectedOptionIdList.push(tempItem.id); //拿到目前选中的optionid
        }
        selectedOptionIdList.sort();
        let tempIdStr = selectedOptionIdList.join("-");
        tempPriceObj = this.productOptions.allOptionVariants.find((item) => item.sortedOptionValueId === tempIdStr);
        return tempPriceObj;
      }
    },
    allOptionZero() {
      let allZero = this.productOptions?.allOptionVariants.filter((item) => item.optionVariantQty == 0);
      return allZero;
    },
    // tempOtherOptions() {
    //   return this.productOptions.options.map((item, index) => {
    //     return {
    //       option_id: item.values[this.selectedOptionIdObj[index]].optionId,
    //       optionName: item.values[this.selectedOptionIdObj[index]].optionName,
    //       option_value_id: item.values[this.selectedOptionIdObj[index]].id,
    //       optionValueTitle: item.values[this.selectedOptionIdObj[index]].optionValueTitle,
    //     };
    //   });
    // },
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapActions("cart", ["getCartList"]),
    // 方法
    // 改变弹窗
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
    },
    /**
     * @Date: 2023-06-14 14:45:39
     * @Description: 更新属性
     * @return {*}
     */
    update() {
      if (this.buttonType == "confirm") {
        //代表是编辑
        let params = {
          id: this.productOptions.quoteId,
          item_id: this.productOptions.itemId,
          product_id: this.productOptions.id,
          modify_qty: this.qtyNumD,
        };
        // 这一段用来更新修改选中的 option，可以优化
        this.tempOtherOptions = params.options = this.tempOtherOptions[0]?.option_id ? this.tempOtherOptions : this.selectedOptionIdObj;
        this.$emit("updateCart", params);
      } else {
        //代表是加购
        this.addCart();
      }
    },
    /**
     * @Author: liudanli
     * @Date: 2023-06-27 13:59:44
     * @LastEditTime: Do not edit
     * @description: 加购处理
     * @return {*}
     */
    async addCart() {
      try {
        this.googleCustomeEventHanlder("add_to_cart_btn", "click", "cart_icon", "wishlist");

        this.unSelectedOptionObj = [];
        //过滤出所有必选项
        let tempRequire = this.productOptions.options.filter((item) => item.isRequire);
        //遍历所有必须项是否被选中,如果没有被选中收集起来
        tempRequire.forEach((item) => {
          let isHas = this.tempOtherOptions.some((selectItem) => item.id == selectItem.option_id);
          if (!isHas) {
            this.unSelectedOptionObj.push(item);
          }
        });
        // 有未选中的选项
        if (this.unSelectedOptionObj.length > 0) {
          window.scrollTo({
            top: this.$refs.selectContainer.offsetTop - 60,
            behavior: "smooth",
          });
          return;
        }
        let params = {
          product_id: this.productOptions.id,
          qty: this.qtyNumD,
          options: [...this.tempOtherOptions],
        };
        let addCartRes = await this.$addCart(params);
        if (addCartRes.code == 200000) {
          this.$toast({ type: "success", content: addCartRes.message });

          //显示购物车弹窗
          this.addSuccessRes = addCartRes.data?.quote;
          this.getCartList(true);
          // 加入购物车
          let datalayerParam = {
            eventType: "add",
            event_category: "productDetail",
            event_action: "productAdd",
            event_label: "add_to_cart",
            value: this.addSuccessRes.previewGrandTotalFormat,
            item_name: this.productOptions.name,
            item_id: this.productOptions.id,
            item_category: this.productOptions?.categories ? this.productOptions?.categories[0].id : 0,
            price: this.productOptions.finalPrice,
            quantity: this.qtyNumD,
          };
          this.$googleProductActionCart(datalayerParam);
        }
      } catch (error) {
        throw new Error("加购事件" + error);
      }
    },
    /**
     * @Date: 2023-06-14 09:26:11
     * @Description: option选择 库存数量，状态改变, 价格改变
     * @param {*} optionInx 选择中的属性的index
     * @param {*} childInx选中的option的index
     * @return {*}
     */
    selectOption(optionInx, childInx) {
      // this.selectedOptionIdObj[curritem.optionName] = null
      // 库存数量，状态改变, 价格改变
      // 状态改变
      this.$set(this.selectOtherIndex, optionInx, `${childInx}`);
      console.log(this.selectOtherIndex, "selectOtherIndexselectOtherIndex");
      //库存数量
      this.tempOtherOptions = [];
      this.selectedOptionIdArr = [];
      for (let i in this.selectOtherIndex) {
        let optionItem = this.productOptions.options[i];
        let tempItem = optionItem.values[this.selectOtherIndex[i]];
        // this.currentSelected[i] = tempItem.optionValueTitle;
        this.selectedOptionIdArr.push(tempItem.id); //拿到目前选中的optionid
        this.tempOtherOptions.push({
          //这个加购的时候使用
          option_id: tempItem.optionId,
          optionName: optionItem.optionName,
          option_value_id: tempItem.id,
          optionValueTitle: tempItem.optionValueTitle,
        });
      }
      this.productOptions.options.forEach((optionItem) => {
        optionItem.values.forEach((valueItem) => {
          valueItem.isDisable = false;
        });
      });
      this.handlerDisableOption(this.selectedOptionIdArr);
    },
    /**
     * @Date: 2023-06-14 09:27:49
     * @Description: 库存限制
     * @param {*} selectedId 当前选中的 optionid
     * @return {*}
     */
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 09:12:56
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 库存验证
     * @param {*} selectedId
     * @return {*}
     * @Description: Do not Description
     */
    handlerDisableOption(selectedId) {
      console.log(selectedId);
      let selectNum = this.productOptions.options.length;
      if (selectNum == selectedId.length) {
        let newSelected = this.getCombinations(selectedId, selectedId.length - 1);
        console.log(newSelected, "newSelected");
        newSelected.forEach((item) => {
          this.findZeroInventory(item);
        });
      } else {
        selectedId.sort();
        let tempIdStr = selectedId.join("-");
        this.findZeroInventory(tempIdStr);
      }
    },
    // 工具方法，用于选满的情况下组合新数组
    getCombinations(arr, k) {
      const combinations = [];
      function backtrack(startIndex, currentCombination) {
        if (currentCombination.length === k) {
          combinations.push(currentCombination.slice());
          return;
        }
        for (let i = startIndex; i < arr.length; i++) {
          currentCombination.push(arr[i]);
          backtrack(i + 1, currentCombination);
          currentCombination.pop();
        }
      }
      backtrack(0, []);
      return combinations;
    },
    // 工具方法，用于两个数组 取交集和交集之外的那个变量
    getIntersection(arr1, arr2) {
      console.log(arr1);
      console.log(arr2);
      const set2 = new Set(arr2);
      return { intersection: arr1.filter((item) => set2.has(item)), currstr: arr1.filter((item) => !set2.has(item)) };
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-01 09:11:54
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 找到库存为零的组合
     * @param {*} sid 选中的 option id
     * @return {*}
     * @Description: Do not Description
     */
    findZeroInventory(tempIdStr) {
      // console.log(this.allOptionZero);
      let paramType = typeof tempIdStr;
      this.allOptionZero.forEach((item) => {
        // tempIdStr类型是字符串
        if (paramType == "string") {
          if (item.sortedOptionValueId.includes(tempIdStr)) {
            let newTempIdStr = item.sortedOptionValueId.replace(tempIdStr, "").replace("-", "");
            //置灰optionid
            this.disableClass(newTempIdStr);
          }
        }
        // tempIdStr类型是数组
        if (paramType == "object") {
          let newTempIdArr = item.sortedOptionValueId.split("-").map((item) => Number(item));
          let { intersection, currstr } = this.getIntersection(newTempIdArr, tempIdStr);
          if (intersection.length == newTempIdArr.length - 1) {
            //置灰optionid
            this.disableClass(currstr[0]);
          }
        }
      });
    },
    /**
     * @description:找到需要灰的optionid
     * @param {*} newTempIdStr
     * @return {*}
     */
    disableClass(newTempIdStr) {
      this.productOptions.options.forEach((optionItem) => {
        optionItem.values.forEach((valueItem) => {
          if (valueItem.id == newTempIdStr) {
            valueItem.isDisable = true;
          }
        });
      });
    },
    /**
     * @Date: 2023-06-14 10:56:29
     * @Description: 数量加减
     * @param {*} type -1是减 1是加
     * @return {*}
     */
    qtyChange(type) {
      if (this.qtyNumD == 1 && type == -1) {
        return;
      }
      this.qtyNumD += type;
    },
  },
  mounted() {
    // 页面进入时加载内容
    // console.log(this.productOptions, "弹框中的产品信息");
    // console.log(this.selectOtherIndex, "默认选中的选项");
  },
  watch: {
    // 监测变化
    //当进来时 选中默认值
    selectedOptionIdObj: {
      handler(val) {
        this.selectedOptionIdObj.forEach((item, index) => {
          this.selectOtherIndex[index] = item;
        });
        if (val.length > 0) {
          this.tempOtherOptions = this.productOptions.options.map((item, index) => {
            return {
              option_id: item.values[this.selectedOptionIdObj[index]].optionId,
              optionName: item.values[this.selectedOptionIdObj[index]].optionName,
              option_value_id: item.values[this.selectedOptionIdObj[index]].id,
              optionValueTitle: item.values[this.selectedOptionIdObj[index]].optionValueTitle,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
    qtyNum: {
      handler(val) {
        console.log(val, "valval");
        this.qtyNumD = val;
      },
      deep: true,
      immediate: true,
    },
  },
};
