//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { countryList } from "~/static/js/areaInfo";
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    isResetBackLink: {
      type: Boolean,
      default: () => false,
    },
    titleContent: {
      type: String,
      default: () => "",
    }, //顶部title
    sourceType: {
      type: String,
      default: () => "add",
    }, //获取弹窗类型 add---》添加 shipping--->修改shipping billing --->修改billing
  },
  data() {
    return {
      defaultBillingAddress: true, //添加地址时 默认billing地址
      formdata: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "",
        province: "",
        provinceId: 0,
        city: "",
        address: "",
        suite: "",
        postalcode: "",
        firstNameBilling: "",
        lastNameBilling: "",
        emailBilling: "",
        phoneBilling: "",
        countryBilling: "",
        provinceBilling: "",
        provinceBillingId: "",
        cityBilling: "",
        addressBilling: "",
        postalcodeBilling: "",
      },
      countryList: [], //国家列表
      regionsList: [], // 城市列表
      regionsListBilling: [], // 城市列表

      editDefaultBillingAddress: true, //编辑shippingaddress时 是否默认
      editDefaultShippingAddress: true, //编辑 billing adress时 是否默认
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters("user", ["getUserInfo", "isLogin"]),
    ...mapGetters("address", ["addressInfo", "billingAddressInfo"]),
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapMutations("address", ["setAddressInfo", "setBillingAddressInfo"]),
    // 方法
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
      // if (!this.isShowDialog) {
      //   // this.resetData();
      // }
    },
    initData() {
      this.formdata.firstName = this.addressInfo?.firstname || "";
      this.formdata.lastName = this.addressInfo?.lastname || "";
      this.formdata.email = this.addressInfo?.email || "";
      this.formdata.phone = this.addressInfo?.telephone || "";
      this.formdata.country = this.addressInfo?.countryId || "";
      this.formdata.province = this.addressInfo?.region || "";
      this.formdata.provinceId = this.addressInfo?.regionId || "";
      this.formdata.city = this.addressInfo?.city || "";
      this.formdata.address = this.addressInfo?.street || "";
      this.formdata.suite = this.addressInfo?.suite || "";
      this.formdata.postalcode = this.addressInfo?.postcode || "";

      this.formdata.firstNameBilling = this.billingAddressInfo?.firstname || "";
      this.formdata.lastNameBilling = this.billingAddressInfo?.lastname || "";
      this.formdata.emailBilling = this.billingAddressInfo?.email || "";
      this.formdata.phoneBilling = this.billingAddressInfo?.telephone || "";
      this.formdata.countryBilling = this.billingAddressInfo?.countryId || "";

      this.formdata.provinceBilling = this.billingAddressInfo?.region || "";
      this.formdata.provinceBillingId = this.billingAddressInfo?.regionId || "";
      this.formdata.cityBilling = this.billingAddressInfo?.city || "";
      this.formdata.addressBilling = this.billingAddressInfo?.street || "";
      this.formdata.suiteBilling = this.billingAddressInfo?.suite || "";
      this.formdata.postalcodeBilling = this.billingAddressInfo?.postcode || "";
    },
    //重置数据
    // resetData() {
    //   this.setAddressInfo({});
    //   this.setBillingAddressInfo({});
    // },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-06 10:59:02
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 选中国家改变 事件 获取地区列表
     * @param {*} event
     * @param {*} params
     * @return {*}
     * @Description: Do not Description
     */
    async handleCountryChange(params) {
      console.log(params, "触发了吗");
      try {
        let param = {
          country_id: params == "shipping" ? this.formdata.country : this.formdata.countryBilling,
        };
        if (!param.country_id) {
          return;
        }
        let { code, data } = await this.$regionsList(param);
        if (code == 200000) {
          //默认选中第1个
          if (params == "shipping") {
            this.regionsList = data;
          } else {
            this.regionsListBilling = data;
          }
        }
      } catch (error) {
        new Error("选中国家改变 事件 获取地区列表" + error);
      }
    },
    /**
     * @description:保存地址的handler
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-14 14:51:12
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    async saveAddressHandler() {
      try {
        let paramsShipping = {
          email: this.formdata.email || this.getUserInfo?.email || this.$cookies.get("userInfo")?.user?.email,
          telephone: this.formdata.phone,
          firstname: this.formdata.firstName,
          lastname: this.formdata.lastName,
          country_id: this.formdata.country,
          region: this.formdata.province,
          region_id: this.formdata.provinceId,
          city: this.formdata.city,
          street: this.formdata.address,
          postcode: this.formdata.postalcode,
          suite: this.formdata.suite,
        };
        let paramsBilling = {
          email: this.formdata.emailBilling || this.getUserInfo?.email || this.$cookies.get("userInfo")?.user?.email,
          telephone: this.formdata.phoneBilling,
          firstname: this.formdata.firstNameBilling,
          lastname: this.formdata.lastNameBilling,
          country_id: this.formdata.countryBilling,
          region: this.formdata.provinceBilling,
          region_id: this.formdata.provinceBillingId,
          city: this.formdata.cityBilling,
          street: this.formdata.addressBilling,
          postcode: this.formdata.postalcodeBilling,
          suite: this.formdata.suiteBilling,
        };
        if (this.sourceType == "add") {
          //添加
          this.saveBookAddress("add", paramsShipping, paramsBilling);
        } else if (this.sourceType == "shipping") {
          this.saveBookAddress("shipping", paramsShipping);
        } else if (this.sourceType == "billing") {
          //当 billing 编辑时
          this.saveBookAddress("billing", paramsBilling);
        }
      } catch (error) {
        throw new Error("保存地址事件" + error);
      }
    },

    /**
     * @description:保存地址的接口处理
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-14 14:51:12
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    async saveBookAddress(type, params, paramsBilling) {
      try {
        let saveAddressRes;
        if (type == "shipping") {
          // params.save_in_address_book = 1;

          params.address_type = "shipping";
          params.same_as_billing = this.editDefaultBillingAddress ? 1 : 0; //是否做为默认的 1是0否
          params.save_in_address_book = this.editDefaultBillingAddress ? 1 : 0;

          saveAddressRes = await this.$updataAddress(params, this.addressInfo.id);
        } else if (type == "billing") {
          params.same_as_billing = this.editDefaultShippingAddress ? 1 : 0; //是否做为默认的 1是0否
          params.address_type = "billing";
          // params.save_in_address_book = 1;
          params.save_in_address_book = this.editDefaultShippingAddress ? 1 : 0;
          saveAddressRes = await this.$updataAddress(params, this.billingAddressInfo.id);
        } else if (type == "add") {
          params.address_type = "shipping";
          params.same_as_billing = this.editDefaultBillingAddress ? 1 : 0;
          params.save_in_address_book = this.editDefaultBillingAddress ? 1 : 0;
          saveAddressRes = await this.$createAddress(params);

          if (!params.same_as_billing) {
            //shipping 和 billing 不一样
            params.save_in_address_book = this.editDefaultBillingAddress ? 1 : 0;
            params.address_type = "billing";
            saveAddressRes = await this.$createAddress(paramsBilling);
          }
        }
        if (saveAddressRes.code == 200000) {
          this.$toast({ type: "success", content: saveAddressRes.message });
          this.$emit("submitSuccess", params);
        }
      } catch (error) {
        throw new Error("地址薄 保存处理" + error);
      }
    },
    /**
     * @description: 编辑地址保存
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-14 14:45:15
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    submitHandler() {
      //校验
      this.$validator.validateAll().then((valid) => {
        if (true === valid) {
          console.log("sucess");
          this.saveAddressHandler();
        } else {
          console.log(this.$validator.errors);
        }
      });
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.countryList = countryList;
    if (this.sourceType != "add") {
      this.initData();
    }
    console.log(this.addressInfo);
    console.log(this.$cookies.get("addressType"));
    console.log(this.sourceType);
  },
  watch: {
    "formdata.country": {
      handler() {
        this.handleCountryChange("shipping");
      },
      deep: true,
      immediate: true,
    },
    "formdata.provinceId"(val) {
      if (this.regionsList.length > 0 && val) {
        console.log(this.regionsList);
        console.log(val);
        let nowProvice = this.regionsList.filter((item) => {
          console.log(item.regionId, item.code);
          return item.regionId == val;
        });
        console.log(nowProvice);
        this.formdata.province = nowProvice[0]?.defaultName || 0;
      }
    },
    "formdata.countryBilling": {
      handler() {
        this.handleCountryChange("billing");
      },
      deep: true,
      immediate: true,
    },
    "formdata.provinceBillingId"(val) {
      if (this.regionsListBilling.length > 0 && val) {
        let nowProvice = this.regionsListBilling.filter((item) => {
          return item.regionId == val;
        });
        this.formdata.provinceBilling = nowProvice[0]?.defaultName || 0;
      }
    },
    regionsList: {
      handler() {
        if (this.regionsList.length == 0) {
          this.formdata.provinceId = 0;
        }
      },
    },
  },
};
