//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    isLink: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    textContent: {
      type: Object,
      default: () => {},
    },
  },
  name: "EmptyListData",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    handlerFun() {
      this.$emit("linkChangeHandler");
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
