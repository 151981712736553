//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
  },
  name: "RegisterDialog",
  data() {
    return {
      email: "",
      password: "",
      agree: "",
      markete: "",
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapMutations("user", ["setIsLogin", "setUserInfo"]),
    // 方法
    async registerSubmit() {
      const success = await this.$validator.validateAll();
      if (success) {
        let params = {
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
          phone: "",
          firstname: "new",
          lastname: "unice",
          dob: "",
          customer_source: "0",
          source_url: window.location.href,
          markete: this.markete,
          agree: this.agree,
        };
        let res = await this.$nuxt.$submitRegiser(params);
        console.log(res);
        if (res.code == 200000) {
          try {
            this.$emit("registerSuccessHanlder", this.email, this.password);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },

    changeDialogType() {
      this.$emit("changeDialogHanlder");
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
