//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
export default {
  props: {
    orderId: {
      type: [Number, String],
      default: () => 0,
    },
  },
  data() {
    return {
      addressType: 1,
      addressInfo: {},
      isShowDialog: false,
    };
  },
  computed: {
    // ...mapGetters("address", ["addressInfo"]),
  },
  mounted() {
    console.log("mounted 确认地址弹窗");
    let showAddress = JSON.parse(sessionStorage.getItem("isShowAddress"));
    if (showAddress == null) {
      this.getAddressInfo();
    }
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations("address", ["setAddressInfo"]),
    // 获取订单地址信息
    async getAddressInfo() {
      if (!this.orderId) {
        return;
      }
      let { code, data } = await this.$orderDetail(this.orderId);
      if (code == 200000) {
        if (data.status == "pending") {
          this.$router.replace({
            path: "/user/order/unpaid",
            query: { orderId: this.orderId },
          });
          return;
        }
        this.addressInfo = data.shippingAddress;
      }
    },
    async confirmAddress() {
      let confirmAddressRes = await this.$confirmShippingAddress({ id: this.addressInfo.id });
      if (confirmAddressRes.code == 200000) {
        this.$emit("confirmAddress");
        sessionStorage.setItem("isShowAddress", JSON.stringify(false));
      }
    },
    modifyAddress() {
      this.setAddressInfo(this.addressInfo);
      this.$router.push({
        path: "/address/save",
        query: {
          orderId: this.orderId,
          titleType: 1,
          isGoBack: 0,
          enterType: "modify",
        },
      });
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit("confirmAddress");
    },
  },
};
