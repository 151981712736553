var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"postTitle"},[_vm._v(_vm._s(_vm.headTitle))]),_vm._v(" "),_c('div',[(_vm.swiperData)?_c('div',{staticClass:"blog-list"},_vm._l((_vm.swiperData),function(listItem,listIndex){return _c('div',{key:listIndex,staticClass:"blog-listItem swiper-slide"},[_c('div',{staticClass:"blog-listItem--img",on:{"click":function($event){return _vm.linkHandle(listItem)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.connectImgUrl(listItem.smallImage)),expression:"connectImgUrl(listItem.smallImage)"}],attrs:{"title":listItem.title}})]),_vm._v(" "),_c('p',{staticClass:"blog-listItem_short flex-centerY"},_vm._l((listItem.tags),function(keyword,keywordIndex){return _c('span',{key:keywordIndex,class:[
              'a-hover',
              { 'a-hover--knowledge': keyword.toLowerCase().indexOf('knowledge') != -1 },
              { 'a-hover--hairstyle': keyword.toLowerCase().indexOf('hairstyle') != -1 },
              { 'a-hover--care': keyword.toLowerCase().indexOf('care') != -1 },
              { 'a-hover--review': keyword.toLowerCase().indexOf('review') != -1 },
              { 'a-hover--ques': keyword.toLowerCase().indexOf('ques') != -1 },
              { 'a-hover--promotion': keyword.toLowerCase().indexOf('promotion') != -1 },
              { 'a-hover--blog': keyword.toLowerCase().indexOf('blog') != -1 } ]},[_vm._v(_vm._s(keyword))])}),0),_vm._v(" "),_c('h3',{staticClass:"blog-listItem_tit"},[_c('div',{staticClass:"blog-item_tit-link",on:{"click":function($event){return _vm.linkHandle(listItem)}}},[_vm._v("\n            "+_vm._s(listItem.title)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"readMore",on:{"click":function($event){return _vm.linkHandle(listItem)}}},[_vm._v("Read More")])])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-centerXY custom-pagination"})])])}
var staticRenderFns = []

export { render, staticRenderFns }