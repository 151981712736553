//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    containerStyle: {
      type: String,
      default: "",
    },
    touchmoveSet: {
      type: String,
      default: "prevent",
    },
  },
  data() {
    return {
      isShowDialog: false,
      touchmoveEvent: {},
      scrollTop: 0,
      fixedTop: 0,
    };
  },
  mounted() {
    if (this.touchmoveSet == "") {
      this.touchmoveEvent = { touchmove: () => {} };
    } else if (this.touchmoveSet == "prevent") {
      this.touchmoveEvent = {
        touchmove: (event) => {
          event.preventDefault();
        },
      };
    }
  },
  methods: {
    changeDialog() {
      console.log("进来了吗");
      this.isShowDialog = !this.isShowDialog;
      if (this.touchmoveSet == "") {
        this.tool.isFiexdBody(this.isShowDialog);
      }
    },
  },
};
