//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    swiperOption: {
      type: Object,
      default: () => ({}),
    },
    swiperData: {
      type: Array,
      default: () => [],
    },
    swiperStyle: {
      type: Object,
      default: () => ({}),
    },
    customPagination: {
      type: Boolean,
      default: () => false,
    },
    otherClickAction: {
      type: Boolean,
      default: () => false,
    },
    getCatalogInfoBoolean: {
      type: Boolean,
      default: () => false,
    }, //是否通过获取栏目信息 来跳转
    videoicon: {
      type: Boolean,
      default: () => false,
    },
    bigActiveIndex: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      currindex: this.bigActiveIndex,
    };
  },

  mounted() {
    // console.log(this.swiperData, "swiperDataswiperDataswiperData");
  },
  methods: {
    handleClick(index, item) {
      if (this.otherClickAction) {
        this.currindex = index;
        this.$emit("clickEvent", index, item);
      } else if (this.getCatalogInfoBoolean) {
        if (item.link != "") {
          this.handleJump(item.link);
        } else {
          this.getCatalogInfoHandler(item.categoryId);
        }
      } else {
        this.handleJump(item.link);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-22 10:01:39
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 根据产品id 获取栏目信息
     * @param {*} id
     * @return {*}
     * @Description: Do not Description
     */
    async getCatalogInfoHandler(id) {
      try {
        let { code, data } = await this.$categoriesDetail(id);
        if (code == 200000) {
          console.log(data);
          this.handleJump(data.category.urlPath);
        }
      } catch (error) {
        throw new Error("根据产品id 获取栏目信息" + error);
      }
    },
  },
  watch: {
    bigActiveIndex: {
      handler(val) {
        this.currindex = val;
      },
      deep: true,
      immediate: true,
    },
  },
};
