import { render, staticRenderFns } from "./AddressBookDialog.vue?vue&type=template&id=77642f7e&scoped=true&"
import script from "./AddressBookDialog.vue?vue&type=script&lang=js&"
export * from "./AddressBookDialog.vue?vue&type=script&lang=js&"
import style0 from "./AddressBookDialog.vue?vue&type=style&index=0&id=77642f7e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77642f7e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AddressStatus: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/AddressStatus.vue').default})
