module.exports = {
  areaInfo: [
    {
      country: "United States",
      country_cn: "美国",
      country_code: "US",
      phone_code: "1",
    },
    {
      country: "United Kingdom",
      country_cn: "英国",
      country_code: "GB",
      phone_code: "44",
    },
    {
      country: "France",
      country_cn: "法国",
      country_code: "FR",
      phone_code: "33",
    },
    {
      country: "Germany",
      country_cn: "德国",
      country_code: "DE",
      phone_code: "49",
    },
    {
      country: "Australia",
      country_cn: "澳大利亚",
      country_code: "AU",
      phone_code: "61",
    },

    {
      country: "Netherlands",
      country_cn: "荷兰",
      country_code: "NL",
      phone_code: "31",
    },
    {
      country: "Ireland",
      country_cn: "爱尔兰",
      country_code: "IE",
      phone_code: "353",
    },
    {
      country: "Norway",
      country_cn: "挪威",
      country_code: "NO",
      phone_code: "47",
    },
    {
      country: "South Africa",
      country_cn: "南非",
      country_code: "ZA",
      phone_code: "27",
    },

    {
      country: "Belgium",
      country_cn: "比利时",
      country_code: "BE",
      phone_code: "32",
    },
    {
      country: "Sweden",
      country_cn: "瑞典",
      country_code: "SE",
      phone_code: "46",
    },
    {
      country: "Bahamas",
      country_cn: "巴哈马",
      country_code: "BS",
      phone_code: "1242",
    },
    {
      country: "Italy",
      country_cn: "意大利",
      country_code: "IT",
      phone_code: "39",
    },
    {
      country: "Spain",
      country_cn: "西班牙",
      country_code: "ES",
      phone_code: "34",
    },
    {
      country: "Jamaica",
      country_cn: "牙买加",
      country_code: "JM",
      phone_code: "1876",
    },
    {
      country: "Swaziland",
      country_cn: "斯威士兰",
      country_code: "SZ",
      phone_code: "268",
    },
    {
      country: "Israel",
      country_cn: "以色列",
      country_code: "IL",
      phone_code: "972",
    },
    {
      country: "Austria",
      country_cn: "奥地利",
      country_code: "AT",
      phone_code: "43",
    },
    {
      country: "New Zealand",
      country_cn: "新西兰",
      country_code: "NZ",
      phone_code: "64",
    },
    {
      country: "China",
      country_cn: "中国",
      country_code: "CN",
      phone_code: "86",
    },

    // { id: "101", iso2: "US", iso3: "USA", country: "United States of America (USA)", country_cn: "美国", country_id: "US", prefix: "+1" },
    // 以下先不用,运营需要 拿出来就行
    // {
    //     "country":"Andorra",
    //     "country_cn":"安道尔",
    //     "country_code":"AD",
    //     "phone_code":"376"
    // },
    // {
    //     "country":"United Arab Emirates",
    //     "country_cn":"阿拉伯联合酋长国",
    //     "country_code":"AE",
    //     "phone_code":"971"
    // },
    // {
    //     "country":"Afghanistan",
    //     "country_cn":"阿富汗",
    //     "country_code":"AF",
    //     "phone_code":"93"
    // },
    // {
    //     "country":"Antigua and Barbuda",
    //     "country_cn":"安提瓜和巴布达",
    //     "country_code":"AG",
    //     "phone_code":"1268"
    // },
    // {
    //     "country":"Anguilla",
    //     "country_cn":"安圭拉",
    //     "country_code":"AI",
    //     "phone_code":"1264"
    // },
    // {
    //     "country":"Albania",
    //     "country_cn":"阿尔巴尼亚",
    //     "country_code":"AL",
    //     "phone_code":"355"
    // },
    // {
    //     "country":"Armenia",
    //     "country_cn":"亚美尼亚",
    //     "country_code":"AM",
    //     "phone_code":"374"
    // },
    // {
    //     "country":"Angola",
    //     "country_cn":"安哥拉",
    //     "country_code":"AO",
    //     "phone_code":"244"
    // },
    // {
    //     "country":"Argentina",
    //     "country_cn":"阿根廷",
    //     "country_code":"AR",
    //     "phone_code":"54"
    // },
    // {
    //     "country":"American Samoa",
    //     "country_cn":"美属萨摩亚",
    //     "country_code":"AS",
    //     "phone_code":"1684"
    // },

    // {
    //     "country":"Aruba",
    //     "country_cn":"阿鲁巴",
    //     "country_code":"AW",
    //     "phone_code":"297"
    // },
    // {
    //     "country":"Azerbaijan",
    //     "country_cn":"阿塞拜疆",
    //     "country_code":"AZ",
    //     "phone_code":"994"
    // },
    // {
    //     "country":"Bosniaand Herzegovina",
    //     "country_cn":"波斯尼亚和黑塞哥维那",
    //     "country_code":"BA",
    //     "phone_code":"387"
    // },
    // {
    //     "country":"Barbados",
    //     "country_cn":"巴巴多斯",
    //     "country_code":"BB",
    //     "phone_code":"1246"
    // },
    // {
    //     "country":"Bangladesh",
    //     "country_cn":"孟加拉国",
    //     "country_code":"BD",
    //     "phone_code":"880"
    // },

    // {
    //     "country":"Burkina Faso",
    //     "country_cn":"布基纳法索",
    //     "country_code":"BF",
    //     "phone_code":"226"
    // },
    // {
    //     "country":"Bulgaria",
    //     "country_cn":"保加利亚",
    //     "country_code":"BG",
    //     "phone_code":"359"
    // },
    // {
    //     "country":"Bahrain",
    //     "country_cn":"巴林",
    //     "country_code":"BH",
    //     "phone_code":"973"
    // },
    // {
    //     "country":"Burundi",
    //     "country_cn":"布隆迪",
    //     "country_code":"BI",
    //     "phone_code":"257"
    // },
    // {
    //     "country":"Benin",
    //     "country_cn":"贝宁",
    //     "country_code":"BJ",
    //     "phone_code":"229"
    // },
    // {
    //     "country":"Bermuda",
    //     "country_cn":"百慕大群岛",
    //     "country_code":"BM",
    //     "phone_code":"1441"
    // },
    // {
    //     "country":"Brunei",
    //     "country_cn":"文莱",
    //     "country_code":"BN",
    //     "phone_code":"673"
    // },
    // {
    //     "country":"Bolivia",
    //     "country_cn":"玻利维亚",
    //     "country_code":"BO",
    //     "phone_code":"591"
    // },
    // {
    //     "country":"Caribisch Nederland",
    //     "country_cn":"荷兰加勒比",
    //     "country_code":"BQ",
    //     "phone_code":"599"
    // },
    // {
    //     "country":"Brazil",
    //     "country_cn":"巴西",
    //     "country_code":"BR",
    //     "phone_code":"55"
    // },

    // {
    //     "country":"Bhutan",
    //     "country_cn":"不丹",
    //     "country_code":"BT",
    //     "phone_code":"975"
    // },
    // {
    //     "country":"Botswana",
    //     "country_cn":"博茨瓦纳",
    //     "country_code":"BW",
    //     "phone_code":"267"
    // },
    // {
    //     "country":"Belarus",
    //     "country_cn":"白俄罗斯",
    //     "country_code":"BY",
    //     "phone_code":"375"
    // },
    // {
    //     "country":"Belize",
    //     "country_cn":"伯利兹",
    //     "country_code":"BZ",
    //     "phone_code":"501"
    // },
    // {
    //     "country":"Canada",
    //     "country_cn":"加拿大",
    //     "country_code":"CA",
    //     "phone_code":"1"
    // },
    // {
    //     "country":"Democratic Republic of theCongo",
    //     "country_cn":"刚果民主共和国",
    //     "country_code":"CD",
    //     "phone_code":"243"
    // },
    // {
    //     "country":"Central African Republic",
    //     "country_cn":"中非共和国",
    //     "country_code":"CF",
    //     "phone_code":"236"
    // },
    // {
    //     "country":"Republic Of The Congo",
    //     "country_cn":"刚果共和国",
    //     "country_code":"CG",
    //     "phone_code":"242"
    // },
    // {
    //     "country":"Switzerland",
    //     "country_cn":"瑞士",
    //     "country_code":"CH",
    //     "phone_code":"41"
    // },
    // {
    //     "country":"Ivory Coast",
    //     "country_cn":"象牙海岸",
    //     "country_code":"CI",
    //     "phone_code":"225"
    // },
    // {
    //     "country":"Cook Islands",
    //     "country_cn":"库克群岛",
    //     "country_code":"CK",
    //     "phone_code":"682"
    // },
    // {
    //     "country":"Chile",
    //     "country_cn":"智利",
    //     "country_code":"CL",
    //     "phone_code":"56"
    // },
    // {
    //     "country":"Cameroon",
    //     "country_cn":"喀麦隆",
    //     "country_code":"CM",
    //     "phone_code":"237"
    // },

    // {
    //     "country":"Colombia",
    //     "country_cn":"哥伦比亚",
    //     "country_code":"CO",
    //     "phone_code":"57"
    // },
    // {
    //     "country":"CostaRica",
    //     "country_cn":"哥斯达黎加",
    //     "country_code":"CR",
    //     "phone_code":"506"
    // },
    // {
    //     "country":"Cuba",
    //     "country_cn":"古巴",
    //     "country_code":"CU",
    //     "phone_code":"53"
    // },
    // {
    //     "country":"Cape Verde",
    //     "country_cn":"开普",
    //     "country_code":"CV",
    //     "phone_code":"238"
    // },
    // {
    //     "country":"Curacao",
    //     "country_cn":"库拉索",
    //     "country_code":"CW",
    //     "phone_code":"599"
    // },
    // {
    //     "country":"Cyprus",
    //     "country_cn":"塞浦路斯",
    //     "country_code":"CY",
    //     "phone_code":"357"
    // },
    // {
    //     "country":"Czech",
    //     "country_cn":"捷克",
    //     "country_code":"CZ",
    //     "phone_code":"420"
    // },

    // {
    //     "country":"Djibouti",
    //     "country_cn":"吉布提",
    //     "country_code":"DJ",
    //     "phone_code":"253"
    // },
    // {
    //     "country":"Denmark",
    //     "country_cn":"丹麦",
    //     "country_code":"DK",
    //     "phone_code":"45"
    // },
    // {
    //     "country":"Dominica",
    //     "country_cn":"多米尼加",
    //     "country_code":"DM",
    //     "phone_code":"1767"
    // },
    // {
    //     "country":"dominican republic",
    //     "country_cn":"多米尼加共和国",
    //     "country_code":"DO",
    //     "phone_code":"1809"
    // },
    // {
    //     "country":"Algeria",
    //     "country_cn":"阿尔及利亚",
    //     "country_code":"DZ",
    //     "phone_code":"213"
    // },
    // {
    //     "country":"Ecuador",
    //     "country_cn":"厄瓜多尔",
    //     "country_code":"EC",
    //     "phone_code":"593"
    // },
    // {
    //     "country":"Estonia",
    //     "country_cn":"爱沙尼亚",
    //     "country_code":"EE",
    //     "phone_code":"372"
    // },
    // {
    //     "country":"Egypt",
    //     "country_cn":"埃及",
    //     "country_code":"EG",
    //     "phone_code":"20"
    // },
    // {
    //     "country":"Eritrea",
    //     "country_cn":"厄立特里亚",
    //     "country_code":"ER",
    //     "phone_code":"291"
    // },

    // {
    //     "country":"Ethiopia",
    //     "country_cn":"埃塞俄比亚",
    //     "country_code":"ET",
    //     "phone_code":"251"
    // },
    // {
    //     "country":"Finland",
    //     "country_cn":"芬兰",
    //     "country_code":"FI",
    //     "phone_code":"358"
    // },
    // {
    //     "country":"Fiji",
    //     "country_cn":"斐济",
    //     "country_code":"FJ",
    //     "phone_code":"679"
    // },
    // {
    //     "country":"Micronesia",
    //     "country_cn":"密克罗尼西亚",
    //     "country_code":"FM",
    //     "phone_code":"691"
    // },
    // {
    //     "country":"Faroe Islands",
    //     "country_cn":"法罗群岛",
    //     "country_code":"FO",
    //     "phone_code":"298"
    // },

    // {
    //     "country":"Gabon",
    //     "country_cn":"加蓬",
    //     "country_code":"GA",
    //     "phone_code":"241"
    // },

    // {
    //     "country":"Grenada",
    //     "country_cn":"格林纳达",
    //     "country_code":"GD",
    //     "phone_code":"1473"
    // },
    // {
    //     "country":"Georgia",
    //     "country_cn":"格鲁吉亚",
    //     "country_code":"GE",
    //     "phone_code":"995"
    // },
    // {
    //     "country":"French Guiana",
    //     "country_cn":"法属圭亚那",
    //     "country_code":"GF",
    //     "phone_code":"594"
    // },
    // {
    //     "country":"Ghana",
    //     "country_cn":"加纳",
    //     "country_code":"GH",
    //     "phone_code":"233"
    // },
    // {
    //     "country":"Gibraltar",
    //     "country_cn":"直布罗陀",
    //     "country_code":"GI",
    //     "phone_code":"350"
    // },
    // {
    //     "country":"Greenland",
    //     "country_cn":"格陵兰岛",
    //     "country_code":"GL",
    //     "phone_code":"299"
    // },
    // {
    //     "country":"Gambia",
    //     "country_cn":"冈比亚",
    //     "country_code":"GM",
    //     "phone_code":"220"
    // },
    // {
    //     "country":"Guinea",
    //     "country_cn":"几内亚",
    //     "country_code":"GN",
    //     "phone_code":"224"
    // },
    // {
    //     "country":"Guadeloupe",
    //     "country_cn":"瓜德罗普岛",
    //     "country_code":"GP",
    //     "phone_code":"590"
    // },
    // {
    //     "country":"Equatorial Guinea",
    //     "country_cn":"赤道几内亚",
    //     "country_code":"GQ",
    //     "phone_code":"240"
    // },
    // {
    //     "country":"Greece",
    //     "country_cn":"希腊",
    //     "country_code":"GR",
    //     "phone_code":"30"
    // },
    // {
    //     "country":"Guatemala",
    //     "country_cn":"瓜地马拉",
    //     "country_code":"GT",
    //     "phone_code":"502"
    // },
    // {
    //     "country":"Guam",
    //     "country_cn":"关岛",
    //     "country_code":"GU",
    //     "phone_code":"1671"
    // },
    // {
    //     "country":"Guinea-Bissau",
    //     "country_cn":"几内亚比绍共和国",
    //     "country_code":"GW",
    //     "phone_code":"245"
    // },
    // {
    //     "country":"Guyana",
    //     "country_cn":"圭亚那",
    //     "country_code":"GY",
    //     "phone_code":"592"
    // },
    // {
    //     "country":"Hong Kong",
    //     "country_cn":"中国香港",
    //     "country_code":"HK",
    //     "phone_code":"852"
    // },
    // {
    //     "country":"Honduras",
    //     "country_cn":"洪都拉斯",
    //     "country_code":"HN",
    //     "phone_code":"504"
    // },
    // {
    //     "country":"Croatia",
    //     "country_cn":"克罗地亚",
    //     "country_code":"HR",
    //     "phone_code":"385"
    // },
    // {
    //     "country":"Haiti",
    //     "country_cn":"海地",
    //     "country_code":"HT",
    //     "phone_code":"509"
    // },
    // {
    //     "country":"Hungary",
    //     "country_cn":"匈牙利",
    //     "country_code":"HU",
    //     "phone_code":"36"
    // },
    // {
    //     "country":"Indonesia",
    //     "country_cn":"印度尼西亚",
    //     "country_code":"ID",
    //     "phone_code":"62"
    // },

    // {
    //     "country":"India",
    //     "country_cn":"印度",
    //     "country_code":"IN",
    //     "phone_code":"91"
    // },
    // {
    //     "country":"Iraq",
    //     "country_cn":"伊拉克",
    //     "country_code":"IQ",
    //     "phone_code":"964"
    // },
    // {
    //     "country":"Iran",
    //     "country_cn":"伊朗",
    //     "country_code":"IR",
    //     "phone_code":"98"
    // },
    // {
    //     "country":"Iceland",
    //     "country_cn":"冰岛",
    //     "country_code":"IS",
    //     "phone_code":"354"
    // },

    // {
    //     "country":"Jordan",
    //     "country_cn":"约旦",
    //     "country_code":"JO",
    //     "phone_code":"962"
    // },
    // {
    //     "country":"Japan",
    //     "country_cn":"日本",
    //     "country_code":"JP",
    //     "phone_code":"81"
    // },
    // {
    //     "country":"Kenya",
    //     "country_cn":"肯尼亚",
    //     "country_code":"KE",
    //     "phone_code":"254"
    // },
    // {
    //     "country":"Kyrgyzstan",
    //     "country_cn":"吉尔吉斯斯坦",
    //     "country_code":"KG",
    //     "phone_code":"996"
    // },
    // {
    //     "country":"Cambodia",
    //     "country_cn":"柬埔寨",
    //     "country_code":"KH",
    //     "phone_code":"855"
    // },
    // {
    //     "country":"Kiribati",
    //     "country_cn":"基里巴斯",
    //     "country_code":"KI",
    //     "phone_code":"686"
    // },
    // {
    //     "country":"Comoros",
    //     "country_cn":"科摩罗",
    //     "country_code":"KM",
    //     "phone_code":"269"
    // },
    // {
    //     "country":"Saint Kitts and Nevis",
    //     "country_cn":"圣基茨和尼维斯",
    //     "country_code":"KN",
    //     "phone_code":"1869"
    // },
    // {
    //     "country":"Korea Democratic Rep.",
    //     "country_cn":"朝鲜",
    //     "country_code":"KP",
    //     "phone_code":"850"
    // },
    // {
    //     "country":"South Korea",
    //     "country_cn":"韩国",
    //     "country_code":"KR",
    //     "phone_code":"82"
    // },
    // {
    //     "country":"Kuwait",
    //     "country_cn":"科威特",
    //     "country_code":"KW",
    //     "phone_code":"965"
    // },
    // {
    //     "country":"Cayman Islands",
    //     "country_cn":"开曼群岛",
    //     "country_code":"KY",
    //     "phone_code":"1345"
    // },
    // {
    //     "country":"Kazakhstan",
    //     "country_cn":"哈萨克斯坦",
    //     "country_code":"KZ",
    //     "phone_code":"7"
    // },
    // {
    //     "country":"Laos",
    //     "country_cn":"老挝",
    //     "country_code":"LA",
    //     "phone_code":"856"
    // },
    // {
    //     "country":"Lebanon",
    //     "country_cn":"黎巴嫩",
    //     "country_code":"LB",
    //     "phone_code":"961"
    // },
    // {
    //     "country":"Saint Lucia",
    //     "country_cn":"圣露西亚",
    //     "country_code":"LC",
    //     "phone_code":"1758"
    // },
    // {
    //     "country":"Liechtenstein",
    //     "country_cn":"列支敦士登",
    //     "country_code":"LI",
    //     "phone_code":"423"
    // },
    // {
    //     "country":"Sri Lanka",
    //     "country_cn":"斯里兰卡",
    //     "country_code":"LK",
    //     "phone_code":"94"
    // },
    // {
    //     "country":"Liberia",
    //     "country_cn":"利比里亚",
    //     "country_code":"LR",
    //     "phone_code":"231"
    // },
    // {
    //     "country":"Lesotho",
    //     "country_cn":"莱索托",
    //     "country_code":"LS",
    //     "phone_code":"266"
    // },
    // {
    //     "country":"Lithuania",
    //     "country_cn":"立陶宛",
    //     "country_code":"LT",
    //     "phone_code":"370"
    // },
    // {
    //     "country":"Luxembourg",
    //     "country_cn":"卢森堡",
    //     "country_code":"LU",
    //     "phone_code":"352"
    // },
    // {
    //     "country":"Latvia",
    //     "country_cn":"拉脱维亚",
    //     "country_code":"LV",
    //     "phone_code":"371"
    // },
    // {
    //     "country":"Libya",
    //     "country_cn":"利比亚",
    //     "country_code":"LY",
    //     "phone_code":"218"
    // },
    // {
    //     "country":"Morocco",
    //     "country_cn":"摩洛哥",
    //     "country_code":"MA",
    //     "phone_code":"212"
    // },
    // {
    //     "country":"Monaco",
    //     "country_cn":"摩纳哥",
    //     "country_code":"MC",
    //     "phone_code":"377"
    // },
    // {
    //     "country":"Moldova",
    //     "country_cn":"摩尔多瓦",
    //     "country_code":"MD",
    //     "phone_code":"373"
    // },
    // {
    //     "country":"Montenegro",
    //     "country_cn":"黑山",
    //     "country_code":"ME",
    //     "phone_code":"382"
    // },
    // {
    //     "country":"Madagascar",
    //     "country_cn":"马达加斯加",
    //     "country_code":"MG",
    //     "phone_code":"261"
    // },
    // {
    //     "country":"Marshall Islands",
    //     "country_cn":"马绍尔群岛",
    //     "country_code":"MH",
    //     "phone_code":"692"
    // },
    // {
    //     "country":"Macedonia",
    //     "country_cn":"马其顿",
    //     "country_code":"MK",
    //     "phone_code":"389"
    // },
    // {
    //     "country":"Mali",
    //     "country_cn":"马里",
    //     "country_code":"ML",
    //     "phone_code":"223"
    // },
    // {
    //     "country":"Myanmar",
    //     "country_cn":"缅甸",
    //     "country_code":"MM",
    //     "phone_code":"95"
    // },
    // {
    //     "country":"Mongolia",
    //     "country_cn":"蒙古",
    //     "country_code":"MN",
    //     "phone_code":"976"
    // },
    // {
    //     "country":"Macau",
    //     "country_cn":"中国澳门",
    //     "country_code":"MO",
    //     "phone_code":"853"
    // },
    // {
    //     "country":"Mauritania",
    //     "country_cn":"毛里塔尼亚",
    //     "country_code":"MR",
    //     "phone_code":"222"
    // },
    // {
    //     "country":"Montserrat",
    //     "country_cn":"蒙特塞拉特岛",
    //     "country_code":"MS",
    //     "phone_code":"1664"
    // },
    // {
    //     "country":"Malta",
    //     "country_cn":"马耳他",
    //     "country_code":"MT",
    //     "phone_code":"356"
    // },
    // {
    //     "country":"Mauritius",
    //     "country_cn":"毛里求斯",
    //     "country_code":"MU",
    //     "phone_code":"230"
    // },
    // {
    //     "country":"Maldives",
    //     "country_cn":"马尔代夫",
    //     "country_code":"MV",
    //     "phone_code":"960"
    // },
    // {
    //     "country":"Malawi",
    //     "country_cn":"马拉维",
    //     "country_code":"MW",
    //     "phone_code":"265"
    // },
    // {
    //     "country":"Mexico",
    //     "country_cn":"墨西哥",
    //     "country_code":"MX",
    //     "phone_code":"52"
    // },
    // {
    //     "country":"Malaysia",
    //     "country_cn":"马来西亚",
    //     "country_code":"MY",
    //     "phone_code":"60"
    // },
    // {
    //     "country":"Mozambique",
    //     "country_cn":"莫桑比克",
    //     "country_code":"MZ",
    //     "phone_code":"258"
    // },
    // {
    //     "country":"Namibia",
    //     "country_cn":"纳米比亚",
    //     "country_code":"NA",
    //     "phone_code":"264"
    // },
    // {
    //     "country":"New Caledonia",
    //     "country_cn":"新喀里多尼亚",
    //     "country_code":"NC",
    //     "phone_code":"687"
    // },
    // {
    //     "country":"Niger",
    //     "country_cn":"尼日尔",
    //     "country_code":"NE",
    //     "phone_code":"227"
    // },
    // {
    //     "country":"Nigeria",
    //     "country_cn":"尼日利亚",
    //     "country_code":"NG",
    //     "phone_code":"234"
    // },
    // {
    //     "country":"Nicaragua",
    //     "country_cn":"尼加拉瓜",
    //     "country_code":"NI",
    //     "phone_code":"505"
    // },

    // {
    //     "country":"Nepal",
    //     "country_cn":"尼泊尔",
    //     "country_code":"NP",
    //     "phone_code":"977"
    // },
    // {
    //     "country":"Nauru",
    //     "country_cn":"拿鲁岛",
    //     "country_code":"NR",
    //     "phone_code":"674"
    // },

    // {
    //     "country":"Oman",
    //     "country_cn":"阿曼",
    //     "country_code":"OM",
    //     "phone_code":"968"
    // },
    // {
    //     "country":"Panama",
    //     "country_cn":"巴拿马",
    //     "country_code":"PA",
    //     "phone_code":"507"
    // },
    // {
    //     "country":"Peru",
    //     "country_cn":"秘鲁",
    //     "country_code":"PE",
    //     "phone_code":"51"
    // },
    // {
    //     "country":"French Polynesia",
    //     "country_cn":"法属波利尼西亚",
    //     "country_code":"PF",
    //     "phone_code":"689"
    // },
    // {
    //     "country":"Papua New Guinea",
    //     "country_cn":"巴布亚新几内亚",
    //     "country_code":"PG",
    //     "phone_code":"675"
    // },
    // {
    //     "country":"Philippines",
    //     "country_cn":"菲律宾",
    //     "country_code":"PH",
    //     "phone_code":"63"
    // },
    // {
    //     "country":"Pakistan",
    //     "country_cn":"巴基斯坦",
    //     "country_code":"PK",
    //     "phone_code":"92"
    // },
    // {
    //     "country":"Poland",
    //     "country_cn":"波兰",
    //     "country_code":"PL",
    //     "phone_code":"48"
    // },
    // {
    //     "country":"Saint Pierreand Miquelon",
    //     "country_cn":"圣彼埃尔和密克隆岛",
    //     "country_code":"PM",
    //     "phone_code":"508"
    // },
    // {
    //     "country":"Puerto Rico",
    //     "country_cn":"波多黎各",
    //     "country_code":"PR",
    //     "phone_code":"1787"
    // },
    // {
    //     "country":"Portugal",
    //     "country_cn":"葡萄牙",
    //     "country_code":"PT",
    //     "phone_code":"351"
    // },
    // {
    //     "country":"Palau",
    //     "country_cn":"帕劳",
    //     "country_code":"PW",
    //     "phone_code":"680"
    // },
    // {
    //     "country":"Paraguay",
    //     "country_cn":"巴拉圭",
    //     "country_code":"PY",
    //     "phone_code":"595"
    // },
    // {
    //     "country":"Qatar",
    //     "country_cn":"卡塔尔",
    //     "country_code":"QA",
    //     "phone_code":"974"
    // },
    // {
    //     "country":"Réunion Island",
    //     "country_cn":"留尼汪",
    //     "country_code":"RE",
    //     "phone_code":"262"
    // },
    // {
    //     "country":"Romania",
    //     "country_cn":"罗马尼亚",
    //     "country_code":"RO",
    //     "phone_code":"40"
    // },
    // {
    //     "country":"Serbia",
    //     "country_cn":"塞尔维亚",
    //     "country_code":"RS",
    //     "phone_code":"381"
    // },
    // {
    //     "country":"Russia",
    //     "country_cn":"俄罗斯",
    //     "country_code":"RU",
    //     "phone_code":"7"
    // },
    // {
    //     "country":"Rwanda",
    //     "country_cn":"卢旺达",
    //     "country_code":"RW",
    //     "phone_code":"250"
    // },
    // {
    //     "country":"Saudi Arabia",
    //     "country_cn":"沙特阿拉伯",
    //     "country_code":"SA",
    //     "phone_code":"966"
    // },
    // {
    //     "country":"Solomon Islands",
    //     "country_cn":"所罗门群岛",
    //     "country_code":"SB",
    //     "phone_code":"677"
    // },
    // {
    //     "country":"Seychelles",
    //     "country_cn":"塞舌尔",
    //     "country_code":"SC",
    //     "phone_code":"248"
    // },
    // {
    //     "country":"Sudan",
    //     "country_cn":"苏丹",
    //     "country_code":"SD",
    //     "phone_code":"249"
    // },

    // {
    //     "country":"Singapore",
    //     "country_cn":"新加坡",
    //     "country_code":"SG",
    //     "phone_code":"65"
    // },
    // {
    //     "country":"Slovenia",
    //     "country_cn":"斯洛文尼亚",
    //     "country_code":"SI",
    //     "phone_code":"386"
    // },
    // {
    //     "country":"Slovakia",
    //     "country_cn":"斯洛伐克",
    //     "country_code":"SK",
    //     "phone_code":"421"
    // },
    // {
    //     "country":"Sierra Leone",
    //     "country_cn":"塞拉利昂",
    //     "country_code":"SL",
    //     "phone_code":"232"
    // },
    // {
    //     "country":"San Marino",
    //     "country_cn":"圣马力诺",
    //     "country_code":"SM",
    //     "phone_code":"378"
    // },
    // {
    //     "country":"Senegal",
    //     "country_cn":"塞内加尔",
    //     "country_code":"SN",
    //     "phone_code":"221"
    // },
    // {
    //     "country":"Somalia",
    //     "country_cn":"索马里",
    //     "country_code":"SO",
    //     "phone_code":"252"
    // },
    // {
    //     "country":"Suriname",
    //     "country_cn":"苏里南",
    //     "country_code":"SR",
    //     "phone_code":"597"
    // },
    // {
    //     "country":"Sao Tome and Principe",
    //     "country_cn":"圣多美和普林西比",
    //     "country_code":"ST",
    //     "phone_code":"239"
    // },
    // {
    //     "country":"ElSalvador",
    //     "country_cn":"萨尔瓦多",
    //     "country_code":"SV",
    //     "phone_code":"503"
    // },
    // {
    //     "country":"Syria",
    //     "country_cn":"叙利亚",
    //     "country_code":"SY",
    //     "phone_code":"963"
    // },

    // {
    //     "country":"Turksand Caicos Islands",
    //     "country_cn":"特克斯和凯科斯群岛",
    //     "country_code":"TC",
    //     "phone_code":"1649"
    // },
    // {
    //     "country":"Chad",
    //     "country_cn":"乍得",
    //     "country_code":"TD",
    //     "phone_code":"235"
    // },
    // {
    //     "country":"Togo",
    //     "country_cn":"多哥",
    //     "country_code":"TG",
    //     "phone_code":"228"
    // },
    // {
    //     "country":"Thailand",
    //     "country_cn":"泰国",
    //     "country_code":"TH",
    //     "phone_code":"66"
    // },
    // {
    //     "country":"Tajikistan",
    //     "country_cn":"塔吉克斯坦",
    //     "country_code":"TJ",
    //     "phone_code":"992"
    // },
    // {
    //     "country":"East Timor",
    //     "country_cn":"东帝汶",
    //     "country_code":"TL",
    //     "phone_code":"670"
    // },
    // {
    //     "country":"Turkmenistan",
    //     "country_cn":"土库曼斯坦",
    //     "country_code":"TM",
    //     "phone_code":"993"
    // },
    // {
    //     "country":"Tunisia",
    //     "country_cn":"突尼斯",
    //     "country_code":"TN",
    //     "phone_code":"216"
    // },
    // {
    //     "country":"Tonga",
    //     "country_cn":"汤加",
    //     "country_code":"TO",
    //     "phone_code":"676"
    // },
    // {
    //     "country":"Turkey",
    //     "country_cn":"土耳其",
    //     "country_code":"TR",
    //     "phone_code":"90"
    // },
    // {
    //     "country":"Trinidadand Tobago",
    //     "country_cn":"特立尼达和多巴哥",
    //     "country_code":"TT",
    //     "phone_code":"1868"
    // },
    // {
    //     "country":"Taiwan",
    //     "country_cn":"中国台湾",
    //     "country_code":"TW",
    //     "phone_code":"886"
    // },
    // {
    //     "country":"Tanzania",
    //     "country_cn":"坦桑尼亚",
    //     "country_code":"TZ",
    //     "phone_code":"255"
    // },
    // {
    //     "country":"Ukraine",
    //     "country_cn":"乌克兰",
    //     "country_code":"UA",
    //     "phone_code":"380"
    // },
    // {
    //     "country":"Uganda",
    //     "country_cn":"乌干达",
    //     "country_code":"UG",
    //     "phone_code":"256"
    // },

    // {
    //     "country":"Uruguay",
    //     "country_cn":"乌拉圭",
    //     "country_code":"UY",
    //     "phone_code":"598"
    // },
    // {
    //     "country":"Uzbekistan",
    //     "country_cn":"乌兹别克斯坦",
    //     "country_code":"UZ",
    //     "phone_code":"998"
    // },
    // {
    //     "country":"Saint Vincent and The Grenadines",
    //     "country_cn":"圣文森特和格林纳丁斯",
    //     "country_code":"VC",
    //     "phone_code":"1784"
    // },
    // {
    //     "country":"Venezuela",
    //     "country_cn":"委内瑞拉",
    //     "country_code":"VE",
    //     "phone_code":"58"
    // },
    // {
    //     "country":"VirginIslands,British",
    //     "country_cn":"英属处女群岛",
    //     "country_code":"VG",
    //     "phone_code":"1284"
    // },
    // {
    //     "country":"Vietnam",
    //     "country_cn":"越南",
    //     "country_code":"VN",
    //     "phone_code":"84"
    // },
    // {
    //     "country":"Vanuatu",
    //     "country_cn":"瓦努阿图",
    //     "country_code":"VU",
    //     "phone_code":"678"
    // },
    // {
    //     "country":"Samoa",
    //     "country_cn":"萨摩亚",
    //     "country_code":"WS",
    //     "phone_code":"685"
    // },
    // {
    //     "country":"Yemen",
    //     "country_cn":"也门",
    //     "country_code":"YE",
    //     "phone_code":"967"
    // },
    // {
    //     "country":"Mayotte",
    //     "country_cn":"马约特",
    //     "country_code":"YT",
    //     "phone_code":"269"
    // },

    // {
    //     "country":"Zambia",
    //     "country_cn":"赞比亚",
    //     "country_code":"ZM",
    //     "phone_code":"260"
    // },
    // {
    //     "country":"Zimbabwe",
    //     "country_cn":"津巴布韦",
    //     "country_code":"ZW",
    //     "phone_code":"263"
    // }

    // { id: "101", iso2: "US", iso3: "USA", country: "United States of America (USA)", country_cn: "美国", country_id: "US", prefix: "+1" },
    // { id: "90", iso2: "FR", iso3: "FRA", country: "France", country_cn: "法国", country_id: "FR", prefix: "+33" },
    // { id: "95", iso2: "AU", iso3: "AUS", country: "Australia", country_cn: "澳大利亚", country_id: "AU", prefix: "+61" },
    // { id: "97", iso2: "GB", iso3: "GBR", country: "United Kingdom", country_cn: "英国", country_id: "GB", prefix: "+44" },
    // { id: "98", iso2: "CA", iso3: "CAN", country: "Canada", country_cn: "加拿大", country_id: "CA", prefix: "+1" },
    // { id: "99", iso2: "MX", iso3: "MEX", country: "Mexico", country_cn: "墨西哥", country_id: "MX", prefix: "+52" },
    // { id: "100", iso2: "BR", iso3: "BRA", country: "Brazil", country_cn: "巴西", country_id: "BR", prefix: "+55" },
    // { id: "91", iso2: "CN", iso3: "CHN", country: "China", country_cn: "中国 内地", country_id: "CN", prefix: "+86" },

    // { id: "1", iso2: "GH", iso3: "GHA", country: "Ghana", country_cn: "加纳", country_id: "GH", prefix: "+233" },
    // { id: "2", iso2: "BZ", iso3: "BLZ", country: "Belize", country_cn: "伯利兹", country_id: "BZ", prefix: "+501" },
    // { id: "3", iso2: "VC", iso3: "VCT", country: "St. Vincent & the Grenadines", country_cn: "圣文森特和格林纳丁斯", country_id: "VC", prefix: "+1-784" },
    // { id: "4", iso2: "CV", iso3: "CPV", country: "Cape Verde", country_cn: "佛得角", country_id: "CV", prefix: "+238" },
    // { id: "5", iso2: "AM", iso3: "ARM", country: "Armenia", country_cn: "亚美尼亚", country_id: "AM", prefix: "+374" },
    // { id: "6", iso2: "GT", iso3: "GTM", country: "Guatemala", country_cn: "危地马拉", country_id: "GT", prefix: "+502" },
    // { id: "7", iso2: "NI", iso3: "NIC", country: "Nicaragua", country_cn: "尼加拉瓜", country_id: "NI", prefix: "+505" },
    // { id: "8", iso2: "TJ", iso3: "TJK", country: "Tajikistan", country_cn: "塔吉克斯坦", country_id: "TJ", prefix: "+992" },
    // { id: "9", iso2: "GY", iso3: "GUY", country: "Guyana", country_cn: "圭亚那", country_id: "GY", prefix: "+592" },
    // { id: "10", iso2: "OM", iso3: "OMN", country: "Oman", country_cn: "阿曼", country_id: "OM", prefix: "+968" },
    // { id: "11", iso2: "AI", iso3: "AIA", country: "Anguilla", country_cn: "安圭拉", country_id: "AI", prefix: "+1-264" },
    // { id: "12", iso2: "JM", iso3: "JAM", country: "Jamaica", country_cn: "牙买加", country_id: "JM", prefix: "+1-876" },
    // { id: "13", iso2: "ZA", iso3: "ZAF", country: "South Africa", country_cn: "南非", country_id: "ZA", prefix: "+27" },
    // { id: "14", iso2: "EG", iso3: "EGY", country: "Egypt", country_cn: "埃及", country_id: "EG", prefix: "+20" },
    // { id: "15", iso2: "LA", iso3: "LAO", country: "Laos", country_cn: "老挝", country_id: "LA", prefix: "+856" },
    // { id: "16", iso2: "HN", iso3: "HND", country: "Honduras", country_cn: "洪都拉斯", country_id: "HN", prefix: "+504" },
    // { id: "17", iso2: "BH", iso3: "BHR", country: "Bahrain", country_cn: "巴林", country_id: "BH", prefix: "+973" },
    // { id: "18", iso2: "DZ", iso3: "DZA", country: "Algeria", country_cn: "阿尔及利亚", country_id: "DZ", prefix: "+213" },
    // { id: "19", iso2: "NG", iso3: "NGA", country: "Nigeria", country_cn: "尼日利亚", country_id: "NG", prefix: "+234" },
    // { id: "20", iso2: "KG", iso3: "KGZ", country: "Kyrgyzstan", country_cn: "吉尔吉斯斯坦", country_id: "KG", prefix: "+996" },
    // { id: "21", iso2: "LK", iso3: "LKA", country: "Sri Lanka", country_cn: "斯里兰卡", country_id: "LK", prefix: "+94" },
    // { id: "22", iso2: "LB", iso3: "LBN", country: "Lebanon", country_cn: "黎巴嫩", country_id: "LB", prefix: "+961" },
    // { id: "23", iso2: "LU", iso3: "LUX", country: "Luxembourg", country_cn: "卢森堡", country_id: "LU", prefix: "+352" },
    // { id: "24", iso2: "AO", iso3: "AGO", country: "Angola", country_cn: "安哥拉", country_id: "AO", prefix: "+244" },
    // { id: "25", iso2: "CR", iso3: "CRI", country: "Costa Rica", country_cn: "哥斯达黎加", country_id: "CR", prefix: "+506" },
    // { id: "26", iso2: "PA", iso3: "PAN", country: "Panama", country_cn: "巴拿马", country_id: "PA", prefix: "+507" },
    // { id: "27", iso2: "PK", iso3: "PAK", country: "Pakistan", country_cn: "巴基斯坦", country_id: "PK", prefix: "+92" },
    // { id: "28", iso2: "EE", iso3: "EST", country: "Estonia", country_cn: "爱沙尼亚", country_id: "EE", prefix: "+372" },
    // { id: "29", iso2: "MD", iso3: "MDA", country: "Moldova", country_cn: "摩尔多瓦", country_id: "MD", prefix: "+373-533" },
    // { id: "30", iso2: "AZ", iso3: "AZE", country: "Azerbaijan", country_cn: "阿塞拜疆", country_id: "AZ", prefix: "+374-97" },
    // { id: "31", iso2: "LT", iso3: "LTU", country: "Lithuania", country_cn: "立陶宛", country_id: "LT", prefix: "+370" },
    // { id: "32", iso2: "CY", iso3: "CYP", country: "Cyprus", country_cn: "塞浦路斯", country_id: "CY", prefix: "+90-392" },
    // { id: "33", iso2: "LV", iso3: "LVA", country: "Latvia", country_cn: "拉脱维亚", country_id: "LV", prefix: "+371" },
    // { id: "34", iso2: "KZ", iso3: "KAZ", country: "Kazakhstan", country_cn: "哈萨克斯坦", country_id: "KZ", prefix: "+7" },
    // { id: "35", iso2: "QA", iso3: "QAT", country: "Qatar", country_cn: "卡塔尔", country_id: "QA", prefix: "+974" },
    // { id: "36", iso2: "BO", iso3: "BOL", country: "Bolivia", country_cn: "玻利维亚", country_id: "BO", prefix: "+591" },
    // { id: "37", iso2: "SI", iso3: "SVN", country: "Slovenia", country_cn: "斯洛文尼亚", country_id: "SI", prefix: "+386" },
    // { id: "38", iso2: "MK", iso3: "MKD", country: "Republic of Macedonia (FYROM)", country_cn: "马其顿", country_id: "MK", prefix: "+389" },
    // { id: "39", iso2: "SK", iso3: "SVK", country: "Slovakia", country_cn: "斯洛伐克", country_id: "SK", prefix: "+421" },
    // { id: "40", iso2: "MT", iso3: "MLT", country: "Malta", country_cn: "马耳他", country_id: "MT", prefix: "+356" },
    // { id: "41", iso2: "DO", iso3: "DOM", country: "Dominican", country_cn: "多米尼加", country_id: "DO", prefix: "+1-809and1-829" },
    // { id: "42", iso2: "BN", iso3: "BRN", country: "Brunei", country_cn: "文莱", country_id: "BN", prefix: "+673" },
    // { id: "43", iso2: "PY", iso3: "PRY", country: "Paraguay", country_cn: "巴拉圭", country_id: "PY", prefix: "+595" },
    // { id: "44", iso2: "IS", iso3: "ISL", country: "Iceland", country_cn: "冰岛", country_id: "IS", prefix: "+354" },
    // { id: "45", iso2: "HK", iso3: "HKG", country: "Hong Kong", country_cn: "香港", country_id: "HK", prefix: "+852" },
    // { id: "46", iso2: "KW", iso3: "KWT", country: "Kuwait", country_cn: "科威特", country_id: "KW", prefix: "+965" },
    // { id: "47", iso2: "VE", iso3: "VEN", country: "Venezuela", country_cn: "委内瑞拉", country_id: "VE", prefix: "+58" },
    // { id: "48", iso2: "BY", iso3: "BLR", country: "Belarus", country_cn: "白俄罗斯", country_id: "BY", prefix: "+375" },
    // { id: "49", iso2: "KR", iso3: "KOR", country: "South Korea", country_cn: "韩国 南朝鲜", country_id: "KR", prefix: "+82" },
    // { id: "50", iso2: "IN", iso3: "IND", country: "India", country_cn: "印度", country_id: "IN", prefix: "+91" },
    // { id: "51", iso2: "IL", iso3: "ISR", country: "Israel", country_cn: "以色列", country_id: "IL", prefix: "+972" },
    // { id: "52", iso2: "TW", iso3: "TWN", country: "Taiwan", country_cn: "中华民国（台湾）", country_id: "TW", prefix: "+886" },
    // { id: "53", iso2: "FI", iso3: "FIN", country: "Finland", country_cn: "芬兰", country_id: "FI", prefix: "+358" },
    // { id: "54", iso2: "TR", iso3: "TUR", country: "Turkey", country_cn: "土耳其", country_id: "TR", prefix: "+90" },
    // { id: "55", iso2: "EC", iso3: "ECU", country: "Ecuador", country_cn: "厄瓜多尔", country_id: "EC", prefix: "+593" },
    // { id: "56", iso2: "JP", iso3: "JPN", country: "Japan", country_cn: "日本", country_id: "JP", prefix: "+81" },
    // { id: "57", iso2: "SG", iso3: "SGP", country: "Singapore", country_cn: "新加坡", country_id: "SG", prefix: "+65" },
    // { id: "58", iso2: "NL", iso3: "NLD", country: "Netherlands", country_cn: "荷兰", country_id: "NL", prefix: "+31" },
    // { id: "59", iso2: "UY", iso3: "URY", country: "Uruguay", country_cn: "乌拉圭", country_id: "UY", prefix: "+598" },
    // { id: "60", iso2: "HR", iso3: "HRV", country: "Croatia", country_cn: "克罗地亚", country_id: "HR", prefix: "+385" },
    // { id: "61", iso2: "CZ", iso3: "CZE", country: "Czech", country_cn: "捷克", country_id: "CZ", prefix: "+420" },
    // { id: "62", iso2: "AT", iso3: "AUT", country: "Austria", country_cn: "奥地利", country_id: "AT", prefix: "+43" },
    // { id: "63", iso2: "ID", iso3: "IDN", country: "Indonesia", country_cn: "印尼", country_id: "ID", prefix: "+62" },
    // { id: "64", iso2: "BG", iso3: "BGR", country: "Bulgaria", country_cn: "保加利亚", country_id: "BG", prefix: "+359" },
    // { id: "65", iso2: "KH", iso3: "KHM", country: "Cambodia", country_cn: "柬埔寨", country_id: "KH", prefix: "+855" },
    // { id: "66", iso2: "PE", iso3: "PER", country: "Peru", country_cn: "秘鲁", country_id: "PE", prefix: "+51" },
    // { id: "67", iso2: "AL", iso3: "ALB", country: "Albania", country_cn: "阿尔巴尼亚", country_id: "AL", prefix: "+355" },
    // { id: "68", iso2: "IE", iso3: "IRL", country: "Ireland", country_cn: "爱尔兰", country_id: "IE", prefix: "+353" },
    // { id: "69", iso2: "VN", iso3: "VNM", country: "Vietnam", country_cn: "越南", country_id: "VN", prefix: "+84" },
    // { id: "70", iso2: "GR", iso3: "GRC", country: "Greece", country_cn: "希腊", country_id: "GR", prefix: "+30" },
    // { id: "71", iso2: "HU", iso3: "HUN", country: "Hungary", country_cn: "匈牙利", country_id: "HU", prefix: "+36" },
    // { id: "72", iso2: "CL", iso3: "CHL", country: "Chile", country_cn: "智利", country_id: "CL", prefix: "+56" },
    // { id: "73", iso2: "RO", iso3: "ROU", country: "Romania", country_cn: "罗马尼亚", country_id: "RO", prefix: "+40" },
    // { id: "74", iso2: "PL", iso3: "POL", country: "Poland", country_cn: "波兰", country_id: "PL", prefix: "+48" },
    // { id: "75", iso2: "UA", iso3: "UKR", country: "Ukraine", country_cn: "乌克兰", country_id: "UA", prefix: "+380" },
    // { id: "76", iso2: "AE", iso3: "ARE", country: "United Arab Emirates", country_cn: "阿联酋", country_id: "AE", prefix: "+971" },
    // { id: "77", iso2: "PT", iso3: "PRT", country: "Portugal", country_cn: "葡萄牙", country_id: "PT", prefix: "+351" },
    // { id: "78", iso2: "MY", iso3: "MYS", country: "Malaysia", country_cn: "马来西亚", country_id: "MY", prefix: "+60" },
    // { id: "79", iso2: "BE", iso3: "BEL", country: "Belgium", country_cn: "比利时", country_id: "BE", prefix: "+32" },
    // { id: "80", iso2: "DK", iso3: "DNK", country: "Denmark", country_cn: "丹麦", country_id: "DK", prefix: "+45" },
    // { id: "81", iso2: "SE", iso3: "SWE", country: "Sweden", country_cn: "瑞典", country_id: "SE", prefix: "+46" },
    // { id: "82", iso2: "SA", iso3: "SAU", country: "Saudi Arabia", country_cn: "沙特阿拉伯", country_id: "SA", prefix: "+966" },
    // { id: "83", iso2: "NO", iso3: "NOR", country: "Norway", country_cn: "挪威", country_id: "NO", prefix: "+47" },
    // { id: "84", iso2: "AR", iso3: "ARG", country: "Argentina", country_cn: "阿根廷", country_id: "AR", prefix: "+54" },
    // { id: "85", iso2: "NZ", iso3: "NZL", country: "New Zealand", country_cn: "新西兰", country_id: "NZ", prefix: "+64" },
    // { id: "86", iso2: "CO", iso3: "COL", country: "Colombia", country_cn: "哥伦比亚", country_id: "CO", prefix: "+57" },
    // { id: "87", iso2: "CH", iso3: "CHE", country: "Switzerland", country_cn: "瑞士", country_id: "CH", prefix: "+41" },
    // { id: "88", iso2: "PH", iso3: "PHL", country: "The Philippines", country_cn: "菲律宾", country_id: "PH", prefix: "+63" },
    // { id: "89", iso2: "DE", iso3: "DEU", country: "Germany", country_cn: "德国", country_id: "DE", prefix: "+49" },
    // // { id: "90", iso2: "FR", iso3: "FRA", country: "France", country_cn: "法国", country_id: "FR", prefix: "+33" },
    // { id: "91", iso2: "CN", iso3: "CHN", country: "China", country_cn: "中国 内地", country_id: "CN", prefix: "+86" },
    // { id: "92", iso2: "TH", iso3: "THA", country: "Thailand", country_cn: "泰国", country_id: "TH", prefix: "+66" },
    // { id: "93", iso2: "RU", iso3: "RUS", country: "Russian Federation", country_cn: "俄罗斯", country_id: "RU", prefix: "+7" },
    // { id: "94", iso2: "ES", iso3: "ESP", country: "Spain", country_cn: "西班牙", country_id: "ES", prefix: "+34" },
    // // { id: "95", iso2: "AU", iso3: "AUS", country: "Australia", country_cn: "澳大利亚", country_id: "AU", prefix: "+61" },
    // { id: "96", iso2: "IT", iso3: "ITA", country: "Italy", country_cn: "意大利", country_id: "IT", prefix: "+39" },
    // // { id: "97", iso2: "GB", iso3: "GBR", country: "United Kingdom", country_cn: "英国", country_id: "GB", prefix: "+44" },
    // // { id: "98", iso2: "CA", iso3: "CAN", country: "Canada", country_cn: "加拿大", country_id: "CA", prefix: "+1" },
    // // { id: "99", iso2: "MX", iso3: "MEX", country: "Mexico", country_cn: "墨西哥", country_id: "MX", prefix: "+52" },
    // // { id: "100", iso2: "BR", iso3: "BRA", country: "Brazil", country_cn: "巴西", country_id: "BR", prefix: "+55" },
    // // { id: "101", iso2: "US", iso3: "USA", country: "United States of America (USA)", country_cn: "美国", country_id: "US", prefix: "+1" },
    // { id: "102", iso2: "BA", iso3: "BIH", country: "Bosnia & Herzegovina", country_cn: "波黑", country_id: "BA", prefix: "+387" },
    // { id: "103", iso2: "PF", iso3: "PYF", country: "French polynesia", country_cn: "法属波利尼西亚", country_id: "PF", prefix: "+689" },
    // { id: "104", iso2: "AF", iso3: "AFG", country: "Afghanistan", country_cn: "阿富汗", country_id: "AF", prefix: "+93" },
    // { id: "105", iso2: "ME", iso3: "MNE", country: "Montenegro", country_cn: "黑山", country_id: "ME", prefix: "+382" },
    // { id: "106", iso2: "NP", iso3: "NPL", country: "Nepal", country_cn: "尼泊尔", country_id: "NP", prefix: "+977" },
    // { id: "107", iso2: "BD", iso3: "BGD", country: "Bangladesh", country_cn: "孟加拉", country_id: "BD", prefix: "+880" },
    // { id: "108", iso2: "GP", iso3: "GLP", country: "Guadeloupe", country_cn: "瓜德罗普", country_id: "GP", prefix: "+590" },
    // { id: "109", iso2: "MQ", iso3: "MTQ", country: "Martinique", country_cn: "马提尼克", country_id: "MQ", prefix: "+596" },
    // { id: "110", iso2: "PS", iso3: "PSE", country: "Palestinian territories", country_cn: "巴勒斯坦", country_id: "PS", prefix: "+970" },
    // { id: "111", iso2: "CI", iso3: "CIV", country: "C?te d'Ivoire", country_cn: "科特迪瓦", country_id: "CI", prefix: "+225" },
    // { id: "112", iso2: "GU", iso3: "GUM", country: "Guam", country_cn: "关岛", country_id: "GU", prefix: "+1-671" },
    // { id: "113", iso2: "AD", iso3: "AND", country: "Andorra", country_cn: "安道尔", country_id: "AD", prefix: "+376" },
    // { id: "114", iso2: "AG", iso3: "ATG", country: "Antigua & Barbuda", country_cn: "安提瓜和巴布达", country_id: "AG", prefix: "+1-268" },
    // { id: "115", iso2: "AQ", iso3: "ATA", country: "Antarctica", country_cn: "南极洲", country_id: "AQ" },
    // { id: "116", iso2: "AS", iso3: "ASM", country: "American Samoa", country_cn: "美属萨摩亚", country_id: "AS", prefix: "+1-684" },
    // { id: "117", iso2: "AW", iso3: "ABW", country: "Aruba", country_cn: "阿鲁巴", country_id: "AW", prefix: "+297" },
    // { id: "118", iso2: "AX", iso3: "ALA", country: "?aland Island", country_cn: "奥兰群岛", country_id: "AX", prefix: "+358-18" },
    // { id: "119", iso2: "BB", iso3: "BRB", country: "Barbados", country_cn: "巴巴多斯", country_id: "BB", prefix: "+1-246" },
    // { id: "120", iso2: "BF", iso3: "BFA", country: "Burkina", country_cn: "布基纳法索", country_id: "BF", prefix: "+226" },
    // { id: "121", iso2: "BI", iso3: "BDI", country: "Burundi", country_cn: "布隆迪", country_id: "BI", prefix: "+257" },
    // { id: "122", iso2: "BJ", iso3: "BEN", country: "Benin", country_cn: "贝宁", country_id: "BJ", prefix: "+229" },
    // { id: "123", iso2: "BL", iso3: "BLM", country: "Saint Barthélemy", country_cn: "圣巴泰勒米岛", country_id: "BL" },
    // { id: "124", iso2: "BM", iso3: "BMU", country: "Bermuda", country_cn: "百慕大", country_id: "BM", prefix: "+1-441" },
    // { id: "125", iso2: "BQ", iso3: "BES", country: "Caribbean Netherlands", country_cn: "荷兰加勒比区", country_id: "BQ" },
    // { id: "126", iso2: "BS", iso3: "BHS", country: "The Bahamas", country_cn: "巴哈马", country_id: "BS", prefix: "+1-242" },
    // { id: "127", iso2: "BT", iso3: "BTN", country: "Bhutan", country_cn: "不丹", country_id: "BT", prefix: "+975" },
    // { id: "128", iso2: "BV", iso3: "BVT", country: "Bouvet Island", country_cn: "布韦岛", country_id: "BV" },
    // { id: "129", iso2: "BW", iso3: "BWA", country: "Botswana", country_cn: "博茨瓦纳", country_id: "BW", prefix: "+267" },
    // { id: "130", iso2: "CC", iso3: "CCK", country: "Cocos (Keeling) Islands", country_cn: "科科斯群岛", country_id: "CC", prefix: "+61" },
    // { id: "131", iso2: "CF", iso3: "CAF", country: "Central African Republic", country_cn: "中非", country_id: "CF", prefix: "+236" },
    // { id: "132", iso2: "CM", iso3: "CMR", country: "Cameroon", country_cn: "喀麦隆", country_id: "CM", prefix: "+237" },
    // { id: "133", iso2: "CU", iso3: "CUB", country: "Cuba", country_cn: "古巴", country_id: "CU", prefix: "+53" },
    // { id: "134", iso2: "CX", iso3: "CXR", country: "Christmas Island", country_cn: "圣诞岛", country_id: "CX", prefix: "+61" },
    // { id: "135", iso2: "DJ", iso3: "DJI", country: "Djibouti", country_cn: "吉布提", country_id: "DJ", prefix: "+253" },
    // { id: "136", iso2: "DM", iso3: "DMA", country: "Dominica", country_cn: "多米尼克", country_id: "DM", prefix: "+1-767" },
    // { id: "137", iso2: "EH", iso3: "ESH", country: "Western Sahara", country_cn: "西撒哈拉", country_id: "EH", prefix: "+212" },
    // { id: "138", iso2: "ER", iso3: "ERI", country: "Eritrea", country_cn: "厄立特里亚", country_id: "ER", prefix: "+291" },
    // { id: "139", iso2: "FJ", iso3: "FJI", country: "Fiji", country_cn: "斐济群岛", country_id: "FJ", prefix: "+679" },
    // { id: "140", iso2: "FK", iso3: "FLK", country: "Falkland Islands", country_cn: "马尔维纳斯群岛（ 福克兰）", country_id: "FK", prefix: "+500" },
    // { id: "141", iso2: "FM", iso3: "FSM", country: "Federated States of Micronesia", country_cn: "密克罗尼西亚联邦", country_id: "FM", prefix: "+691" },
    // { id: "142", iso2: "FO", iso3: "FRO", country: "Faroe Islands", country_cn: "法罗群岛", country_id: "FO", prefix: "+298" },
    // { id: "143", iso2: "GA", iso3: "GAB", country: "Gabon", country_cn: "加蓬", country_id: "GA", prefix: "+241" },
    // { id: "144", iso2: "GD", iso3: "GRD", country: "Grenada", country_cn: "格林纳达", country_id: "GD", prefix: "+1-473" },
    // { id: "145", iso2: "GE", iso3: "GEO", country: "Georgia", country_cn: "格鲁吉亚", country_id: "GE", prefix: "+995" },
    // { id: "146", iso2: "GF", iso3: "GUF", country: "French Guiana", country_cn: "法属圭亚那", country_id: "GF", prefix: "+594" },
    // { id: "147", iso2: "GI", iso3: "GIB", country: "Gibraltar", country_cn: "直布罗陀", country_id: "GI", prefix: "+350" },
    // { id: "148", iso2: "GL", iso3: "GRL", country: "Greenland", country_cn: "格陵兰", country_id: "GL", prefix: "+299" },
    // { id: "149", iso2: "GN", iso3: "GIN", country: "Guinea", country_cn: "几内亚", country_id: "GN", prefix: "+224" },
    // { id: "150", iso2: "GQ", iso3: "GNQ", country: "Equatorial Guinea", country_cn: "赤道几内亚", country_id: "GQ", prefix: "+240" },
    // { id: "151", iso2: "GS", iso3: "SGS", country: "South Georgia and the South Sandwich Islands", country_cn: "南乔治亚岛和南桑威奇群岛", country_id: "GS" },
    // { id: "152", iso2: "GW", iso3: "GNB", country: "Guinea-Bissau", country_cn: "几内亚比绍", country_id: "GW", prefix: "+245" },
    // { id: "153", iso2: "HM", iso3: "HMD", country: "Heard Island and McDonald Islands", country_cn: "赫德岛和麦克唐纳群岛", country_id: "HM" },
    // { id: "154", iso2: "HT", iso3: "HTI", country: "Haiti", country_cn: "海地", country_id: "HT", prefix: "+509" },
    // { id: "155", iso2: "IM", iso3: "IMN", country: "Isle of Man", country_cn: "马恩岛", country_id: "IM", prefix: "+44" },
    // { id: "156", iso2: "IO", iso3: "IOT", country: "British Indian Ocean Territory", country_cn: "英属印度洋领地", country_id: "IO", prefix: "+246" },
    // { id: "157", iso2: "IQ", iso3: "IRQ", country: "Iraq", country_cn: "伊拉克", country_id: "IQ", prefix: "+964" },
    // { id: "158", iso2: "IR", iso3: "IRN", country: "Iran", country_cn: "伊朗", country_id: "IR", prefix: "+98" },
    // { id: "159", iso2: "JE", iso3: "JEY", country: "Jersey", country_cn: "泽西岛", country_id: "JE", prefix: "+44" },
    // { id: "160", iso2: "JO", iso3: "JOR", country: "Jordan", country_cn: "约旦", country_id: "JO", prefix: "+962" },
    // { id: "161", iso2: "KI", iso3: "KIR", country: "Kiribati", country_cn: "基里巴斯", country_id: "KI", prefix: "+686" },
    // { id: "162", iso2: "KM", iso3: "COM", country: "The Comoros", country_cn: "科摩罗", country_id: "KM", prefix: "+269" },
    // { id: "163", iso2: "KY", iso3: "CYM", country: "Cayman Islands", country_cn: "开曼群岛", country_id: "KY", prefix: "+1-345" },
    // { id: "164", iso2: "LI", iso3: "LIE", country: "Liechtenstein", country_cn: "列支敦士登", country_id: "LI", prefix: "+423" },
    // { id: "165", iso2: "LR", iso3: "LBR", country: "Liberia", country_cn: "利比里亚", country_id: "LR", prefix: "+231" },
    // { id: "166", iso2: "LS", iso3: "LSO", country: "Lesotho", country_cn: "莱索托", country_id: "LS", prefix: "+266" },
    // { id: "167", iso2: "LY", iso3: "LBY", country: "Libya", country_cn: "利比亚", country_id: "LY", prefix: "+218" },
    // { id: "168", iso2: "MA", iso3: "MAR", country: "Morocco", country_cn: "摩洛哥", country_id: "MA", prefix: "+212" },
    // { id: "169", iso2: "MC", iso3: "MCO", country: "Monaco", country_cn: "摩纳哥", country_id: "MC", prefix: "+377" },
    // { id: "170", iso2: "MF", iso3: "MAF", country: "Saint Martin (France)", country_cn: "法属圣马丁", country_id: "MF" },
    // { id: "171", iso2: "MG", iso3: "MDG", country: "Madagascar", country_cn: "马达加斯加", country_id: "MG", prefix: "+261" },
    // { id: "172", iso2: "MH", iso3: "MHL", country: "Marshall islands", country_cn: "马绍尔群岛", country_id: "MH", prefix: "+692" },
    // { id: "173", iso2: "ML", iso3: "MLI", country: "Mali", country_cn: "马里", country_id: "ML", prefix: "+223" },
    // { id: "174", iso2: "MM", iso3: "MMR", country: "Myanmar (Burma)", country_cn: "缅甸", country_id: "MM", prefix: "+95" },
    // { id: "175", iso2: "MO", iso3: "MAC", country: "Macao", country_cn: "澳门", country_id: "MO", prefix: "+853" },
    // { id: "176", iso2: "MR", iso3: "MRT", country: "Mauritania", country_cn: "毛里塔尼亚", country_id: "MR", prefix: "+222" },
    // { id: "177", iso2: "MS", iso3: "MSR", country: "Montserrat", country_cn: "蒙塞拉特岛", country_id: "MS", prefix: "+1-664" },
    // { id: "178", iso2: "MV", iso3: "MDV", country: "Maldives", country_cn: "马尔代夫", country_id: "MV", prefix: "+960" },
    // { id: "179", iso2: "MW", iso3: "MWI", country: "Malawi", country_cn: "马拉维", country_id: "MW", prefix: "+265" },
    // { id: "180", iso2: "NA", iso3: "NAM", country: "Namibia", country_cn: "纳米比亚", country_id: "NA", prefix: "+264" },
    // { id: "181", iso2: "NE", iso3: "NER", country: "Niger", country_cn: "尼日尔", country_id: "NE", prefix: "+227" },
    // { id: "182", iso2: "NF", iso3: "NFK", country: "Norfolk Island", country_cn: "诺福克岛", country_id: "NF", prefix: "+672" },
    // { id: "183", iso2: "NR", iso3: "NRU", country: "Nauru", country_cn: "瑙鲁", country_id: "NR", prefix: "+674" },
    // { id: "184", iso2: "PG", iso3: "PNG", country: "Papua New Guinea", country_cn: "巴布亚新几内亚", country_id: "PG", prefix: "+675" },
    // { id: "185", iso2: "PN", iso3: "PCN", country: "Pitcairn Islands", country_cn: "皮特凯恩群岛", country_id: "PN" },
    // { id: "186", iso2: "PR", iso3: "PRI", country: "Puerto Rico", country_cn: "波多黎各", country_id: "PR", prefix: "+1-787and1-939" },
    // { id: "187", iso2: "PW", iso3: "PLW", country: "Palau", country_cn: "帕劳", country_id: "PW", prefix: "+680" },
    // { id: "188", iso2: "RE", iso3: "REU", country: "Réunion", country_cn: "留尼汪", country_id: "RE", prefix: "+262" },
    // { id: "189", iso2: "RS", iso3: "SRB", country: "Serbia", country_cn: "塞尔维亚", country_id: "RS", prefix: "+381" },
    // { id: "190", iso2: "RW", iso3: "RWA", country: "Rwanda", country_cn: "卢旺达", country_id: "RW", prefix: "+250" },
    // { id: "191", iso2: "SB", iso3: "SLB", country: "Solomon Islands", country_cn: "所罗门群岛", country_id: "SB", prefix: "+677" },
    // { id: "192", iso2: "SC", iso3: "SYC", country: "Seychelles", country_cn: "塞舌尔", country_id: "SC", prefix: "+248" },
    // { id: "193", iso2: "SD", iso3: "SDN", country: "Sudan", country_cn: "苏丹", country_id: "SD", prefix: "+249" },
    // {
    //   id: "194",
    //   iso2: "SJ",
    //   iso3: "SJM",
    //   country: "Template:Country data SJM Svalbard",
    //   country_cn: "斯瓦尔巴群岛和 扬马延岛",
    //   country_id: "SJ",
    //   prefix: "+47",
    // },
    // { id: "195", iso2: "SL", iso3: "SLE", country: "Sierra Leone", country_cn: "塞拉利昂", country_id: "SL", prefix: "+232" },
    // { id: "196", iso2: "SM", iso3: "SMR", country: "San Marino", country_cn: "圣马力诺", country_id: "SM", prefix: "+378" },
    // { id: "197", iso2: "SN", iso3: "SEN", country: "Senegal", country_cn: "塞内加尔", country_id: "SN", prefix: "+221" },
    // { id: "198", iso2: "SO", iso3: "SOM", country: "Somalia", country_cn: "索马里", country_id: "SO", prefix: "+252" },
    // { id: "199", iso2: "SR", iso3: "SUR", country: "Suriname", country_cn: "苏里南", country_id: "SR", prefix: "+597" },
    // { id: "200", iso2: "SS", iso3: "SSD", country: "South Sudan", country_cn: "南苏丹", country_id: "SS" },
    // { id: "201", iso2: "ST", iso3: "STP", country: "Sao Tome & Principe", country_cn: "圣多美和普林西比", country_id: "ST", prefix: "+239" },
    // { id: "202", iso2: "SV", iso3: "SLV", country: "El Salvador", country_cn: "萨尔瓦多", country_id: "SV", prefix: "+503" },
    // { id: "203", iso2: "SY", iso3: "SYR", country: "Syria", country_cn: "叙利亚", country_id: "SY", prefix: "+963" },
    // { id: "204", iso2: "SZ", iso3: "SWZ", country: "Swaziland", country_cn: "斯威士兰", country_id: "SZ", prefix: "+268" },
    // { id: "205", iso2: "TC", iso3: "TCA", country: "Turks & Caicos Islands", country_cn: "特克斯和凯科斯群岛", country_id: "TC", prefix: "+1-649" },
    // { id: "206", iso2: "TD", iso3: "TCD", country: "Chad", country_cn: "乍得", country_id: "TD", prefix: "+235" },
    // { id: "207", iso2: "TG", iso3: "TGO", country: "Togo", country_cn: "多哥", country_id: "TG", prefix: "+228" },
    // { id: "208", iso2: "TK", iso3: "TKL", country: "Tokelau", country_cn: "托克劳", country_id: "TK", prefix: "+690" },
    // { id: "209", iso2: "TL", iso3: "TLS", country: "Timor-Leste (East Timor)", country_cn: "东帝汶", country_id: "TL", prefix: "+670" },
    // { id: "210", iso2: "TN", iso3: "TUN", country: "Tunisia", country_cn: "突尼斯", country_id: "TN", prefix: "+216" },
    // { id: "211", iso2: "TO", iso3: "TON", country: "Tonga", country_cn: "汤加", country_id: "TO", prefix: "+676" },
    // { id: "212", iso2: "TV", iso3: "TUV", country: "Tuvalu", country_cn: "图瓦卢", country_id: "TV", prefix: "+688" },
    // { id: "213", iso2: "TZ", iso3: "TZA", country: "Tanzania", country_cn: "坦桑尼亚", country_id: "TZ", prefix: "+255" },
    // { id: "214", iso2: "UG", iso3: "UGA", country: "Uganda", country_cn: "乌干达", country_id: "UG", prefix: "+256" },
    // { id: "215", iso2: "VA", iso3: "VAT", country: "Vatican City (The Holy See)", country_cn: "梵蒂冈", country_id: "VA", prefix: "+379" },
    // { id: "216", iso2: "VG", iso3: "VGB", country: "British Virgin Islands", country_cn: "英属维尔京群岛", country_id: "VG", prefix: "+1-284" },
    // { id: "217", iso2: "VI", iso3: "VIR", country: "United States Virgin Islands", country_cn: "美属维尔京群岛", country_id: "VI", prefix: "+1-340" },
    // { id: "218", iso2: "WF", iso3: "WLF", country: "Wallis and Futuna", country_cn: "瓦利斯和富图纳", country_id: "WF", prefix: "+681" },
    // { id: "219", iso2: "WS", iso3: "WSM", country: "Samoa", country_cn: "萨摩亚", country_id: "WS", prefix: "+685" },
    // { id: "220", iso2: "YE", iso3: "YEM", country: "Yemen", country_cn: "也门", country_id: "YE", prefix: "+967" },
    // { id: "221", iso2: "YT", iso3: "MYT", country: "Mayotte", country_cn: "马约特", country_id: "YT", prefix: "+262" },
    // { id: "222", iso2: "ZM", iso3: "ZMB", country: "Zambia", country_cn: "赞比亚", country_id: "ZM", prefix: "+260" },
    // { id: "223", iso2: "ZW", iso3: "ZWE", country: "Zimbabwe", country_cn: "津巴布韦", country_id: "ZW", prefix: "+263" },
    // { id: "224", iso2: "CG", iso3: "COG", country: "Congo", country_cn: "刚果（布）", country_id: "CG", prefix: "+242" },
    // { id: "225", iso2: "CD", iso3: "COD", country: "Democratic Republic of the Congo", country_cn: "刚果（金）", country_id: "CD", prefix: "+243" },
    // { id: "226", iso2: "MZ", iso3: "MOZ", country: "Mozambique", country_cn: "莫桑比克", country_id: "MZ", prefix: "+258" },
    // { id: "227", iso2: "GG", iso3: "GGY", country: "Guernsey", country_cn: "根西岛", country_id: "GG", prefix: "+44" },
    // { id: "228", iso2: "GM", iso3: "GMB", country: "Gambia", country_cn: "冈比亚", country_id: "GM", prefix: "+220" },
    // { id: "229", iso2: "MP", iso3: "MNP", country: "Northern Mariana Islands", country_cn: "北马里亚纳群岛", country_id: "MP", prefix: "+1-670" },
    // { id: "230", iso2: "ET", iso3: "ETH", country: "Ethiopia", country_cn: "埃塞俄比亚", country_id: "ET", prefix: "+251" },
    // { id: "231", iso2: "NC", iso3: "NCL", country: "New Caledonia", country_cn: "新喀里多尼亚", country_id: "NC", prefix: "+687" },
    // { id: "232", iso2: "VU", iso3: "VUT", country: "Vanuatu", country_cn: "瓦努阿图", country_id: "VU", prefix: "+678" },
    // { id: "233", iso2: "TF", iso3: "ATF", country: "French Southern Territories", country_cn: "法属南部领地", country_id: "TF" },
    // { id: "234", iso2: "NU", iso3: "NIU", country: "Niue", country_cn: "纽埃", country_id: "NU", prefix: "+683" },
    // { id: "235", iso2: "UM", iso3: "UMI", country: "United States Minor Outlying Islands", country_cn: "美国本土外小岛屿", country_id: "UM" },
    // { id: "236", iso2: "CK", iso3: "COK", country: "Cook Islands", country_cn: "库克群岛", country_id: "CK", prefix: "+682" },
    // { id: "237", iso2: "TT", iso3: "TTO", country: "Trinidad & Tobago", country_cn: "特立尼达和多巴哥", country_id: "TT", prefix: "+1-868" },
    // { id: "238", iso2: "KP", iso3: "PRK", country: "North Korea", country_cn: "朝鲜 北朝鲜", country_id: "KP", prefix: "+850" },
    // { id: "239", iso2: "TM", iso3: "TKM", country: "Turkmenistan", country_cn: "土库曼斯坦", country_id: "TM", prefix: "+993" },
    // { id: "240", iso2: "UZ", iso3: "UZB", country: "Uzbekistan", country_cn: "乌兹别克斯坦", country_id: "UZ", prefix: "+998" },
    // { id: "241", iso2: "KN", iso3: "KNA", country: "St. Kitts & Nevis", country_cn: "圣基茨和尼维斯", country_id: "KN", prefix: "+1-869" },
    // { id: "242", iso2: "PM", iso3: "SPM", country: "Saint-Pierre and Miquelon", country_cn: "圣皮埃尔和密克隆", country_id: "PM", prefix: "+508" },
    // { id: "243", iso2: "SH", iso3: "SHN", country: "St. Helena & Dependencies", country_cn: "圣赫勒拿", country_id: "SH", prefix: "+290" },
    // { id: "244", iso2: "LC", iso3: "LCA", country: "St. Lucia", country_cn: "圣卢西亚", country_id: "LC", prefix: "+1-758" },
    // { id: "245", iso2: "MU", iso3: "MUS", country: "Mauritius", country_cn: "毛里求斯", country_id: "MU", prefix: "+230" },
    // { id: "246", iso2: "KE", iso3: "KEN", country: "Kenya", country_cn: "肯尼亚", country_id: "KE", prefix: "+254" },
    // { id: "247", iso2: "MN", iso3: "MNG", country: "Mongolia", country_cn: "蒙古国 蒙古", country_id: "MN", prefix: "+976" },
  ],
  countryList: [
    {
      country: "United States",
      chinese_name: "美国",
      country_code: "US",
      phone_code: "1",
    },
    {
      country: "Andorra",
      chinese_name: "安道尔",
      country_code: "AD",
      phone_code: "376",
    },
    {
      country: "United Arab Emirates",
      chinese_name: "阿拉伯联合酋长国",
      country_code: "AE",
      phone_code: "971",
    },
    {
      country: "Afghanistan",
      chinese_name: "阿富汗",
      country_code: "AF",
      phone_code: "93",
    },
    {
      country: "Antigua and Barbuda",
      chinese_name: "安提瓜和巴布达",
      country_code: "AG",
      phone_code: "1268",
    },
    {
      country: "Anguilla",
      chinese_name: "安圭拉",
      country_code: "AI",
      phone_code: "1264",
    },
    {
      country: "Albania",
      chinese_name: "阿尔巴尼亚",
      country_code: "AL",
      phone_code: "355",
    },
    {
      country: "Armenia",
      chinese_name: "亚美尼亚",
      country_code: "AM",
      phone_code: "374",
    },
    {
      country: "Angola",
      chinese_name: "安哥拉",
      country_code: "AO",
      phone_code: "244",
    },
    {
      country: "Argentina",
      chinese_name: "阿根廷",
      country_code: "AR",
      phone_code: "54",
    },
    {
      country: "American Samoa",
      chinese_name: "美属萨摩亚",
      country_code: "AS",
      phone_code: "1684",
    },
    {
      country: "Austria",
      chinese_name: "奥地利",
      country_code: "AT",
      phone_code: "43",
    },
    {
      country: "Australia",
      chinese_name: "澳大利亚",
      country_code: "AU",
      phone_code: "61",
    },
    {
      country: "Aruba",
      chinese_name: "阿鲁巴",
      country_code: "AW",
      phone_code: "297",
    },
    {
      country: "Azerbaijan",
      chinese_name: "阿塞拜疆",
      country_code: "AZ",
      phone_code: "994",
    },
    {
      country: "Bosniaand Herzegovina",
      chinese_name: "波斯尼亚和黑塞哥维那",
      country_code: "BA",
      phone_code: "387",
    },
    {
      country: "Barbados",
      chinese_name: "巴巴多斯",
      country_code: "BB",
      phone_code: "1246",
    },
    {
      country: "Bangladesh",
      chinese_name: "孟加拉国",
      country_code: "BD",
      phone_code: "880",
    },
    {
      country: "Belgium",
      chinese_name: "比利时",
      country_code: "BE",
      phone_code: "32",
    },
    {
      country: "Burkina Faso",
      chinese_name: "布基纳法索",
      country_code: "BF",
      phone_code: "226",
    },
    {
      country: "Bulgaria",
      chinese_name: "保加利亚",
      country_code: "BG",
      phone_code: "359",
    },
    {
      country: "Bahrain",
      chinese_name: "巴林",
      country_code: "BH",
      phone_code: "973",
    },
    {
      country: "Burundi",
      chinese_name: "布隆迪",
      country_code: "BI",
      phone_code: "257",
    },
    {
      country: "Benin",
      chinese_name: "贝宁",
      country_code: "BJ",
      phone_code: "229",
    },
    {
      country: "Bermuda",
      chinese_name: "百慕大群岛",
      country_code: "BM",
      phone_code: "1441",
    },
    {
      country: "Brunei",
      chinese_name: "文莱",
      country_code: "BN",
      phone_code: "673",
    },
    {
      country: "Bolivia",
      chinese_name: "玻利维亚",
      country_code: "BO",
      phone_code: "591",
    },
    {
      country: "Caribisch Nederland",
      chinese_name: "荷兰加勒比",
      country_code: "BQ",
      phone_code: "599",
    },
    {
      country: "Brazil",
      chinese_name: "巴西",
      country_code: "BR",
      phone_code: "55",
    },
    {
      country: "Bahamas",
      chinese_name: "巴哈马",
      country_code: "BS",
      phone_code: "1242",
    },
    {
      country: "Bhutan",
      chinese_name: "不丹",
      country_code: "BT",
      phone_code: "975",
    },
    {
      country: "Botswana",
      chinese_name: "博茨瓦纳",
      country_code: "BW",
      phone_code: "267",
    },
    {
      country: "Belarus",
      chinese_name: "白俄罗斯",
      country_code: "BY",
      phone_code: "375",
    },
    {
      country: "Belize",
      chinese_name: "伯利兹",
      country_code: "BZ",
      phone_code: "501",
    },
    {
      country: "Canada",
      chinese_name: "加拿大",
      country_code: "CA",
      phone_code: "1",
    },
    {
      country: "Democratic Republic of theCongo",
      chinese_name: "刚果民主共和国",
      country_code: "CD",
      phone_code: "243",
    },
    {
      country: "Central African Republic",
      chinese_name: "中非共和国",
      country_code: "CF",
      phone_code: "236",
    },
    {
      country: "Republic Of The Congo",
      chinese_name: "刚果共和国",
      country_code: "CG",
      phone_code: "242",
    },
    {
      country: "Switzerland",
      chinese_name: "瑞士",
      country_code: "CH",
      phone_code: "41",
    },
    {
      country: "Ivory Coast",
      chinese_name: "象牙海岸",
      country_code: "CI",
      phone_code: "225",
    },
    {
      country: "Cook Islands",
      chinese_name: "库克群岛",
      country_code: "CK",
      phone_code: "682",
    },
    {
      country: "Chile",
      chinese_name: "智利",
      country_code: "CL",
      phone_code: "56",
    },
    {
      country: "Cameroon",
      chinese_name: "喀麦隆",
      country_code: "CM",
      phone_code: "237",
    },
    {
      country: "China",
      chinese_name: "中国",
      country_code: "CN",
      phone_code: "86",
    },
    {
      country: "Colombia",
      chinese_name: "哥伦比亚",
      country_code: "CO",
      phone_code: "57",
    },
    {
      country: "CostaRica",
      chinese_name: "哥斯达黎加",
      country_code: "CR",
      phone_code: "506",
    },
    {
      country: "Cuba",
      chinese_name: "古巴",
      country_code: "CU",
      phone_code: "53",
    },
    {
      country: "Cape Verde",
      chinese_name: "开普",
      country_code: "CV",
      phone_code: "238",
    },
    {
      country: "Curacao",
      chinese_name: "库拉索",
      country_code: "CW",
      phone_code: "599",
    },
    {
      country: "Cyprus",
      chinese_name: "塞浦路斯",
      country_code: "CY",
      phone_code: "357",
    },
    {
      country: "Czech",
      chinese_name: "捷克",
      country_code: "CZ",
      phone_code: "420",
    },
    {
      country: "Germany",
      chinese_name: "德国",
      country_code: "DE",
      phone_code: "49",
    },
    {
      country: "Djibouti",
      chinese_name: "吉布提",
      country_code: "DJ",
      phone_code: "253",
    },
    {
      country: "Denmark",
      chinese_name: "丹麦",
      country_code: "DK",
      phone_code: "45",
    },
    {
      country: "Dominica",
      chinese_name: "多米尼加",
      country_code: "DM",
      phone_code: "1767",
    },
    {
      country: "dominican republic",
      chinese_name: "多米尼加共和国",
      country_code: "DO",
      phone_code: "1809",
    },
    {
      country: "Algeria",
      chinese_name: "阿尔及利亚",
      country_code: "DZ",
      phone_code: "213",
    },
    {
      country: "Ecuador",
      chinese_name: "厄瓜多尔",
      country_code: "EC",
      phone_code: "593",
    },
    {
      country: "Estonia",
      chinese_name: "爱沙尼亚",
      country_code: "EE",
      phone_code: "372",
    },
    {
      country: "Egypt",
      chinese_name: "埃及",
      country_code: "EG",
      phone_code: "20",
    },
    {
      country: "Eritrea",
      chinese_name: "厄立特里亚",
      country_code: "ER",
      phone_code: "291",
    },
    {
      country: "Spain",
      chinese_name: "西班牙",
      country_code: "ES",
      phone_code: "34",
    },
    {
      country: "Ethiopia",
      chinese_name: "埃塞俄比亚",
      country_code: "ET",
      phone_code: "251",
    },
    {
      country: "Finland",
      chinese_name: "芬兰",
      country_code: "FI",
      phone_code: "358",
    },
    {
      country: "Fiji",
      chinese_name: "斐济",
      country_code: "FJ",
      phone_code: "679",
    },
    {
      country: "Micronesia",
      chinese_name: "密克罗尼西亚",
      country_code: "FM",
      phone_code: "691",
    },
    {
      country: "Faroe Islands",
      chinese_name: "法罗群岛",
      country_code: "FO",
      phone_code: "298",
    },
    {
      country: "France",
      chinese_name: "法国",
      country_code: "FR",
      phone_code: "33",
    },
    {
      country: "Gabon",
      chinese_name: "加蓬",
      country_code: "GA",
      phone_code: "241",
    },
    {
      country: "United Kingdom",
      chinese_name: "英国",
      country_code: "GB",
      phone_code: "44",
    },
    {
      country: "Grenada",
      chinese_name: "格林纳达",
      country_code: "GD",
      phone_code: "1473",
    },
    {
      country: "Georgia",
      chinese_name: "格鲁吉亚",
      country_code: "GE",
      phone_code: "995",
    },
    {
      country: "French Guiana",
      chinese_name: "法属圭亚那",
      country_code: "GF",
      phone_code: "594",
    },
    {
      country: "Ghana",
      chinese_name: "加纳",
      country_code: "GH",
      phone_code: "233",
    },
    {
      country: "Gibraltar",
      chinese_name: "直布罗陀",
      country_code: "GI",
      phone_code: "350",
    },
    {
      country: "Greenland",
      chinese_name: "格陵兰岛",
      country_code: "GL",
      phone_code: "299",
    },
    {
      country: "Gambia",
      chinese_name: "冈比亚",
      country_code: "GM",
      phone_code: "220",
    },
    {
      country: "Guinea",
      chinese_name: "几内亚",
      country_code: "GN",
      phone_code: "224",
    },
    {
      country: "Guadeloupe",
      chinese_name: "瓜德罗普岛",
      country_code: "GP",
      phone_code: "590",
    },
    {
      country: "Equatorial Guinea",
      chinese_name: "赤道几内亚",
      country_code: "GQ",
      phone_code: "240",
    },
    {
      country: "Greece",
      chinese_name: "希腊",
      country_code: "GR",
      phone_code: "30",
    },
    {
      country: "Guatemala",
      chinese_name: "瓜地马拉",
      country_code: "GT",
      phone_code: "502",
    },
    {
      country: "Guam",
      chinese_name: "关岛",
      country_code: "GU",
      phone_code: "1671",
    },
    {
      country: "Guinea-Bissau",
      chinese_name: "几内亚比绍共和国",
      country_code: "GW",
      phone_code: "245",
    },
    {
      country: "Guyana",
      chinese_name: "圭亚那",
      country_code: "GY",
      phone_code: "592",
    },
    {
      country: "Hong Kong",
      chinese_name: "中国香港",
      country_code: "HK",
      phone_code: "852",
    },
    {
      country: "Honduras",
      chinese_name: "洪都拉斯",
      country_code: "HN",
      phone_code: "504",
    },
    {
      country: "Croatia",
      chinese_name: "克罗地亚",
      country_code: "HR",
      phone_code: "385",
    },
    {
      country: "Haiti",
      chinese_name: "海地",
      country_code: "HT",
      phone_code: "509",
    },
    {
      country: "Hungary",
      chinese_name: "匈牙利",
      country_code: "HU",
      phone_code: "36",
    },
    {
      country: "Indonesia",
      chinese_name: "印度尼西亚",
      country_code: "ID",
      phone_code: "62",
    },
    {
      country: "Ireland",
      chinese_name: "爱尔兰",
      country_code: "IE",
      phone_code: "353",
    },
    {
      country: "Israel",
      chinese_name: "以色列",
      country_code: "IL",
      phone_code: "972",
    },
    {
      country: "India",
      chinese_name: "印度",
      country_code: "IN",
      phone_code: "91",
    },
    {
      country: "Iraq",
      chinese_name: "伊拉克",
      country_code: "IQ",
      phone_code: "964",
    },
    {
      country: "Iran",
      chinese_name: "伊朗",
      country_code: "IR",
      phone_code: "98",
    },
    {
      country: "Iceland",
      chinese_name: "冰岛",
      country_code: "IS",
      phone_code: "354",
    },
    {
      country: "Italy",
      chinese_name: "意大利",
      country_code: "IT",
      phone_code: "39",
    },
    {
      country: "Jamaica",
      chinese_name: "牙买加",
      country_code: "JM",
      phone_code: "1876",
    },
    {
      country: "Jordan",
      chinese_name: "约旦",
      country_code: "JO",
      phone_code: "962",
    },
    {
      country: "Japan",
      chinese_name: "日本",
      country_code: "JP",
      phone_code: "81",
    },
    {
      country: "Kenya",
      chinese_name: "肯尼亚",
      country_code: "KE",
      phone_code: "254",
    },
    {
      country: "Kyrgyzstan",
      chinese_name: "吉尔吉斯斯坦",
      country_code: "KG",
      phone_code: "996",
    },
    {
      country: "Cambodia",
      chinese_name: "柬埔寨",
      country_code: "KH",
      phone_code: "855",
    },
    {
      country: "Kiribati",
      chinese_name: "基里巴斯",
      country_code: "KI",
      phone_code: "686",
    },
    {
      country: "Comoros",
      chinese_name: "科摩罗",
      country_code: "KM",
      phone_code: "269",
    },
    {
      country: "Saint Kitts and Nevis",
      chinese_name: "圣基茨和尼维斯",
      country_code: "KN",
      phone_code: "1869",
    },
    {
      country: "Korea Democratic Rep.",
      chinese_name: "朝鲜",
      country_code: "KP",
      phone_code: "850",
    },
    {
      country: "South Korea",
      chinese_name: "韩国",
      country_code: "KR",
      phone_code: "82",
    },
    {
      country: "Kuwait",
      chinese_name: "科威特",
      country_code: "KW",
      phone_code: "965",
    },
    {
      country: "Cayman Islands",
      chinese_name: "开曼群岛",
      country_code: "KY",
      phone_code: "1345",
    },
    {
      country: "Kazakhstan",
      chinese_name: "哈萨克斯坦",
      country_code: "KZ",
      phone_code: "7",
    },
    {
      country: "Laos",
      chinese_name: "老挝",
      country_code: "LA",
      phone_code: "856",
    },
    {
      country: "Lebanon",
      chinese_name: "黎巴嫩",
      country_code: "LB",
      phone_code: "961",
    },
    {
      country: "Saint Lucia",
      chinese_name: "圣露西亚",
      country_code: "LC",
      phone_code: "1758",
    },
    {
      country: "Liechtenstein",
      chinese_name: "列支敦士登",
      country_code: "LI",
      phone_code: "423",
    },
    {
      country: "Sri Lanka",
      chinese_name: "斯里兰卡",
      country_code: "LK",
      phone_code: "94",
    },
    {
      country: "Liberia",
      chinese_name: "利比里亚",
      country_code: "LR",
      phone_code: "231",
    },
    {
      country: "Lesotho",
      chinese_name: "莱索托",
      country_code: "LS",
      phone_code: "266",
    },
    {
      country: "Lithuania",
      chinese_name: "立陶宛",
      country_code: "LT",
      phone_code: "370",
    },
    {
      country: "Luxembourg",
      chinese_name: "卢森堡",
      country_code: "LU",
      phone_code: "352",
    },
    {
      country: "Latvia",
      chinese_name: "拉脱维亚",
      country_code: "LV",
      phone_code: "371",
    },
    {
      country: "Libya",
      chinese_name: "利比亚",
      country_code: "LY",
      phone_code: "218",
    },
    {
      country: "Morocco",
      chinese_name: "摩洛哥",
      country_code: "MA",
      phone_code: "212",
    },
    {
      country: "Monaco",
      chinese_name: "摩纳哥",
      country_code: "MC",
      phone_code: "377",
    },
    {
      country: "Moldova",
      chinese_name: "摩尔多瓦",
      country_code: "MD",
      phone_code: "373",
    },
    {
      country: "Montenegro",
      chinese_name: "黑山",
      country_code: "ME",
      phone_code: "382",
    },
    {
      country: "Madagascar",
      chinese_name: "马达加斯加",
      country_code: "MG",
      phone_code: "261",
    },
    {
      country: "Marshall Islands",
      chinese_name: "马绍尔群岛",
      country_code: "MH",
      phone_code: "692",
    },
    {
      country: "Macedonia",
      chinese_name: "马其顿",
      country_code: "MK",
      phone_code: "389",
    },
    {
      country: "Mali",
      chinese_name: "马里",
      country_code: "ML",
      phone_code: "223",
    },
    {
      country: "Myanmar",
      chinese_name: "缅甸",
      country_code: "MM",
      phone_code: "95",
    },
    {
      country: "Mongolia",
      chinese_name: "蒙古",
      country_code: "MN",
      phone_code: "976",
    },
    {
      country: "Macau",
      chinese_name: "中国澳门",
      country_code: "MO",
      phone_code: "853",
    },
    {
      country: "Mauritania",
      chinese_name: "毛里塔尼亚",
      country_code: "MR",
      phone_code: "222",
    },
    {
      country: "Montserrat",
      chinese_name: "蒙特塞拉特岛",
      country_code: "MS",
      phone_code: "1664",
    },
    {
      country: "Malta",
      chinese_name: "马耳他",
      country_code: "MT",
      phone_code: "356",
    },
    {
      country: "Mauritius",
      chinese_name: "毛里求斯",
      country_code: "MU",
      phone_code: "230",
    },
    {
      country: "Maldives",
      chinese_name: "马尔代夫",
      country_code: "MV",
      phone_code: "960",
    },
    {
      country: "Malawi",
      chinese_name: "马拉维",
      country_code: "MW",
      phone_code: "265",
    },
    {
      country: "Mexico",
      chinese_name: "墨西哥",
      country_code: "MX",
      phone_code: "52",
    },
    {
      country: "Malaysia",
      chinese_name: "马来西亚",
      country_code: "MY",
      phone_code: "60",
    },
    {
      country: "Mozambique",
      chinese_name: "莫桑比克",
      country_code: "MZ",
      phone_code: "258",
    },
    {
      country: "Namibia",
      chinese_name: "纳米比亚",
      country_code: "NA",
      phone_code: "264",
    },
    {
      country: "New Caledonia",
      chinese_name: "新喀里多尼亚",
      country_code: "NC",
      phone_code: "687",
    },
    {
      country: "Niger",
      chinese_name: "尼日尔",
      country_code: "NE",
      phone_code: "227",
    },
    {
      country: "Nigeria",
      chinese_name: "尼日利亚",
      country_code: "NG",
      phone_code: "234",
    },
    {
      country: "Nicaragua",
      chinese_name: "尼加拉瓜",
      country_code: "NI",
      phone_code: "505",
    },
    {
      country: "Netherlands",
      chinese_name: "荷兰",
      country_code: "NL",
      phone_code: "31",
    },
    {
      country: "Norway",
      chinese_name: "挪威",
      country_code: "NO",
      phone_code: "47",
    },
    {
      country: "Nepal",
      chinese_name: "尼泊尔",
      country_code: "NP",
      phone_code: "977",
    },
    {
      country: "Nauru",
      chinese_name: "拿鲁岛",
      country_code: "NR",
      phone_code: "674",
    },
    {
      country: "New Zealand",
      chinese_name: "新西兰",
      country_code: "NZ",
      phone_code: "64",
    },
    {
      country: "Oman",
      chinese_name: "阿曼",
      country_code: "OM",
      phone_code: "968",
    },
    {
      country: "Panama",
      chinese_name: "巴拿马",
      country_code: "PA",
      phone_code: "507",
    },
    {
      country: "Peru",
      chinese_name: "秘鲁",
      country_code: "PE",
      phone_code: "51",
    },
    {
      country: "French Polynesia",
      chinese_name: "法属波利尼西亚",
      country_code: "PF",
      phone_code: "689",
    },
    {
      country: "Papua New Guinea",
      chinese_name: "巴布亚新几内亚",
      country_code: "PG",
      phone_code: "675",
    },
    {
      country: "Philippines",
      chinese_name: "菲律宾",
      country_code: "PH",
      phone_code: "63",
    },
    {
      country: "Pakistan",
      chinese_name: "巴基斯坦",
      country_code: "PK",
      phone_code: "92",
    },
    {
      country: "Poland",
      chinese_name: "波兰",
      country_code: "PL",
      phone_code: "48",
    },
    {
      country: "Saint Pierreand Miquelon",
      chinese_name: "圣彼埃尔和密克隆岛",
      country_code: "PM",
      phone_code: "508",
    },
    {
      country: "Puerto Rico",
      chinese_name: "波多黎各",
      country_code: "PR",
      phone_code: "1787",
    },
    {
      country: "Portugal",
      chinese_name: "葡萄牙",
      country_code: "PT",
      phone_code: "351",
    },
    {
      country: "Palau",
      chinese_name: "帕劳",
      country_code: "PW",
      phone_code: "680",
    },
    {
      country: "Paraguay",
      chinese_name: "巴拉圭",
      country_code: "PY",
      phone_code: "595",
    },
    {
      country: "Qatar",
      chinese_name: "卡塔尔",
      country_code: "QA",
      phone_code: "974",
    },
    {
      country: "Réunion Island",
      chinese_name: "留尼汪",
      country_code: "RE",
      phone_code: "262",
    },
    {
      country: "Romania",
      chinese_name: "罗马尼亚",
      country_code: "RO",
      phone_code: "40",
    },
    {
      country: "Serbia",
      chinese_name: "塞尔维亚",
      country_code: "RS",
      phone_code: "381",
    },
    {
      country: "Russia",
      chinese_name: "俄罗斯",
      country_code: "RU",
      phone_code: "7",
    },
    {
      country: "Rwanda",
      chinese_name: "卢旺达",
      country_code: "RW",
      phone_code: "250",
    },
    {
      country: "Saudi Arabia",
      chinese_name: "沙特阿拉伯",
      country_code: "SA",
      phone_code: "966",
    },
    {
      country: "Solomon Islands",
      chinese_name: "所罗门群岛",
      country_code: "SB",
      phone_code: "677",
    },
    {
      country: "Seychelles",
      chinese_name: "塞舌尔",
      country_code: "SC",
      phone_code: "248",
    },
    {
      country: "Sudan",
      chinese_name: "苏丹",
      country_code: "SD",
      phone_code: "249",
    },
    {
      country: "Sweden",
      chinese_name: "瑞典",
      country_code: "SE",
      phone_code: "46",
    },
    {
      country: "Singapore",
      chinese_name: "新加坡",
      country_code: "SG",
      phone_code: "65",
    },
    {
      country: "Slovenia",
      chinese_name: "斯洛文尼亚",
      country_code: "SI",
      phone_code: "386",
    },
    {
      country: "Slovakia",
      chinese_name: "斯洛伐克",
      country_code: "SK",
      phone_code: "421",
    },
    {
      country: "Sierra Leone",
      chinese_name: "塞拉利昂",
      country_code: "SL",
      phone_code: "232",
    },
    {
      country: "San Marino",
      chinese_name: "圣马力诺",
      country_code: "SM",
      phone_code: "378",
    },
    {
      country: "Senegal",
      chinese_name: "塞内加尔",
      country_code: "SN",
      phone_code: "221",
    },
    {
      country: "Somalia",
      chinese_name: "索马里",
      country_code: "SO",
      phone_code: "252",
    },
    {
      country: "Suriname",
      chinese_name: "苏里南",
      country_code: "SR",
      phone_code: "597",
    },
    {
      country: "Sao Tome and Principe",
      chinese_name: "圣多美和普林西比",
      country_code: "ST",
      phone_code: "239",
    },
    {
      country: "ElSalvador",
      chinese_name: "萨尔瓦多",
      country_code: "SV",
      phone_code: "503",
    },
    {
      country: "Syria",
      chinese_name: "叙利亚",
      country_code: "SY",
      phone_code: "963",
    },
    {
      country: "Swaziland",
      chinese_name: "斯威士兰",
      country_code: "SZ",
      phone_code: "268",
    },
    {
      country: "Turksand Caicos Islands",
      chinese_name: "特克斯和凯科斯群岛",
      country_code: "TC",
      phone_code: "1649",
    },
    {
      country: "Chad",
      chinese_name: "乍得",
      country_code: "TD",
      phone_code: "235",
    },
    {
      country: "Togo",
      chinese_name: "多哥",
      country_code: "TG",
      phone_code: "228",
    },
    {
      country: "Thailand",
      chinese_name: "泰国",
      country_code: "TH",
      phone_code: "66",
    },
    {
      country: "Tajikistan",
      chinese_name: "塔吉克斯坦",
      country_code: "TJ",
      phone_code: "992",
    },
    {
      country: "East Timor",
      chinese_name: "东帝汶",
      country_code: "TL",
      phone_code: "670",
    },
    {
      country: "Turkmenistan",
      chinese_name: "土库曼斯坦",
      country_code: "TM",
      phone_code: "993",
    },
    {
      country: "Tunisia",
      chinese_name: "突尼斯",
      country_code: "TN",
      phone_code: "216",
    },
    {
      country: "Tonga",
      chinese_name: "汤加",
      country_code: "TO",
      phone_code: "676",
    },
    {
      country: "Turkey",
      chinese_name: "土耳其",
      country_code: "TR",
      phone_code: "90",
    },
    {
      country: "Trinidadand Tobago",
      chinese_name: "特立尼达和多巴哥",
      country_code: "TT",
      phone_code: "1868",
    },
    {
      country: "Taiwan",
      chinese_name: "中国台湾",
      country_code: "TW",
      phone_code: "886",
    },
    {
      country: "Tanzania",
      chinese_name: "坦桑尼亚",
      country_code: "TZ",
      phone_code: "255",
    },
    {
      country: "Ukraine",
      chinese_name: "乌克兰",
      country_code: "UA",
      phone_code: "380",
    },
    {
      country: "Uganda",
      chinese_name: "乌干达",
      country_code: "UG",
      phone_code: "256",
    },

    {
      country: "Uruguay",
      chinese_name: "乌拉圭",
      country_code: "UY",
      phone_code: "598",
    },
    {
      country: "Uzbekistan",
      chinese_name: "乌兹别克斯坦",
      country_code: "UZ",
      phone_code: "998",
    },
    {
      country: "Saint Vincent and The Grenadines",
      chinese_name: "圣文森特和格林纳丁斯",
      country_code: "VC",
      phone_code: "1784",
    },
    {
      country: "Venezuela",
      chinese_name: "委内瑞拉",
      country_code: "VE",
      phone_code: "58",
    },
    {
      country: "VirginIslands,British",
      chinese_name: "英属处女群岛",
      country_code: "VG",
      phone_code: "1284",
    },
    {
      country: "Vietnam",
      chinese_name: "越南",
      country_code: "VN",
      phone_code: "84",
    },
    {
      country: "Vanuatu",
      chinese_name: "瓦努阿图",
      country_code: "VU",
      phone_code: "678",
    },
    {
      country: "Samoa",
      chinese_name: "萨摩亚",
      country_code: "WS",
      phone_code: "685",
    },
    {
      country: "Yemen",
      chinese_name: "也门",
      country_code: "YE",
      phone_code: "967",
    },
    {
      country: "Mayotte",
      chinese_name: "马约特",
      country_code: "YT",
      phone_code: "269",
    },
    {
      country: "South Africa",
      chinese_name: "南非",
      country_code: "ZA",
      phone_code: "27",
    },
    {
      country: "Zambia",
      chinese_name: "赞比亚",
      country_code: "ZM",
      phone_code: "260",
    },
    {
      country: "Zimbabwe",
      chinese_name: "津巴布韦",
      country_code: "ZW",
      phone_code: "263",
    },
  ],
};
