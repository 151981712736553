//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mixins: "@",
  name: "moreBtn",
  data() {
    return {};
  },
  props: {
    customStyles: {
      type: Object,
      default: () => {},
      // default: () => {
      //   return {
      //     height: "4.8/3.75rem",
      //   };
      // },
    },
    text: {
      type: String,
      require: true,
      default: "",
    },
    isbtn: {
      type: Boolean,
      default: true,
    },
    isWhiteBg: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isNoneArrow: {
      type: Boolean,
      default: false,
    },
    isIconDown: {
      type: Boolean,
      default: false,
    },
    isIconRight: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {},
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    handleClick() {
      if (!this.isLoading) {
        this.$emit("clickEmit");
      }
    },
  },
};
