//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    slideType: {
      type: String,
      default: () => "slideRight",
    },
  },
  name: "SlideSlotDialog",
  data() {
    return {
      isShowDialog: false,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(this.slideType);
  },
  watch: {
    // 监测变化
  },
};
