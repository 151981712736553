import { render, staticRenderFns } from "./PreviewGalleryImgDialog.vue?vue&type=template&id=a6ac8a96&scoped=true&"
import script from "./PreviewGalleryImgDialog.vue?vue&type=script&lang=js&"
export * from "./PreviewGalleryImgDialog.vue?vue&type=script&lang=js&"
import style0 from "./PreviewGalleryImgDialog.vue?vue&type=style&index=0&id=a6ac8a96&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6ac8a96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Star: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Star.vue').default})
