//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    noTopInfo: {
      type: Boolean,
      default: () => true,
    },
    contentBg: {
      type: String,
      default: () => "linear-gradient(140deg, #EDF3FF 0%, #C2CBD2 55%, #D9E0E8 100%)",
    },
    backgroundStyle: {
      type: String,
      default: () => "#fff",
    },
  },
  mounted() {},
  methods: {},
};
