//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    swiperInit: {
      type: Object,
      default: () => ({
        preventClicks: true,
        slidesPerView: 4,
        spaceBetween: 15,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      }),
    },
    swiperData: {
      type: [Array, Object],
      default: () => [] || {},
    },
    swiperTitle: {
      type: String,
      default: () => "",
    },
  },
  name: "SwiperProductList",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },

  components: {
    // 组件的引用
  },
  methods: {
    // 跳详情
    toProductDetail(path) {
      this.$router.push({ path: "/" + path });
    },

    // 谷歌产品浏览点击 参数处理
    googleProductParamHandler(item, index, title) {
      return {
        event_category: this.$route.name,
        event_label: this.$route.name + "_" + title,
        item_name: item.name,
        item_id: item.id,
        price: item.finalPrice,
        item_category: item.categories ? item?.categories[0]?.id : 0,
        item_list_name: this.$route.name + "_" + title,
        index: index,
      };
    },
    // // 产品浏览
    // googleSwiperProductView(item, index) {
    //   let param = this.googleProductParamHandler(item, index);
    //   this.$googleCategroyListView(param);
    // },
    // 产品点击
    googleSwiperProductClick(item, index, title) {
      let param = this.googleProductParamHandler(item, index, title);
      this.$googleCategroyListClick(param);
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {},
};
