import { render, staticRenderFns } from "./OptionsProductDialog.vue?vue&type=template&id=c025fde8&scoped=true&"
import script from "./OptionsProductDialog.vue?vue&type=script&lang=js&"
export * from "./OptionsProductDialog.vue?vue&type=script&lang=js&"
import style0 from "./OptionsProductDialog.vue?vue&type=style&index=0&id=c025fde8&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c025fde8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImgSwiper: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/ImgSwiper.vue').default,Input: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/Design/Input.vue').default})
