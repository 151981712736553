//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    swiperData: {
      type: [Array, Object],
      default: () => [] || {},
    },
    headTitle: {
      type: String,
      default: "",
    },
    swiperOption: {
      type: Object,
      default: () => ({
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        preventClicks: true,
        loop: false,
        autoplayDisableOnInteraction: false,
        slidesPerView: 1.2,
        slidesPerGroup: 2,
        spaceBetween: "4%",
        navigation: {
          nextEl: ".myswiper-button-next",
          prevEl: ".myswiper-button-prev",
        },
        on: {},
      }),
    },
  },
  name: "SwiperBlog",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapMutations("blog", ["setBlogCatId"]),
    // 方法
    linkHandle(item) {
      console.log(item);
      this.$router.push({
        path: `/blog/${item.identifier}`,
      });
      this.setBlogCatId(item.id);
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
