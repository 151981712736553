//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      userActivity: false,
    };
  },
  computed: {
    // 计算属性
    ...mapGetters("cart", ["popoupInfo"]),
  },
  mounted() {
    console.log(this.popoupInfo, "弹框信息");

    window.addEventListener("scroll", this.handleUserActivity);
    window.addEventListener("click", this.handleUserActivity);
    setTimeout(this.resetUserActivity, 1000 * 60 * 0.5);
  },
  methods: {
    waitUpComfirmDialog() {
      this.handleJump("/");
    },
    handleUserActivity() {
      this.userActivity = true;
    },
    /**
     * @Author: liudanli
     * @Date: 2023-07-15 10:49:43
     * @LastEditTime: Do not edit
     * @description: 计时器,只监听两分钟,
     * @return {*}
     */
    resetUserActivity() {
      console.log("跑吧");
      if (!this.userActivity) {
        this.checkUserActivity();
      } else {
        console.log("用户操作了");
      }
    },

    /**
     * @Author: liudanli
     * @Date: 2023-07-15 10:32:34
     * @LastEditTime: Do not edit
     * @description: 放弃支付弹窗 停留两分钟后的处理
     * @return {*}
     */
    checkUserActivity() {
      console.log("弹框了吗");
      if (this.popoupInfo && "checkoutretention" in this.popoupInfo && this.popoupInfo.checkoutretention.enablePosition.indexOf(this.$route.path) != -1) {
        this.$refs.waitUpDialog.changeDialog();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleUserActivity);
    window.removeEventListener("click", this.handleUserActivity);
  },
};
