//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["total", "pageSize", "pageNo", "pagerCount"],
  data() {
    return {
      currentPage: 1,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-05-26 11:32:17
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 计算出总共多少页  //向上取整数
     * @return {*}
     * @Description: Do not Description
     */
    totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-05-26 11:36:59
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 算出连续五个数字、开头、结尾的两个数字,分两种情况总页数是否大于连续页面数
     * @return {*}
     * @Description: Do not Description
     */
    startAndEnd() {
      let start = 0,
        end = 0;
      const { totalPage, pagerCount, pageNo } = this;
      if (totalPage < pagerCount) {
        start = 1;
        end = totalPage;
      } else {
        start = pageNo - parseInt(pagerCount / 2);
        end = pageNo + parseInt(pagerCount / 2);
        if (start < 1) {
          start = 1;
          end = pagerCount;
        }
        if (end > totalPage) {
          end = totalPage;
          start = totalPage - pagerCount + 1;
        }
      }
      return { start, end };
    },
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
