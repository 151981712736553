//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    listData: {
      type: Array,
      default: () => [],
    },
    reviewBtn: {
      type: Boolean,
      default: () => false,
    },
    reorderBtn: {
      type: Boolean,
      default: () => false,
    },
    wishlistBtn: {
      type: Boolean,
      default: () => false,
    },
  },
  name: "UserWishList",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    handleFun() {
      console.log("点击编辑");
      this.$emit("emitHandeFun");
    },
    reOrder($event, item) {
      this.$emit("reorderHandle", $event, item);
    },
    movewish(item, index) {
      this.$emit("moveWishHandle", item, index);
      //datalayer
      let datalayerParam = {
        eventType: "remove",
        event_label: "remove_item",
        event_category: "Wishlist",
        value: item.product.finalPrice,
        item_name: item.product.name,
        item_id: item.product.id,
        item_category: item?.product.categories ? item?.product.categories[0].id : 0,
        price: item.product.finalPrice,
        quantity: 1,
        index: index,
      };
      this.$googleActionWishlist(datalayerParam);
    },
    handleAddCart(item) {
      this.$emit("optionEite", item);
      //datalayer
      let datalayerParam = {
        eventType: "add",
        event_category: "Account",
        value: item.product.finalPrice,
        item_name: item.product.name,
        item_id: item.product.id,
        item_category: item?.product.categories ? item?.product.categories[0].id : 0,
        price: item.product.finalPrice,
        quantity: 1,
      };
      this.$googleProductActionCart(datalayerParam);
    },
    toReview(orderId, pid) {
      console.log(orderId);
      this.$router.push({
        path: `/user/order/review/${orderId}`,
        query: {
          pId: pid,
        },
      });
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-15 11:19:57
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 谷歌产品浏览点击 参数处理
     * @param {*} item
     * @param {*} index
     * @return {*}
     * @Description: Do not Description
     */
    googleProductParamHandler(item, index) {
      return {
        event_category: "Account",
        event_label: this.$route.path,
        item_name: item.product.name,
        item_id: item.product.id,
        price: item.product.finalPrice,
        item_category: item.product.categories ? item?.product.categories[0]?.id : 0,
        item_list_name: this.$route.path,
        index: index,
      };
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 17:46:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:  产品点击 datalayer
     * @return {*}
     * @Description: Do not Description
     */
    googleSwiperProductClick(item, index) {
      let param = this.googleProductParamHandler(item, index);
      this.$googleCategroyListClick(param);
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 17:46:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:  产品浏览 datalayer
     * @return {*}
     * @Description: Do not Description
     */
    googleSwiperProductView(item, index) {
      let param = this.googleProductParamHandler(item, index);
      this.$googleCategroyListView(param);
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
