//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { loadScript as PaypalLoadScript } from "@paypal/paypal-js";
import { loadStripe as StripeLoadScript } from "@stripe/stripe-js";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { mapActions } from "vuex";
export default {
  // head: {
  //   script: [
  //   { src: "https://checkoutshopper-test.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.1.9.7.min.js", async: true, type: "text/javascript", charset: "utf-8" },
  //   ],
  // },
  props: {
    payType: {
      // 支付类型：钱包充值--wallet、下单支付（默认）--order ，
      type: String,
      default: "order",
    },
    payMethod: {
      // 支付方式
      type: String,
      default: "",
    },
    payOrder: {
      // 创建订单使用
      type: Function,
      default: null,
    },
    // addAddressDialog: {
    //   // 购物车中没有地址的情况，唤起地址弹框
    //   type: Function,
    //   default: null,
    // },
    orderNum: {
      // 已有订单号，并传入，重新支付使用
      type: [String, Number],
      default: "",
    },
    quoteId: {
      // 创建订单使用
      type: Number,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    disableBtn: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: "",
    },
    afterPayInfo: {
      type: Object,
      default: () => ({}),
    },
    paypalBtnStyle: {
      type: Object,
      default: () => {
        return {
          layout: "vertical",
          color: "gold",
          shape: "rect",
          label: "paypal",
        };
      },
    },
  },
  data() {
    return {
      stripe: null,
      isInintPayapl: false, //是否初始化了paypal
      isInintStripe: false, //是否初始化stripe
      caretedOrderId: null,
      paypalAction: null,
      replayPayId: null,
      payOrderNum: null, //没有订单号，生成的订单号
      showAppleBtn: false,
      showIframe: false,
      adyenMethodsList: {},
      adyenAction: null,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapActions("cart", ["getCartList"]),
    ...mapActions("user", ["getUserInfoAll"]),

    //初始化 adyen
    // async initAdyen() {
    //   if (JSON.stringify(this.adyenMethodsList) == "{}") {
    //     this.adyenMethodsList = await this.getAdyenPayMethods();
    //   }
    //   const configuration = {
    //     paymentMethodsResponse: this.adyenMethodsList,
    //     locale: "en_US",
    //     environment: "test",
    //     clientKey: "test_RAXDVIV3BNGJRBOJ63PN6L7U6AKDKHH3",
    //     // session:{},
    //     paymentMethodsConfiguration: {
    //       card: {
    //         hasHolderName: false,
    //         showPayButton: this.disableBtn, //验证是否通过
    //         // amount: { //按钮上的价格先注释掉
    //         //   value: 1000,
    //         //   currency: this.currency,
    //         // },
    //         //       redirect: {
    //         //   showConfirmationPage: false,
    //         // },
    //       },
    //     },
    //     onError: (error, component) => {
    //       console.error(error.name, error.message, error.stack, component, "adyen的什么错误");
    //     },
    //     onSubmit: (state, component) => {
    //       //用户点击提交支付按钮
    //       console.log(state, component, "state");
    //       this.adyenAction = component;
    //       if (state.isValid) {
    //         //验证通过
    //         this.goodsCreatOrder(state);
    //       }
    //     },
    //     onAdditionalDetails: (state, component) => {
    //       console.log(state, component, "onAdditionalDetails");
    //       //创建事件处理程序，当付款方式需要更多详细信息时（例如，本地3D Secure 2或本地二维码付款方式）此程序将被触发。
    //     },
    //   };
    //   const checkout = await new AdyenCheckout(configuration);
    //   checkout.create("card").mount("#adyen-payment-div");
    // },
    async initAdyen() {
      console.log(process.env.ADYEN_LIVE, "adyen的keyddddddddddddddddd");
      if (JSON.stringify(this.adyenMethodsList) == "{}") {
        this.adyenMethodsList = await this.getAdyenPayMethods();
      }
      const checkout = await this.createAdyenCheckout();
      checkout.create("card").mount("#adyen-payment-div");
    },
    async createAdyenCheckout() {
      const configuration = {
        paymentMethodsResponse: this.adyenMethodsList,
        locale: "en_US",
        environment: process.env.ADYEN_LIVE,
        clientKey: process.env.ADYEN_CLIENT_KEY,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: false,
            showPayButton: this.disableBtn, //验证是否通过
            // amount: { //按钮上的价格先注释掉
            //   value: 1000,
            //   currency: this.currency,
            // },
            //       redirect: {
            //   showConfirmationPage: false,
            // },
          },
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component, "adyen的什么错误");
        },
        onAdditionalDetails: (state, component) => {
          console.log(state, component, "onAdditionalDetails");
          //创建事件处理程序，当付款方式需要更多详细信息时（例如，本地3D Secure 2或本地二维码付款方式）此程序将被触发。
        },
        onSubmit: (state, component) => {
          //用户点击提交支付按钮
          console.log(state, component, "state");
          if (state.isValid) {
            //验证通过
            this.goodsCreatOrder(state);
          }
        },
      };
      return new AdyenCheckout(configuration);
    },
    //初始化stripe
    async initStripe() {
      // if (this.isInintStripe) {
      //   return;
      // }
      try {
        // process.env.StripeKey
        let params;
        if (this.quoteId) {
          params = { quote_id: this.quoteId };
        } else if (this.orderNum) {
          params = { order_id: this.orderNum };
        }
        let stripeClientSecretRes = await this.$getStripeClientSecret(params);
        console.log(stripeClientSecretRes, "客户端id");
        this.stripeClientSecret = stripeClientSecretRes.data.clientSecret;
        this.stripe = await StripeLoadScript(process.env.StripeKey);
        this.isInintStripe = true;
        const options = {
          clientSecret: this.stripeClientSecret,
        };
        const elements = this.stripe.elements(options);
        // this.paymentElement = elements.create("card", {
        //   classes: { base: "StripeElement" },
        //   hidePostalCode: true,
        //   style: {
        //     base: {
        //       lineHeight: "30px",
        //     },
        //   },
        // });
        // var cardNumberElement = elements.create('cardNumber');
        // this.paymentElement.mount("#stripe_payments");

        this.cardNumber = this.stripe.card = elements.create("cardNumber", {
          classes: { base: "StripeElement" },
          hidePostalCode: true,
          showIcon: true,
          style: {
            base: {
              lineHeight: "30px",
            },
          },
        });
        this.cardNumber.mount("#stripe-payments-card-number");

        this.cardExpiry = elements.create("cardExpiry", {
          classes: { base: "StripeElement" },
          hidePostalCode: true,
          showIcon: true,
          style: {
            base: {
              lineHeight: "30px",
            },
          },
        });
        this.cardExpiry.mount("#stripe-payments-card-expiry");

        this.cardCvc = elements.create("cardCvc", {
          classes: { base: "StripeElement" },
          hidePostalCode: true,
          showIcon: true,
          style: {
            base: {
              lineHeight: "30px",
            },
          },
        });
        this.cardCvc.mount("#stripe-payments-card-cvc");
      } catch (error) {
        console.error("failed to load the Stripe JS SDK script", error);
      }
    },
    /**
     * @Author: liudanli
     * @Date: 2023-06-21 14:11:00
     * @LastEditTime: Do not edit
     * @description: 用于获取Stripe paymentMethod id
     * @return {*}
     */
    async getStripeId() {
      const params = { type: "card", card: this.cardNumber };
      let stripeInfo = await this.stripe.createPaymentMethod(params);
      console.log(stripeInfo, "stripeInfo");
      //这儿要根据给出更合理的提示（后面优化处理）
      if (stripeInfo?.paymentMethod?.id) {
        return stripeInfo.paymentMethod.id;
      } else {
        this.$toast({ type: "fail", content: stripeInfo.error.message });
      }
    },
    // 初始化paypal
    async initPaypal() {
      console.log(process.env.PAYPAL_CLIENT_ID, "paypay的key");
      if (this.isInintPayapl) {
        return;
      }
      let paypal;
      try {
        paypal = await PaypalLoadScript({ "client-id": process.env.PAYPAL_CLIENT_ID, currency: this.currency });
        this.isInintPayapl = true;
      } catch (error) {
        console.error("failed to load the PayPal JS SDK script", error);
      }
      paypal
        .Buttons({
          style: this.paypalBtnStyle,
          createOrder: this.getOrderId,
          onApprove: this.payCallback,
          onInit: (data, actions) => {
            // PayPal 按钮初始化完成后执行的逻辑
            // 可以执行一些初始化操作、设置按钮样式或调用其他自定义函数
            console.log("PayPal 按钮已初始化");
            this.paypalAction = actions;
            console.log(this.paypalAction);
            if (this.disableBtn) {
              actions.enable();
            } else {
              actions.disable();
              // if(this.addAddressDialog){
              //   this.addAddressDialog();
              // }
            }
          },
          onClick: this.paypalClick,
          onCancel: this.paypalCancel,
          onError: this.paypalError,
          onShippingChange: function (data, actions) {
            console.log(data, "PayPal里切换地址的时候");
            console.log(actions, "PayPal里切换地址的时候");
          },
        })
        .render("#paypal-button-container");
    },
    //paypal按钮点击
    paypalClick(actions) {
      console.log(this.disableBtn, "什么状态");
      if (!this.disableBtn) {
        //滚动到顶部
        this.paypalAction.disable();
        return;
      }
      if (this.payType == "order") {
        //推送 支付方式 datalaye
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          CartInfo: {
            method: actions.fundingSource,
          },
          event: "place_order",
        });
      }
    },
    // paypal 取消支付
    paypalCancel(data) {
      console.log("paypal 取消支付", data);
      if (this.payType == "order") {
        if (this.isDialog) {
          this.$emit("changeDialog", false);
        } else {
          this.$router.replace({
            path: "/user/order/unpaid",
            query: { orderId: this.caretedOrderId },
          });
        }
      } else if (this.payType == "wallet") {
        console.log("paypal wallet 取消支付");
      } else {
        console.log(this.payType);
      }
      this.$emit("PaypalCreditCard", false);
    },
    // paypal 支付报错
    paypalError(data) {
      console.log("paypal onError支付", data);
      if (this.payType == "order") {
        if (this.isDialog) {
          this.$emit("changeDialog", false);
        } else {
          this.$router.replace({
            path: "/cart",
          });
        }
      }
      this.$emit("PaypalCreditCard", false);
    },
    // 获取订单号
    getOrderId(data) {
      if (data.paymentSource == "card") {
        //data 是PayPal 信用卡支付时处理页面固定使用
        this.$emit("PaypalCreditCard", true);
      }
      if (this.payType == "order") {
        if (this.payMethod == "paypal_express") {
          return this.goodsCreatOrder();
        } else {
          this.goodsCreatOrder();
        }
      } else if (this.payType == "wallet") {
        return this.walletCreatOrder();
      }
      return;
    },

    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-09 11:35:40
     * @LastAuthor: liudanli 565478958@qq.com
     * @description:支付之前,根据用户的信息，创建一个订单
     * @return {*}
     * @Description: Do not Description
     */
    async goodsCreatOrder(adyenVal) {
      console.log(this.orderNum, "this.orderNum");
      if (!this.orderNum) {
        this.googleCustomeEventHanlder(`7_pay_${this.payMethod}`, "productCheckoutProgress", "place_order", "fireCheckout");
        let paramsObj = {};
        if (this.payMethod == "stripe_payments") {
          paramsObj = {
            stripe_payment_methods_id: await this.getStripeId(),
          };
          if (!paramsObj.stripe_payment_methods_id) {
            return false;
          }
        }
        if (this.payMethod == "adyen") {
          paramsObj.adyen_payment_method = adyenVal.data.paymentMethod;
          paramsObj.adyen_browser_info = adyenVal.data.browserInfo;
        }
        console.log(paramsObj, "paramsObj");
        try {
          let payOrderRes = await this.payOrder(paramsObj);
          this.caretedOrderId = payOrderRes.orderId;
          this.getCartList();
          if (payOrderRes.token?.expiresIn) {
            console.log("游客转化", payOrderRes);
            this.$cookies.set("userInfo", payOrderRes.token, {
              maxAge: 60 * 60 * 24 * 120,
              path: "/",
            });
          }

          if (this.payMethod == "paypal_express") {
            this.payOrderNum = payOrderRes.pay.id;
            return payOrderRes.pay.id;
          } else if (this.payMethod == "stripe_payments") {
            if (payOrderRes?.pay?.threeDSecure) {
              this.threeDSecure(payOrderRes);
              window.addEventListener("message", this.adyen3DSComplete, false);
            } else {
              // 不需要3d 验证的卡号，不用调用支付回调接口
              if (payOrderRes.hasOrderPaid) {
                try {
                  //此打印勿删，用于kibana,定位漏单使用
                  throw new Error("订单数据统计 触发GTM的订单ID(getOrderId 不需要3d 验证的)" + JSON.stringify(this.caretedOrderId));
                } catch (error) {
                  console.error(error);
                }
                this.handleGtm(this.caretedOrderId);
                this.$router.replace({
                  path: "/order/payment",
                  query: { orderId: this.caretedOrderId },
                });
              } else {
                this.$router.replace({
                  path: "/user/order/unpaid",
                  query: { orderId: this.caretedOrderId },
                });
              }
            }
          } else if (this.payMethod == "adyen") {
            if (payOrderRes?.pay?.threeDSecure) {
              //需要调起3D验证
              sessionStorage.setItem("adyenParam", JSON.stringify(payOrderRes.pay.nextAction.data));
              this.threeDSecure(payOrderRes);
              // window.addEventListener("message", this.adyen3DSComplete, false);
            } else {
              // 不需要3d 验证的卡号，不用调用支付回调接口
              if (payOrderRes.hasOrderPaid) {
                //支付成功
                this.handleGtm(this.caretedOrderId);
                this.$router.replace({
                  path: "/order/payment",
                  query: { orderId: this.caretedOrderId },
                });
              } else {
                this.$router.replace({
                  path: "/user/order/unpaid",
                  query: { orderId: this.caretedOrderId },
                });
              }
            }
          } else if (this.payMethod == "free") {
            try {
              // throw new Error("订单数据统计 触发GTM的订单ID(free)" + JSON.stringify(payOrderRes.orderId));
              this.handleGtm(payOrderRes.orderId);
              this.$router.replace({
                path: "/order/payment",
                query: { orderId: payOrderRes.orderId },
              });
            } catch (error) {
              console.error("订单数据统计 触发GTM的订单ID(free)" + error);
            }
          }
        } catch (error) {
          //支付报错跳带支付页
          console.log(error + "获取订单号方法报错跳转购物车");
          this.$router.replace({
            path: "/cart",
          });
        }
      } else {
        return this.payReplay(adyenVal);
      }
    },

    /**
     * @Date: 2023-06-16 13:45:23
     * @Description: 重新支付
     * @return {*}
     */
    async payReplay(adyenVal) {
      if (!this.payMethod) {
        return;
      }
      this.isInintStripe = false; //先注释，因为在支付成功页面会重新初始化stripe.
      let tempID;
      let paramsObj = {};
      if (this.payMethod == "stripe_payments") {
        paramsObj = {
          stripe_payment_methods_id: await this.getStripeId(),
        };
        if (!paramsObj.stripe_payment_methods_id) {
          return false;
        }
      }
      if (this.payMethod == "adyen") {
        paramsObj.adyen_payment_method = adyenVal.data.paymentMethod;
        paramsObj.adyen_browser_info = adyenVal.data.browserInfo;
      }
      let { code, data } = await this.$payReplay({
        forter_token: this.$cookies.get("forterToken"),
        id: this.orderNum,
        pay_method: this.payMethod,
        ...paramsObj,
      });
      console.log("重新支付接口返回值", data);
      if (code === 200000) {
        if (this.payMethod == "paypal_express") {
          tempID = data.pay.id;
        } else if (this.payMethod == "klarna_payments") {
          // this.KlarnaPayInfo = data.pay;
          // this.initKlarna();
        } else if (this.payMethod == "stripe_payments") {
          // this.initStripe();
          if (data?.pay?.threeDSecure) {
            this.threeDSecure(data);
            console.log("重新支付stripe 3D验证");
            window.addEventListener("message", this.stripe3DSComplete, false);
          } else {
            // 不需要3d 验证的卡号，不用调用支付回调接口
            if (data.hasOrderPaid) {
              try {
                throw new Error("订单数据统计 触发GTM的订单ID(payReplay 不需要3d 验证的卡号)" + JSON.stringify(this.orderNum));
              } catch (error) {
                console.error(error);
              }
              this.handleGtm(this.orderNum);
              this.$emit("changeDialog", true);
              this.$router.replace({
                path: "/order/payment",
                query: { orderId: this.orderNum },
              });
            } else {
              //失败
              if (this.isDialog) {
                this.$emit("changeDialog", false);
              }
            }
          }
        } else if (this.payMethod == "mr_quadpay" || this.payMethod == "afterpaypayovertime") {
          sessionStorage.setItem("transactionId", data.pay.transactionId);
          // window.open(data.pay.redirectUrl, "_self");
          window.location.replace(data.pay.redirectUrl);
        } else if (this.payMethod == "applepay") {
          this.applePayMethodsId = data.pay.transactionId;
          this.applePayRequest(data.pay);
        } else if (this.payMethod == "adyen") {
          if (data?.pay?.threeDSecure) {
            //需要调起3D验证
            sessionStorage.setItem("adyenParam", JSON.stringify(data.pay.nextAction.data));
            this.threeDSecure(data);
            // window.addEventListener("message", this.adyen3DSComplete, false);
          } else {
            // 不需要3d 验证的卡号，不用调用支付回调接口
            if (data.hasOrderPaid) {
              //支付成功
              this.handleGtm(this.orderNum);
              this.$router.replace({
                path: "/order/payment",
                query: { orderId: this.orderNum },
              });
            } else {
              this.$router.replace({
                path: "/user/order/unpaid",
                query: { orderId: this.orderNum },
              });
            }
          }
        }
      }
      this.replayPayId = tempID;
      return tempID;
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-09 11:38:18
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 用户支付成功后的回调
     * @return {*}
     * @Description: Do not Description
     */
    async payCallback(val) {
      console.log("触发支付回调成功>>", val, this.payType);
      if (this.payType == "order") {
        this.orderPayReturn(val);
      } else if (this.payType == "wallet") {
        this.$emit("payReturn", { transaction_id: this.payOrderNum, pay_method: "paypal_express" });
      }
      this.payOrderNum = null;
    },
    /**
     * @description: 支付成功的回调
     * @param {*} val
     * @return {*}
     */
    async orderPayReturn(val) {
      try {
        let params;
        if (this.payMethod == "paypal_express") {
          params = { transaction_id: this.orderNum ? this.replayPayId : this.payOrderNum, pay_method: "paypal_express" };
        } else if (this.payMethod == "klarna_payments") {
          params = {
            transaction_id: this.KlarnaPayInfo.sessionId,
            pay_method: "klarna_payments",
            authorization_token: val,
          };
        } else if (this.payMethod == "stripe_payments") {
          params = {
            transaction_id: val,
            pay_method: "stripe_payments",
          };
        } else if (this.payMethod == "applepay") {
          params = {
            transaction_id: this.applePayMethodsId,
            apple_payment_methods_id: this.applePayInfo.payment_method_id,
            pay_method: "applepay",
          };
        }
        console.log("支付回调入参", params);
        let payCallBackRes = await this.$payCallBack({ forter_token: this.$cookies.get("forterToken"), ...params });
        console.log("支付回调数据 return 接口", payCallBackRes);
        console.log("orderId", sessionStorage.getItem("orderId"));
        let orderId = sessionStorage.getItem("orderId") || payCallBackRes.data.shippingAddress.orderId;
        sessionStorage.removeItem("payment_intent");
        if (payCallBackRes.code == 200000) {
          if (payCallBackRes.data.error) {
            this.$toast({ type: "success", content: payCallBackRes.data.error });
            return;
          } else if (payCallBackRes.data.hasOrderPaid) {
            console.log("支付回调 成功");
            this.$router.replace({
              path: "/order/payment",
              query: { orderId: payCallBackRes.data.orderId },
            });
            //此打印勿删，用于kibana,定位漏单使用
            try {
              throw new Error("订单数据统计 触发GTM的订单ID(payCallback函数) try" + JSON.stringify(payCallBackRes.data.orderId));
            } catch (error) {
              console.error(error);
            }
            this.handleGtm(payCallBackRes.data.orderId);
            if (this.isDialog) {
              this.$emit("changeDialog", true);
            }
            if (this.isLogin) {
              // 已登录用户，重新获取用户信息，用于判断用户等级
              this.getUserInfoAll();
            } else {
              // 未登录用户，记录token 用于用户登录
              if (payCallBackRes.data.token) {
                this.$cookies.set("userInfo", payCallBackRes.data.token, {
                  maxAge: 60 * 60 * 24 * 120,
                  path: "/",
                });
              }
            }
          } else if (!payCallBackRes.data.hasOrderPaid) {
            console.log("支付回调 失败", this.caretedOrderId);
            if (this.isDialog) {
              this.$emit("changeDialog", false);
            } else {
              this.$router.replace({
                path: "/user/order/unpaid",
                query: { orderId: orderId || this.caretedOrderId },
              });
            }
          }
          if (params.pay_method == "stripe_payments") {
            window.removeEventListener("message", this.stripe3DSComplete);
          }
          sessionStorage.removeItem("orderId");
        } else if (payCallBackRes.code == 200901) {
          console.log("支付回调 失败 2000901", this.caretedOrderId);
          if (this.isDialog) {
            this.$emit("changeDialog", false);
          } else {
            this.$router.replace({
              path: "/user/order/unpaid",
              query: { orderId: orderId || this.caretedOrderId },
            });
          }
        }
      } catch (error) {
        //支付报错跳带支付页
        console.log("支付回调报错跳带支付页:" + error);
        this.$router.replace({
          path: "/user/order/unpaid",
          query: { orderId: this.caretedOrderId },
        });
      }
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-09 15:20:01
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 获取订单信息 添加GTM
     * @param {*} orderId
     * @return {*}
     * @Description: Do not Description
     */
    async handleGtm(orderId) {
      if (orderId) {
        try {
          let { code, data } = await this.$orderDetail(orderId);
          if (code == 200000) {
            let gtmParams = {
              increment_id: data.incrementId,
              grand_total: data.grandTotal,
              base_grandtotal: data.baseGrandTotal,
              tax: data.taxAmountFormat,
              shipping: data.shippingAmountFormat,
              subtotal: data.subtotalFormat,
              currency: data.orderCurrencyCode,
              email: data.shippingAddress.email,
              customer_id: data.shippingAddress.customerId,
              created_at: Date.parse(data.createdAt),
              created_at_form: data.createdAt,
              coupon: data.couponCode,
              region: data.shippingAddress.region,
              region_id: data.shippingAddress.regionId,
              country_id: data.shippingAddress.countryId,
              city: data.shippingAddress.city,
              postcode: data.shippingAddress.postcode,
              street: data.shippingAddress.street,
              firstname: data.shippingAddress.firstname,
              lastname: data.shippingAddress.lastname,
              telephone: data.shippingAddress.telephone,
              comment: data.shippingAddress.comment,
              item: data.items.map((mItem) => ({
                sku: mItem.sku,
                qty: mItem.qtyOrdered,
                price: mItem.price,
                base_price: mItem.basePrice,
                product_name: mItem.name,
                product_id: mItem.productId,
                options:
                  mItem.productOptions.options.length > 0
                    ? mItem.productOptions.options.map((mItem) => ({ label: mItem.label, value: mItem.value, print_value: mItem.printVsalue }))
                    : null,
              })),
            };
            console.log(gtmParams);
            //此打印勿删，用于kibana, 定位漏单使用
            //  this.addGtmData("orderPayment", gtmParams);
            this.$arrivePaySuccess({ id: orderId });
          }
        } catch (error) {
          //此打印勿删，用于kibana,定位漏单使用
          throw new Error("Pay组件 订单数据统计 触发GTM:" + error);
        }
      }
    },

    // 发起stripe 3d 验证
    threeDSecure(data) {
      if (this.payMethod == "adyen") {
        // this.adyenAction.handleAction(data.pay.nextAction);
        // this.adyenAction.handleAction(data.pay.nextAction).then((res) => {
        //   console.log("3d 验证", res);
        //   return;
        // });

        // 创建一个 form 元素
        console.log(data, "data3d");
        const form = document.createElement("form");
        form.method = "POST"; // 设置表单的提交方法，可以是 GET 或 POST
        form.action = data.pay.nextAction.url; // 设置表单提交的目标 URL
        // 创建一个 input 元素，并设置其属性
        const inputMD = document.createElement("input");
        inputMD.type = "text";
        inputMD.name = "MD";
        inputMD.value = data.pay.nextAction.data.md;

        const inputPaReq = document.createElement("input");
        inputPaReq.type = "text";
        inputPaReq.name = "PaReq";
        inputPaReq.value = data.pay.nextAction.data.paReq;

        const inputTermUrl = document.createElement("input");
        inputTermUrl.type = "text";
        inputTermUrl.name = "TermUrl";
        inputTermUrl.value = data.pay.nextAction.data.termUrl;
        // 将 input 元素添加到 form 中
        form.appendChild(inputMD);
        form.appendChild(inputPaReq);
        form.appendChild(inputTermUrl);
        // 创建一个提交按钮
        // const submitButton = document.createElement("input");
        // submitButton.type = "submit";
        // submitButton.value = "Submit";
        document.body.appendChild(form);
        // 将提交按钮添加到 form 中
        form.submit();
        // // 将 form 元素插入到 body 中
        return false;
      }
      if (this.payMethod == "stripe_payments") {
        this.showIframe = true;
        let iframe = document.createElement("iframe");
        iframe.src = data.pay.nextAction?.redirectToUrl?.url;
        iframe.width = "100%";
        iframe.height = "100%";
        iframe.style = "";
        /* eslint-disable */
        let stripeContainer = document.getElementById("stripe-iframe");
        stripeContainer.appendChild(iframe);
        sessionStorage.setItem("orderId", data.orderId);
        return false;
      }
    },
    // stripe 3D 验证
    stripe3DSComplete(ev) {
      console.log(ev, "ev是啥");
      if (ev.data === "3DS-authentication-complete") {
        // if (this.isDialog) {
        //   this.showIframe = false;
        // } else {
        //   document.getElementById("stripe-iframe").remove();
        // }
        this.stripe
          .retrievePaymentIntent(this.stripeClientSecret)
          .then((result) => {
            console.log("stripe 3D验证返回结果", result);
            if (result.error) {
              console.log("stripe 3d 失败跳转");
              this.$router.replace({
                path: "/user/order/unpaid",
                query: { orderId: this.caretedOrderId },
              });
            } else {
              console.log("stripe 支付成功", result);
              let tempId = sessionStorage.getItem("payment_intent") || result.paymentIntent.id;
              this.payCallback(tempId);
            }
          })
          .catch((err) => {
            console.log(" stripe 3d 错误跳转", err);
            if (this.isDialog) {
              this.showIframe = false;
            } else {
              this.$router.replace({
                path: "/user/order/unpaid",
                query: { orderId: this.caretedOrderId },
              });
            }
          });
      } else {
        //3d 验证失败
      }
    },
    /**
     * @Author: liudanli
     * @Date: 2023-06-29 14:32:53
     * @LastEditTime: Do not edit
     * @description:获取adyen通道中所有符合条件的支付方式
     * @return {*}
     */
    async getAdyenPayMethods() {
      try {
        let params = {};
        if (this.orderNum) {
          params.order_id = this.orderNum;
        } else {
          params.quote_id = this.quoteId;
        }
        console.log(params, "orderNum params");
        let AdyenMethodsListRes = await this.$getAdyenPayMethods(params);
        if (AdyenMethodsListRes.code == 200000) {
          console.log(this.adyenMethodsList, "adyenMethodsListadyenMethodsList");
          return AdyenMethodsListRes.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @Author: liudanli
     * @Date: 2023-07-11 09:54:06
     * @LastEditTime: Do not edit
     * @description: 引入adyen
     * @return {*}
     */
    async loadAdyenWeb() {
      const AdyenCheckout = await import("@adyen/adyen-web");
      await import("@adyen/adyen-web/dist/adyen.css");
      return AdyenCheckout;
      // import AdyenCheckout from "@adyen/adyen-web";
      // import "@adyen/adyen-web/dist/adyen.css";
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.initPaypal();
    this.initAdyen();
    // this.$nextTick(()=>{
    //   this.loadAdyenWeb();
    // })
  },
  watch: {
    // 监测变化
    payMethod(val) {
      console.log(val);
      if (val == "paypal_express") {
        this.initPaypal();
        this.showAppleBtn = false;
      } else if (val == "stripe_payments") {
        this.initStripe();
        this.showAppleBtn = false;
      } else if (val == "adyen") {
        this.initAdyen();
      }
      // } else if (val == "applepay") {
      //   this.initApplePay();
      //   if (this.isDialog) {
      //     this.payReplay();
      //   }
      // } else if (val == "klarna_payments") {
      //   this.initKlarna();
      //   this.showAppleBtn = false;
      // }
    },
    disableBtn(val) {
      // 当地址的验证都通过的时候 重新初始化initpaypal
      if (val) {
        if (this.payMethod == "paypal_express" && this.paypalAction) {
          this.paypalAction.enable();
        }
        if (this.payMethod == "adyen") {
          this.initAdyen();
        }
      } else {
        console.log("重新走了吗");
        if (this.payMethod == "paypal_express" && this.paypalAction) {
          this.paypalAction.disable();
        }
        if (this.payMethod == "adyen") {
          this.initAdyen();
        }
      }

      // if (val && this.payMethod == "paypal_express") {
      //   if (this.paypalAction) {
      //     this.paypalAction.enable();
      //   }
      // } else {
      //   if (this.paypalAction) {
      //     this.paypalAction.disable();
      //   }
      // }
    },
  },
};
