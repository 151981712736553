//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    contentText: {
      type: String,
      default: () => "",
    },
    confirmText: {
      type: String,
      default: () => "Confirm",
    },
    cancelText: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      isShowDialog: false,
    };
  },
  methods: {
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
    },
    comfirmDialog() {
      this.$emit("comfirmDialog");
    },
  },
};
