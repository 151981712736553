//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    cartList: {
      type: Array,
      default: () => [],
    },
    pageType: {
      type: Boolean,
      default: true,
    },
    changQty: {
      type: Boolean,
      default: true,
    },
    isCheckoutPage: {
      type: Boolean,
      default: false,
    },
  },
  name: "CartItemList",
  data() {
    return {
      commonDialogContentText: "",
      commonDialogCconfirmText: "",
      commonDialogCancelText: "",
      commonDialogType: "",
      changeCartIndex: null,
      hasPendingOrder: null,
      quoteInfo: {},
      productOptions: {},
      selectedOptionIdObj: [], //选中的属性
      // showOptionName: "", //update 要更新的属性名
      qtyNum: 1,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters("user", ["isLogin"]),
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-08 09:19:30
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:
     * @param {*} ItemEndTime
     * @param {*} id
     * @return {*}
     * @Description: Do not Description
     */
    // async countdown(ItemEndTime, id) {
    //   let getSeverTime = await this.$serverTime();
    //   let severDate = this.$dayjs(getSeverTime.data.now).valueOf();
    //   let endTime = this.$dayjs(ItemEndTime).valueOf();
    //   let str;
    //   if (endTime - severDate > 0) {
    //     this.tool.countDown(
    //       endTime - severDate,
    //       1000,
    //       (obj, timer) => {
    //         this.timerArr.push(timer);
    //         str = `${obj.d}D ${obj.h}H ${obj.m} M ${obj.s}S`;
    //         // str = `${obj.h} : ${obj.m} : ${obj.s} : ${obj.ms / 10 ? obj.ms / 10 : "00"} `;
    //         let cartProduct = this.cartList.find((item) => item.id == id);
    //         if (cartProduct) {
    //           this.$set(cartProduct, "countdown", str);
    //         }
    //         if (obj.d == "00" && obj.h == "00" && obj.m == "00" && obj.s == "00") {
    //           // this.$pagetCartProductList();
    //           this.$emit("changeHandle");
    //         }
    //       },
    //       true
    //     );
    //   } else {
    //     str = "00D 00H 00M 00S";
    //   }
    // },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 13:11:20
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 拉起确认删除弹窗
     * @param {*} item
     * @param {*} index
     * @return {*}
     * @Description: Do not Description
     */
    changeDeleteDialog(item, index) {
      try {
        this.changeCartIndex = index;
        this.selectedDeletItem = item;
        this.$refs.commonDialog.changeDialog();
        this.commonDialogType = "deleteSingle";
        this.commonDialogContentText = "Are You Sure Want To Remove This Item From Your Cart?";
        this.commonDialogCconfirmText = "Yes";
        this.commonDialogCancelText = "No";
      } catch (error) {
        throw new Error("拉起确认删除弹窗" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 13:12:35
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 确认弹窗--确认
     * @return {*}
     * @Description: Do not Description
     */
    comfirmDialog() {
      try {
        if (this.commonDialogType == "deleteSingle") {
          this.deleteCart(this.selectedDeletItem);
        }
      } catch (error) {
        throw new Error("确认弹窗--确认" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 13:14:11
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 删除商品
     * @param {*} item
     * @param {*} flag
     * @return {*}
     * @Description: Do not Description
     */
    async deleteCart(item, flag = true) {
      try {
        let deleteCartRes = await this.$deleteCart({ id: item.quoteId, item_id: item.id });
        if (deleteCartRes.code == 200000) {
          if (deleteCartRes.data.quoteIsEmpty) {
            if (this.$route.path != "/cart") {
              this.$router.push({
                path: "/",
              });
            } else {
              this.$emit("changeHandle");
            }
          } else {
            this.$emit("changeHandle");
          }

          this.$toast({ type: "success", content: deleteCartRes.message });
          //datalayer
          let datalayerParam = {
            eventType: "remove",
            event_action: "productRemove",
            event_label: "quantity_minus",
            event_category: "cartCheckout",
            value: item.previewFinalPriceFormat,
            item_name: item.name,
            item_id: item.productId,
            item_category: item?.categories ? item?.categories[0].id : 0,
            price: item.previewFinalPriceFormat,
            quantity: item.qty,
          };
          this.$googleProductActionCart(datalayerParam);
          if (flag) {
            this.$refs.commonDialog.changeDialog();
          }
        }
      } catch (error) {
        throw new Error("删除商品" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 11:35:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 获取修改的属性
     * @param {*} params
     * @return {*}
     * @Description: Do not Description
     */
    updateCartDialog(params) {
      this.updateCart(params, "options");
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 09:51:55
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 修改qty数量
     * @param {*} item
     * @param {*} type + -
     * @return {*}
     * @Description: Do not Description
     */
    changeNumber(item, type) {
      // 购物车qty数量 datalayer
      let datalayerParam = {
        event_category: "cartCheckout",
        value: item.previewFinalPriceFormat,
        item_name: item.name,
        item_id: item.productId,
        item_category: item?.categories ? item?.categories[0].id : 0,
        price: item.previewFinalPriceFormat,
        quantity: item.qty,
      };

      try {
        let count = 0;
        let typeStr;
        count++;
        if (type == "-" && item.qty < 2) {
          this.changeDeleteDialog(item);
          return;
        }
        if (type == "+") {
          typeStr = "inc";
          datalayerParam.eventType = "add";
          datalayerParam.event_action = "productAdd";
          datalayerParam.event_label = "quantity_add";
        } else {
          typeStr = "dec";
          datalayerParam.eventType = "remove";
          datalayerParam.event_action = "productRemove";
          datalayerParam.event_label = "quantity_minus";
        }

        let params = {
          id: item.quoteId,
          item_id: item.id,
          type: typeStr,
          qty: count,
        };
        if (type == 1) {
          params = {
            id: item.quoteId,
            item_id: item.id,
            type: "",
            qty: this.qty,
          };
        }
        this.updateCart(params);

        this.$googleProductActionCart(datalayerParam);
      } catch (error) {
        throw new Error("修改qty数量" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 11:33:03
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 更新商品 购物车信息
     * @param {*} params
     * @param {*} changeType
     * @return {*}
     * @Description: Do not Description
     */
    async updateCart(params, changeType) {
      console.log(params, "ddd");
      try {
        let updateCartRes = await this.$updateCart(params);
        if (updateCartRes.code == 200000) {
          this.$emit("changeHandle");
          this.$toast({ type: "success", content: updateCartRes.message });
          if (changeType == "options") {
            this.$refs.optionsDialog.changeDialog();
          }
        }
      } catch (error) {
        throw new Error("更新商品 购物车信息" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 11:34:46
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 修改属性
     * @param {*} item
     * @param {*} index
     * @return {*}
     * @Description: Do not Description
     */
    changeProductOptions(item, index) {
      this.selectedOptionIdObj = [];
      this.qtyNum = item.qty;
      try {
        this.changeCartIndex = index;
        let params = { id: item.productId, simple: true };
        this.$productsDetail(params).then(({ code, data }) => {
          if (code == 200000) {
            this.productOptions = { ...data.product, quoteId: item.quoteId, itemId: item.id, qty: item.qty };
            // 拉起 option update弹窗 处理默认值
            // console.log(this.productOptions, "产品信息");
            let findex2 = null;
            item.variant.optionVariantName.split("-").forEach((itemName, index) => {
              item.variant.optionValueName.split("_").forEach((itemVal, index2) => {
                if (index == index2) {
                  let nowSelectOptions = data.product.options.filter((item) => {
                    if (item.optionName == itemName) {
                      return item;
                    }
                  });
                  // console.log(nowSelectOptions, "nowSelectOptionsnowSelectOptions");
                  nowSelectOptions[0]?.values.filter((item, findex) => {
                    if (item.optionValueTitle == itemVal) {
                      // console.log(findex, "ddd");
                      findex2 = findex;
                      return item;
                    }
                  });

                  this.$set(this.selectedOptionIdObj, index, findex2);
                  // this.showOptionName = optionName;
                }
              });
            });
            this.$nextTick(() => {
              // console.log(this.selectedOptionIdObj, "变化了吗");
              this.$refs.optionsDialog.changeDialog();
            });
          }
        });

        this.googleCustomeEventHanlder("cart", "click", "edit_item", "cartCheckout");
      } catch (error) {
        throw new Error("修改属性" + error);
      }
    },
    /**
     * @description: 添加到新愿单
     * @param {*} item
     * @return {*}
     */
    async changeWishtList(item) {
      if (!this.isLogin) {
        this.$refs.loginDialog.changeDialog();
        return;
      }
      try {
        let wishRes = await this.$addWishList({
          product_id: item.productId,
        });
        if (wishRes.code == 200000) {
          this.$toast({
            type: "success",
            content: wishRes.message,
          });
        }
      } catch (error) {
        console.log("购物添加心愿单" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(this.cartList, "购物车条目");
  },
  watch: {
    // 监测变化
  },
};
