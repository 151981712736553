var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-centerXY",class:{
    button: _vm.isbtn,
    'more-link': !_vm.isbtn,
    writeBg: _vm.isWhiteBg,
    'none-arrow': _vm.isNoneArrow,
    'icon-down': _vm.isIconDown,
    'icon-right': _vm.isIconRight,
    disableClick: _vm.isLoading || _vm.isDisabled,
    'search-btn': _vm.isSearch,
  },style:(_vm.customStyles),on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_vm._t("preInfo"),_vm._v(" "),(!_vm.isLoading)?[_vm._v(" "+_vm._s(_vm.text)+" ")]:[_c('LoadingAnimation')],_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }