//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    title: {
      type: String,
      default: () => "submit review",
    },
  },
  name: "Btn",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    handleEmit() {
      console.log("handleEmit");
      this.$emit("handleEmit");
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
