//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    dataList: {
      type: [Array, null],
      default: () => [],
    },
  },
  name: "BaseProductList",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-15 11:19:57
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 谷歌产品浏览点击 参数处理
     * @param {*} item
     * @param {*} index
     * @return {*}
     * @Description: Do not Description
     */
    googleProductParamHandler(item, index) {
      return {
        event_category: this.$route.name,
        event_label: this.$route.path,
        item_name: item.name,
        item_id: item.id,
        price: item.finalPrice,
        item_category: item.categories ? item?.categories[0]?.id : 0,
        item_list_name: this.$route.path,
        index: index,
      };
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 17:46:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:  产品点击 datalayer
     * @return {*}
     * @Description: Do not Description
     */
    googleSwiperProductClick(item, index) {
      let param = this.googleProductParamHandler(item, index);
      this.$googleCategroyListClick(param);
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 17:46:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:  产品浏览 datalayer
     * @return {*}
     * @Description: Do not Description
     */
    googleSwiperProductView(item, index) {
      let param = this.googleProductParamHandler(item, index);
      this.$googleCategroyListView(param);
    },
  },
  mounted() {
    // 页面进入时加载内容
    // console.log(this.dataList);
  },
  watch: {
    // 监测变化
  },
};
