//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    productOptions: {
      type: Object,
      default: () => null,
    },
  },
  name: "OptionsDialog",
  data() {
    return {
      isShowDialog: false,
      selectColorIndex: null, //选中的颜色索引
      selectedOptionIdObj: {}, //选中的option --主要用于显示
      selectOtherIndex: {},
      tempColorOptions: {},
      tempOtherOptions: [],
      qtyNum: 1, //qty 数量
      unSelectedOptionObj: [], //未选中的必选option --用于显示必选提示

      colorOptionsArr: [], //color的options
      colorOptionsShowNum: 10, //显示前10个color
      colorMoreBtnStatus: false, //默认false 未展开
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性

    selectColorItem() {
      // 颜色只有一个时，默认选中，获取对应的值
      let tempItem = null;
      if (this.productOptions?.hasOptions) {
        let colorList = this.productOptions.options.filter((item) => {
          if (/color/i.test(item.optionName)) {
            return item;
          }
        });
        if (colorList.length > 0 && colorList[0].values.length == 1) {
          tempItem = colorList[0].values[0];
        } else {
          tempItem = null;
        }
      }
      return tempItem;
    },

    selectedOptionPrice() {
      let selectedOptionIdList = [];
      for (let key in this.selectedOptionIdObj) {
        selectedOptionIdList.push(this.selectedOptionIdObj[key].id);
      }
      selectedOptionIdList.sort();
      let tempPriceObj = null;
      if (selectedOptionIdList.length > 0) {
        let tempIdStr = selectedOptionIdList.join("-");
        tempPriceObj = this.productOptions.allOptionVariants.find((item) => item.sortedOptionValueId == tempIdStr);
      }
      return tempPriceObj;
    }, //选择option后
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    ...mapActions("cart", ["getCartList"]),
    // 改变弹窗
    changeDialog() {
      this.isShowDialog = !this.isShowDialog;
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-17 17:35:14
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 选择颜色
     * @param {*} item
     * @param {*} index
     * @param {*} isOnly
     * @return {*}
     * @Description: Do not Description
     */
    selectColor(item, index, isOnly, colorName) {
      try {
        if (isOnly == 1) {
          this.selectColorIndex = 0;
        } else {
          this.selectColorIndex = index;
        }
        if (item.optionVariantQty == 0) {
          return;
        }
        this.tempColorOptions = {};
        if (this.selectColorIndex !== null) {
          this.tempColorOptions[`option_id`] = item.optionId;
          this.tempColorOptions[`option_value_id`] = item.id;
        }
        this.handlerDisableOption("length", item);
        this.$set(this.selectedOptionIdObj, colorName, item);

        // if (item.images) {
        //   this.swiperDataProduct = item.images.map((mItem) => ({ ...mItem, imgUrl: mItem.url, size: "710x948" }));
        // }
      } catch (error) {
        throw new Error("详情 选择颜色错误" + error);
      }
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-17 17:37:00
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:选择其他的属性
     * @param {*} optionIndex
     * @param {*} otherIndex
     * @return {*}
     * @Description: Do not Description
     */
    selectOtherOption(optionIndex, otherIndex) {
      try {
        this.$set(this.selectOtherIndex, optionIndex, `${otherIndex}`);
        this.tempOtherOptions = [];
        for (const i in this.selectOtherIndex) {
          let optionItem = this.productOptions.options[i];
          let tempItem = optionItem.values[this.selectOtherIndex[i]];
          if (tempItem.optionVariantQty == 0) {
            return;
          }
          this.$set(this.selectedOptionIdObj, optionItem.optionName, tempItem);
          if (/length/i.test(optionItem.optionName)) {
            this.handlerDisableOption("color", tempItem);
          }

          this.tempOtherOptions.push({
            option_id: tempItem.optionId,
            option_value_id: tempItem.id,
          });
        }
      } catch (error) {
        throw new Error("选择其他的属性 报错" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-17 17:39:13
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 处理option 库存数量
     * @param {*} selectedItem
     * @return {*}
     * @Description: Do not Description
     */
    handlerDisableOption(type, selectedItem) {
      try {
        this.productOptions.allOptionVariants.forEach((item) => {
          if (item.sortedOptionValueId.includes(selectedItem.id)) {
            if (type == "length") {
              this.productOptions.options.forEach((optionItem) => {
                if (/length/i.test(optionItem.optionName)) {
                  optionItem.values.forEach((valueItem) => {
                    if (item.sortedOptionValueId.includes(valueItem.id)) {
                      valueItem.optionVariantQty = item.optionVariantQty;
                    }
                  });
                }
              });
            } else if (type == "color") {
              this.colorOptionsArr.forEach((valueItem) => {
                if (item.sortedOptionValueId.includes(valueItem.id)) {
                  valueItem.optionVariantQty = item.optionVariantQty;
                }
              });
            }
          }
        });
      } catch (error) {
        throw new Error("处理option 库存数量" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-20 11:01:21
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:qty 操作 增减
     * @param {*} type 1为增 -1为减
     * @return {*}
     * @Description: Do not Description
     */
    qtyChange(type) {
      if (this.qtyNum == 1 && type == -1) {
        return;
      }
      this.qtyNum += type;
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-23 11:08:11
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:加购事件
     * @return {*}
     * @Description: Do not Description
     */
    async addCart() {
      try {
        let options = [];
        if (Object.keys(this.tempColorOptions).length > 0) {
          options.push(this.tempColorOptions);
        } else {
          if (this.selectColorItem) {
            this.tempColorOptions = {};
            this.tempColorOptions[`option_id`] = this.selectColorItem.optionId;
            this.tempColorOptions[`option_value_id`] = this.selectColorItem.id;
            options.push(this.tempColorOptions);
          }
        }
        let params = {
          product_id: this.productOptions.id,
          qty: this.qtyNum,
        };
        //如果有option 赋值tempOtherOptions
        //params.options 保存现有已选择的
        if (this.productOptions.hasOptions) {
          params.options = this.tempOtherOptions ? [...options, ...this.tempOtherOptions] : options;
        }
        //判断必选属性是否选择
        if (this.productOptions.hasOptions && this.productOptions.options[0].isRequire) {
          let tempRequire = this.productOptions.options.filter((item) => {
            if (item.isRequire) {
              return item.id;
            }
          });
          console.log("2222");
          //获取当前未选取的option
          this.unSelectedOptionObj = [];
          tempRequire.forEach((rItem) => {
            let isHas = params?.options.some((sItem) => sItem.option_id == rItem.id);
            if (!isHas) {
              this.unSelectedOptionObj.push(rItem);
            }
          });
          console.log(this.unSelectedOptionObj);
          if (this.unSelectedOptionObj.length > 0) {
            this.$toast({ type: "fail", content: "" });
            return;
          }
        }
        // 判断当前选择的option 是否有库存
        if (params.options) {
          let optionIdArr = params.options.map((item) => item.option_value_id);
          let optionIdStr = optionIdArr.sort().join("-");
          let tempVal = this.productOptions.allOptionVariants.some((item) => item.sortedOptionValueId == optionIdStr && item.optionVariantQty == 0);
          if (tempVal) {
            this.$toast("Please select the product options");
            return;
          }
        }
        console.log(params);
        let addCartRes = await this.$addCart(params);
        if (addCartRes.code == 200000) {
          this.$toast({ type: "success", content: addCartRes.message });
          //显示购物车弹窗
          // this.$refs.detailAddToCart.changeDialog();
          this.getCartList();
          this.changeDialog();
          this.$emit("addCartSuccess");
          this.resetData();
        }
      } catch (error) {
        throw new Error("快速弹窗加购事件" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-10 15:19:32
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 显示更多color
     * @return {*}
     * @Description: Do not Description
     */
    toggleShowColorHandle() {
      this.colorMoreBtnStatus = !this.colorMoreBtnStatus;
      let showColorLength;
      if (this.colorMoreBtnStatus) {
        //显示更多color
        showColorLength = this.colorOptionsArr.length;
      } else {
        showColorLength = this.colorOptionsShowNum;
      }
      this.colorOptionsArr = this.colorOptionsArr.map((item, index) => ({
        ...item,
        isShow: index >= showColorLength ? false : true,
      }));
    },

    resetData() {
      setTimeout(() => {
        this.selectColorIndex = null;
        this.selectedOptionIdObj = {};
        this.selectOtherIndex = {};
        this.tempColorOptions = {};
        this.tempOtherOptions = [];
        this.colorOptionsArr = [];
        this.colorMoreBtnStatus = false;
      }, 500);
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
    //选中color的变化
    selectColorItem: {
      handler(val) {
        if (val && process.client) {
          this.$set(this.selectedOptionIdObj, "Color", val);
          this.handlerDisableOption("color", val);
        }
      },
      immediate: true,
    },
    productOptions: {
      handler() {
        let nowColorOptions =
          this.productOptions?.options?.filter((item) => {
            return /color/i.test(item.optionName);
          }) || [];
        if (nowColorOptions.length > 0) {
          this.colorOptionsArr = nowColorOptions[0].values.map((item, index) => ({
            ...item,
            isShow: index >= this.colorOptionsShowNum ? false : true,
          }));
        }
      },
    },
    deep: true,
    immediate: true,
  },
};
