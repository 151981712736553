//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    title: {
      type: String,
      default: () => "VIEW MORE",
    },
    moreLink: {
      type: String,
      default: () => "",
    },
  },
  name: "ViewMoreBtn",
  data() {
    return {};
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    more() {
      this.$emit("more");
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(this.title, this.link);
  },
  watch: {
    // 监测变化
  },
};
