//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    // addressInfo: {
    //   type: Object,
    //   default: () => { },
    // },
    // addressInfoBilling: {
    //   type: Object,
    //   default: () => { },
    // },
    // sourceType: {
    //   type: String,
    //   default: () => "add",
    // }, // add---》添加 shipping--->修改shipping billing --->修改billing
  },
  data() {
    return {
      shippingList: [],
      billingList: [],
      isShowDialogShipping: false,
      isShowDialogBilling: false,
      sourceType: "add",
      titleContent: "Billing Address",
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters("user", ["isLogin", "currency", "getUserInfo"]),
    ...mapGetters("address", ["addressInfo", "billingAddressInfo", "isEdit"]),

    addressList() {
      if (this.addressInfo?.city && this.billingAddressInfo?.city) {
        return [this.addressInfo, this.billingAddressInfo];
      }
      return [];
    },
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapMutations("address", ["setAddressInfo", "setBillingAddressInfo", "setConfirmFormValid", "setConfirmFormValidBilling", "setIsEdit"]),
    // 方法
    changeDialog() {
      this.isShowListDialog = !this.isShowListDialog;

      // if (!this.isShowDialog) {
      //   //对于编辑不保存的请求，回显数据
      //   this.setAddressInfo({});
      //   this.setBillingAddressInfo({});
      // }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-06 16:00:29
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 获取地址
     * @param {*} addressType
     * @return {*}
     * @Description: Do not Description
     */
    async getAddressList(addressType) {
      try {
        let addressListRes = await this.$addressList({ type: addressType });
        if (addressListRes.code == 200000) {
          if (addressType == "shipping") {
            this.shippingList = addressListRes.data;
            this.shippingList = this.shippingList.map((item) => ({
              ...item,
              isActive: this.addressInfo.id == item.id ? true : false,
            }));
            // console.log(this.shippingList);
          }
          if (addressType == "billing") {
            this.billingList = addressListRes.data;
            this.billingList = this.billingList.map((item) => ({
              ...item,
              isActive: this.billingAddressInfo.id == item.id ? true : false,
            }));
          }
        }
      } catch (error) {
        throw new Error("地址簿获取地址" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-07 16:54:09
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: // 新增地址
     * @return {*}
     * @Description: Do not Description
     */
    toSaveAddress() {
      this.$refs["customerImageDialog"].changeDialog();
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-07 16:54:34
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: //编辑地址 edit
     * @param {*} item 全部参数 用于给编辑弹窗传值
     * @param {*} addressType shipping  billing
     * @return {*}
     * @Description: Do not Description
     */
    editAddress(item, addressType) {
      // this.changeDialog();
      this.setIsEdit(true);
      this.isShowDialogShipping = !this.isShowDialogShipping;
      if (addressType == "shipping") {
        this.sourceType = "shipping";
        this.titleContent = "Shipping Address";
        // this.setAddressInfo(item);
        // this.$refs.shippingAddressSlotDialog.changeDialog();
      } else {
        this.sourceType = "billing";
        this.titleContent = "Billing Address";
        // this.setBillingAddressInfo(item);
        // this.$refs.billingAddressSlotDialog.changeDialog();
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-07 16:55:32
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: //删除
     * @param {*} item
     * @param {*} addressType
     * @return {*}
     * @Description: Do not Description
     */
    async deleteAddress(item, addressType) {
      try {
        let deleteRes = await this.$deleteShippingAddress(item);
        if (deleteRes.code == 200000) {
          this.$toast({ type: "success", content: deleteRes.message });

          this.getAddressList(addressType);
        }
      } catch (error) {
        throw new Error("地址簿删除事件" + error);
      }
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-07 14:02:02
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 编辑成功之后的回调
     * @return {*}
     * @Description: Do not Description
     */
    editAddressSuccessHandle(type) {
      try {
        this.$parent.createOrder();
        if (type == "action") {
          //为action的时候 才关闭
          this.isShowDialogShipping = false;
        }
      } catch (error) {
        throw new Error("编辑成功之后的回调地址簿" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
