//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
    swiperOption: {
      type: Object,
      default: () => ({
        pagination: {
          el: ".custom-pagination",
          clickable: true,
        },
        paginationClickable: true,
        preventClicks: true,
        loop: false,
        autoplayDisableOnInteraction: false,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: "1%",
        navigation: {
          nextEl: ".myswiper-button-next",
          prevEl: ".myswiper-button-prev",
        },
        on: {},
      }),
    },
    swiperData: {
      type: [Array, Object],
      default: () => [] || {},
    },
    swiperStyle: {
      type: Object,
      default: () => ({}),
    },
    customPagination: {
      type: Boolean,
      default: () => false,
    },
    viewMoreTitle: {
      type: String,
      default: () => "VIEW MORE",
    },
    viewMoreLink: {
      type: String,
      default: () => "",
    },
    viewMoreStatus: {
      type: Boolean,
      default: () => false,
    },
    backgroundStyle: {
      type: String,
      default: () => "#fff",
    },
    typePage: {
      type: [Number, String, null],
      default: null,
    },
    headTitle: {
      type: String,
      default: () => "YOU MAY LIKE",
    },
    limit: {
      type: Number,
      default: () => 12,
    },
    catalogToLinkId: {
      type: Number,
      default: () => null,
    },
    productType: {
      type: String,
      default: () => "normal",
    },
    swiperBtnSolt: {
      type: Boolean,
      default: () => false,
    },
  },
  name: "SwiperProduct",
  data() {
    return {
      recommendDataList: [],
      pageInfo: {
        page: 1,
        limit: this.limit,
        total: 0,
      },
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    productList() {
      return this.swiperData.length > 0 ? this.swiperData : this.recommendDataList.length > 0 ? this.recommendDataList : [];
    },
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    clickMore() {
      this.$emit("clickMore");
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-02-27 13:47:19
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:根据页面获取推荐商品
     * @return {*}
     * @Description: Do not Description
     */
    async getRecommendData() {
      let { code, data } = await this.$alsoLike({ type: this.typePage, page: this.pageInfo.page, limit: this.pageInfo.limit });
      if (code == 200000) {
        this.recommendDataList = data;
      }
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-22 10:01:39
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 根据产品id 获取栏目信息
     * @param {*} id
     * @return {*}
     * @Description: Do not Description
     */
    async getCatalogInfoHandler(id) {
      try {
        let { code, data } = await this.$categoriesDetail(id);
        if (code == 200000) {
          this.handleJump(data.category.urlPath);
        }
      } catch (error) {
        throw new Error("swiperproduct 根据产品id 获取栏目信息" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-22 11:35:13
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:
     * @return {*}
     * @Description: Do not Description
     */
    viewMoreClickHandler() {
      if (this.catalogToLinkId) {
        this.getCatalogInfoHandler(this.catalogToLinkId);
      } else if (this.viewMoreLink != "") {
        this.handleJump(this.viewMoreLink);
      } else if (this.viewMoreLink == "") {
        this.$emit("viewMoreClickHandler");
      }
      //view more btn 点击 datalayer
      this.googleCustomeEventHanlder(`${this.headTitle}::more`, "click", "view_more");
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-15 11:19:57
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 谷歌产品浏览点击 参数处理
     * @param {*} item
     * @param {*} index
     * @return {*}
     * @Description: Do not Description
     */
    googleProductParamHandler(item, index) {
      return {
        event_category: this.$route.name,
        event_label: this.headTitle,
        item_name: item.name,
        item_id: item.id,
        price: item.finalPrice,
        item_category: item.categories ? item?.categories[0]?.id : 0,
        item_list_name: this.headTitle,
        index: index,
      };
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 17:46:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:  产品点击 datalayer
     * @return {*}
     * @Description: Do not Description
     */
    googleSwiperProductClick(item, index) {
      let param = this.googleProductParamHandler(item, index);
      this.$googleCategroyListClick(param);
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-14 17:46:53
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description:  产品浏览 datalayer
     * @return {*}
     * @Description: Do not Description
     */
    googleSwiperProductView(item, index) {
      let param = this.googleProductParamHandler(item, index);
      this.$googleCategroyListView(param);
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(this.productList, "before after");
  },
  watch: {
    // 监测变化
    typePage: {
      handler(val) {
        if (val && process.client) {
          this.getRecommendData();
        }
      },
      immediate: true,
    },
  },
};
