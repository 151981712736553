import { render, staticRenderFns } from "./CartItemList.vue?vue&type=template&id=ef439326&scoped=true&"
import script from "./CartItemList.vue?vue&type=script&lang=js&"
export * from "./CartItemList.vue?vue&type=script&lang=js&"
import style0 from "./CartItemList.vue?vue&type=style&index=0&id=ef439326&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef439326",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OptionsProductDialog: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/OptionsProductDialog.vue').default,CommonDialog: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pagePC/components/CommonDialog.vue').default})
