//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  head() {
    return {
      ...this.seoInfo["user/setting"],
    };
  },
  props: {
    // 父辈向子辈传参
  },
  name: "index",
  data() {
    return {
      avatarPath: "",
      commonDialogContentText: "",
      commonDialogCconfirmText: "",
      commonDialogCancelText: "",
      commonDialogType: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, "totto");
    console.log(from, "from,from");
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters(["seoInfo"]),
    ...mapGetters("user", ["getUserInfo"]),
    avatar() {
      if (this.getUserInfo && this.getUserInfo.avatar != "" && this.avatarPath == "") {
        return this.getUserInfo.avatar;
      } else {
        return this.avatarPath;
      }
    },
    fullName() {
      if (this.getUserInfo && (this.getUserInfo.firstname || this.getUserInfo.lastname)) {
        return this.getUserInfo.firstname + " " + this.getUserInfo.lastname;
      }
      return "velvet new";
    },
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    ...mapActions("user", ["loginOut", "getUserInfoAll"]),
    ...mapMutations("cart", ["setCartTotal"]),
    logOut() {
      this.loginOut();
      this.setCartTotal(0);
      this.$router.push("/user/logout");
      console.log("退出登陆");
    },

    async upLoadAvatar(e) {
      let file = e.target.files[0];
      if (file.type.indexOf("image") != -1 && file.size > 2 * 1024 * 1024) {
        this.$toast("Maximum upload file size: 2M");
        return;
      }
      let formdata = this.handlerImage(file);
      console.log(formdata);
      try {
        let res = await this.$upload(formdata);
        console.log(res);
        if (res.code == 200000) {
          await this.$editorAvatar({ avatar: res.data.path });
          this.avatarPath = res.data.path;
          this.getUserInfoAll();
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 处理上传的图片
     */

    handlerImage(file) {
      let formData = new FormData();
      let type = file.type;
      formData.append("file", file);
      if (type.indexOf("image") != -1) {
        formData.append("type", "image");
        formData.append("mod", "avatar");
      }
      return formData;
    },
    /**
     * @description: 删除确认按钮
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-18 09:00:36
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    comfirmDialog() {
      try {
        if (this.commonDialogType == "deleteSingle") {
          this.logOut();
        }
      } catch (error) {
        throw new Error("wishlist确认弹窗--确认" + error);
      }
    },
    /**
     * @description: 唤起确认框
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-22 11:38:39
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    changeDeleteDialog() {
      this.$refs.commonDialog.changeDialog();
      this.commonDialogType = "deleteSingle";
      this.commonDialogContentText = "Are you sure to log out?";
      this.commonDialogCconfirmText = "Yes";
      this.commonDialogCancelText = "No";
      this.googleCustomeEventHanlder("log out", "click", "info_setting", "Account");
    },
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
