//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from "vuex";
import SlideUpDown from "vue-slide-up-down";
import { countryList } from "~/static/js/areaInfo";
export default {
  props: {
    // 父辈向子辈传参
    // addressInfo: {
    //   type: Object,
    //   default: () => { },
    // },
    // addressInfoBilling: {
    //   type: Object,
    //   default: () => { },
    // },
    titleContent: {
      type: String,
      default: () => "",
    },
    sourceType: {
      type: String,
      default: () => "add",
    }, // add---》添加 shipping--->修改shipping billing --->修改billing
    pathFlag: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      formDate: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        country: "",
        province: "",
        provinceId: "",
        city: "",
        address: "",
        postalcode: "",
      },
      formDateBilling: {
        // billing
        emailBilling: "",
        firstnameBilling: "",
        lastnameBilling: "",
        phoneBilling: "",
        countryBilling: "",
        provinceBilling: "",
        provinceIdBilling: "",
        cityBilling: "",
        addressBilling: "",
        postalcodeBilling: "",
      },
      // formValid: false, //表单验证是否通过的标记
      // formValidBilling:true,//
      countryList: [], //国家列表
      regionsList: [], //周列表
      regionsListBilling: [],
      defaultBillingAddress: true, // 默认billing地址和shipping一样
      editDefaultBillingAddress: true, //编辑shippingaddress时 是否默认
      editDefaultShippingAddress: true, //编辑 billing adress时 是否默认
      // errors:this.$validator.errors,
    };
  },
  created() {
    // 实例被创建之后执行代码
    if (this.sourceType == "billing" || this.sourceType == "shipping") {
      this.initDate();
    }
  },
  computed: {
    // 计算属性
    ...mapGetters("user", ["isLogin"]),
    ...mapGetters("cart", ["checkoutProducts"]),
    ...mapGetters("address", ["addressInfo", "billingAddressInfo", "confirmFormValid", "confirmFormValidBilling", "isEdit"]),

    saveAddressAction() {
      return (
        this.formDate.country +
        this.formDate.province +
        this.formDate.provinceId +
        this.formDate.postalcode +
        this.formDateBilling.countryBilling +
        this.formDateBilling.provinceBilling +
        this.formDateBilling.provinceIdBilling +
        this.formDateBilling.postalcodeBilling
      );
    },
  },
  components: {
    // 组件的引用
    SlideUpDown,
  },
  methods: {
    ...mapMutations("address", ["setAddressInfo", "setBillingAddressInfo", "setConfirmFormValid", "setConfirmFormValidBilling", "setIsEdit"]),
    // 方法firstname
    initDate() {
      if (this.addressInfo) {
        this.formDate.firstname = this.addressInfo.firstname;
        this.formDate.lastname = this.addressInfo.lastname;
        this.formDate.email = this.addressInfo.email;
        this.formDate.phone = this.addressInfo.telephone;
        this.formDate.country = this.addressInfo.countryId;
        this.formDate.province = this.addressInfo.region;
        this.formDate.provinceId = this.addressInfo.regionId;
        this.formDate.city = this.addressInfo.city;
        this.formDate.address = this.addressInfo.street;
        this.formDate.postalcode = this.addressInfo.postcode;
      }
      if (this.billingAddressInfo) {
        this.formDateBilling.firstnameBilling = this.billingAddressInfo.firstname;
        this.formDateBilling.lastnameBilling = this.billingAddressInfo.lastname;
        this.formDateBilling.emailBilling = this.billingAddressInfo.email;
        this.formDateBilling.phoneBilling = this.billingAddressInfo.telephone;
        this.formDateBilling.countryBilling = this.billingAddressInfo.countryId;
        this.formDateBilling.provinceBilling = this.billingAddressInfo.region;
        this.formDateBilling.provinceIdBilling = this.billingAddressInfo.regionId;
        this.formDateBilling.cityBilling = this.billingAddressInfo.city;
        this.formDateBilling.addressBilling = this.billingAddressInfo.street;
        this.formDateBilling.postalcodeBilling = this.billingAddressInfo.postcode;
      }
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-06-05 10:46:33
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 获取州的数据
     * @param {*} 国家id
     * @return {*}
     */
    async handleCountryChange(params) {
      try {
        let param = {
          country_id: params == "shipping" ? this.formDate.country : this.formDateBilling.countryBilling,
        };
        console.log(params, param, "zzzzz");
        if (!param.country_id) {
          return;
        }
        let { code, data } = await this.$regionsList(param);
        if (code == 200000) {
          //默认选中第1个
          if (params == "shipping") {
            this.regionsList = data;
          } else {
            this.regionsListBilling = data;
            console.log(this.regionsListBilling, "billingdizh");
          }
        }
      } catch (error) {
        new Error("选中国家改变 事件 获取地区列表" + error);
      }
    },
    async showBilling() {
      this.defaultBillingAddress = !this.defaultBillingAddress;
    },
    async AddressVerification() {
      let result = await this.$validator.validateAll(["firstname", "lastname", "email", "telephone", "Country", "street", "code", "city", "state"]);
      console.log(result, "挂载的时候");
      this.setConfirmFormValid(result);
      if (result) {
        this.setAddressInfo(this.formDate);
      }
    },
    // 编辑保存提交
    submitHandler(action) {
      //校验
      if (this.confirmFormValid && this.confirmFormValidBilling) {
        this.saveAddressHandler(action);
      }
    },

    // 保存
    async saveAddressHandler(action) {
      try {
        let paramsShipping = {
          email: this.formDate.email || this.getUserInfo?.email || this.$cookies.get("userInfo")?.user?.email,
          telephone: this.formDate.phone,
          firstname: this.formDate.firstname,
          lastname: this.formDate.lastname,
          country_id: this.formDate.country,
          region: this.formDate.province,
          region_id: this.formDate.provinceId,
          city: this.formDate.city,
          street: this.formDate.address,
          postcode: this.formDate.postalcode,
          suite: this.suite,
        };
        let paramsBilling = {
          email: this.formDateBilling.emailBilling || this.getUserInfo?.email || this.$cookies.get("userInfo")?.user?.email,
          telephone: this.formDateBilling.phoneBilling,
          firstname: this.formDateBilling.firstnameBilling,
          lastname: this.formDateBilling.lastnameBilling,
          country_id: this.formDateBilling.countryBilling,
          region: this.formDateBilling.provinceBilling,
          region_id: this.formDateBilling.provinceIdBilling,
          city: this.formDateBilling.cityBilling,
          street: this.formDateBilling.addressBilling,
          postcode: this.formDateBilling.postalcodeBilling,
          suite: this.formDateBilling.suiteBilling,
        };
        console.log(paramsBilling, "paramsBilling");
        if (this.regionsList.length > 0) {
          paramsShipping.region_id = this.formDate.provinceId;
        }
        if (this.regionsListBilling.length > 0) {
          paramsBilling.region_id = this.formDateBilling.provinceIdBilling;
        }
        let saveAddressRes;
        if (this.sourceType == "add") {
          paramsShipping.quote_id = this.checkoutProducts[0].quoteId;
          paramsShipping.save_in_address_book = 1;
          paramsShipping.same_as_billing = this.defaultBillingAddress ? 1 : 0;
          saveAddressRes = await this.$saveConfirmOrderShipping(paramsShipping);
          if (!this.defaultBillingAddress) {
            //billing 不使用shipping地址
            paramsBilling.quote_id = this.checkoutProducts[0].quoteId;
            saveAddressRes = await this.$saveConfirmOrderBilling(paramsBilling);
            // this.setBillingAddressInfo(paramsBilling);
          }
          //成功之后
          if (saveAddressRes.code == 200000) {
            this.$emit("submitSuccess");
            this.setAddressInfo(paramsShipping);
          }
        } else if (this.sourceType == "shipping") {
          //当 编辑shiiping 时
          paramsShipping.quote_id = this.checkoutProducts[0].quoteId;
          this.saveBookAddress("shipping", paramsShipping, action);
          console.log("编辑a");
        } else if (this.sourceType == "billing") {
          //当 billing 编辑时
          paramsBilling.quote_id = this.checkoutProducts[0].quoteId;
          this.saveBookAddress("billing", paramsBilling, action);
        }
        this.googleCustomeEventHanlder("continue to shipping", "productCheckoutProgress", "add_shipping_addr", "fireCheckout");
      } catch (error) {
        throw new Error("保存地址事件" + error);
      }
    },

    /**
     * @Date: 2023-06-15 14:31:04
     * @Description: 编辑地址，保存编辑
     * @param {*} type
     * @param {*} params
     * @return {*}
     */
    async saveBookAddress(type, params, action) {
      try {
        let saveAddressRes;
        if (type == "shipping") {
          params.same_as_billing = this.editDefaultBillingAddress ? 1 : 0;
          saveAddressRes = await this.$saveConfirmOrderShipping(params);
        } else if (type == "billing") {
          saveAddressRes = await this.$saveConfirmOrderBilling(params);
        }
        if (saveAddressRes.code == 200000) {
          this.$emit("submitSuccess", action);
          this.$toast({ type: "success", content: saveAddressRes.message });
        }
      } catch (error) {
        throw new Error("地址薄 保存处理" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    // 获取所有的州
    this.countryList = countryList;
    this.AddressVerification();
  },
  watch: {
    // 监测变化
    // errors: {
    //   handler(val) {
    //     console.log(val, "错误");
    //     if (val.items.length == 0) {
    //       this.setConfirmFormValid(true);
    //       this.setConfirmFormValidBilling(true);
    //     } else {
    //       val.items.forEach((item) => {
    //         if (item.field.includes("Billing")) {
    //           this.setConfirmFormValidBilling(true);
    //         } else {
    //           this.setConfirmFormValid(false);
    //         }
    //       });
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    defaultBillingAddress: {
      async handler(val) {
        if (val) {
          this.setConfirmFormValidBilling(true);
        } else {
          let result = await this.$validator.validateAll([
            "firstnameBilling",
            "lastnameBilling",
            "telephoneBilling",
            "countryBilling",
            "streetBilling",
            "codeBilling",
            "cityBilling",
            "stateBilling",
            "emailBilling",
          ]);
          if (result) {
            this.setConfirmFormValidBilling(true);
          } else {
            this.setConfirmFormValidBilling(false);
          }
        }
      },
    },
    "formDate.country": {
      handler() {
        //检测country的值是否发生变化,如果发生变化,重新获取州的数据
        this.handleCountryChange("shipping");
      },
    },
    "formDateBilling.countryBilling": {
      handler() {
        //检测country的值是否发生变化,如果发生变化,重新获取州的数据
        this.handleCountryChange("billing");
      },
    },
    "formDateBilling.provinceIdBilling": {
      handler(val) {
        if (this.regionsListBilling.length > 0 && val) {
          let nowProvice = this.regionsListBilling.filter((item) => {
            return item.regionId == val;
          });
          console.log(nowProvice, "nowProvice");
          if (nowProvice.length > 0) {
            this.formDateBilling.provinceBilling = nowProvice[0].defaultName;
          }
        }
      },
      deep: true,
    },
    "formDate.provinceId": {
      handler(val) {
        if (this.regionsList.length > 0 && val) {
          let nowProvice = this.regionsList.filter((item) => {
            return item.regionId == val;
          });

          if (nowProvice.length > 0) {
            this.formDate.province = nowProvice[0].defaultName;
          }
        }
      },
      deep: true,
    },
    sourceType: {
      handler(val) {
        if (val == "billing") {
          this.defaultBillingAddress = false;
        }
        if (val == "add") {
          this.formDate.firstname = "";
          this.formDate.lastname = "";
          this.formDate.email = "";
          this.formDate.phone = "";
          this.formDate.country = "";
          this.formDate.province = "";
          this.formDate.provinceId = "";
          this.formDate.city = "";
          this.formDate.address = "";
          this.formDate.postalcode = "";

          this.formDateBilling.firstnameBilling = "";
          this.formDateBilling.lastnameBilling = "";
          this.formDateBilling.emailBilling = "";
          this.formDateBilling.phoneBilling = "";
          this.formDateBilling.countryBilling = "";
          this.formDateBilling.provinceBilling = "";
          this.formDateBilling.provinceIdBilling = "";
          this.formDateBilling.cityBilling = "";
          this.formDateBilling.addressBilling = "";
          this.formDateBilling.postalcodeBilling = "";
        }
      },
      deep: true,
      immediate: true,
    },
    // billingAddressInfo:{
    //   handler(val){
    //     if(!val.city){
    //       this.setConfirmFormValidBilling(true)
    //     }

    //   },
    //   deep: true,
    // },
    saveAddressAction: {
      //检测计算属性
      handler(val) {
        //检测的值是否发生变化,如果发生变化,重新保存数据
        if (this.isEdit) {
          this.setIsEdit(false);
          return false;
        }
        if (val && !this.pathFlag) {
          this.saveAddressHandler();
        }
      },
    },

    formDate: {
      async handler() {
        await this.$validator.validateAll(["email", "firstname", "lastname", "telephone", "Country", "street", "code", "city", "state"]).then((result) => {
          console.log(result, "监听到了吗");
          if (result) {
            this.setAddressInfo(this.formDate);
            this.setConfirmFormValid(true);
          } else {
            this.setConfirmFormValid(false);
          }
        });
      },
      deep: true,
      immediate: true,
    },
    formDateBilling: {
      async handler() {
        await this.$validator
          .validateAll([
            "firstnameBilling",
            "lastnameBilling",
            "telephoneBilling",
            "CountryBilling",
            "streetBilling",
            "codeBilling",
            "cityBilling",
            "stateBilling",
            "emailBilling",
          ])
          .then((result) => {
            this.setConfirmFormValidBilling(result);
            if (result) {
              console.log(result, "resultresultresult");
              this.setBillingAddressInfo(this.formDateBilling);
            }
          });
      },
      deep: true,
      immediate: true,
    },
  },
};
