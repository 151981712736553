//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    data: {
      type: Array,
      default: () => [],
    },
    solt: {
      type: Boolean,
      default: () => false,
    },
    slideType: {
      type: String,
      default: () => "slideToggle",
    },
    fullscreen: {
      type: Boolean,
      default: () => true,
    },
  },
  name: "PreviewGalleryImgDialog",
  data() {
    return {
      firstSwiperOption: {
        pagination: {
          el: ".custom-pagination",
          clickable: true,
          type: "fraction",
        },
        preventClicks: true,
        speed: 1000,
        loop: false,
        autoplayDisableOnInteraction: false,
        on: {},
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        spaceBetween: 10,
        navigation: {
          nextEl: ".myswiper-button-next",
          prevEl: ".myswiper-button-prev",
        },
      },
      isShowDialog: false,
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters("user", ["isLogin"]),
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法
    changeDialog(param) {
      if (param) {
        this.isShowDialog = !param;
        return;
      }
      this.isShowDialog = !this.isShowDialog;
    },
    openDialog() {
      this.isShowDialog = true;
    },
    closeDialog() {
      this.isShowDialog = false;
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-13 13:54:03
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 点赞
     * @param {*} item
     * @return {*}
     * @Description: Do not Description
     */
    async changeLikeHandler(item, type) {
      // if (!this.isLogin) {
      //   this.$parent.$parent.$refs.loginDialog.changeDialog();
      //   return;
      // }
      try {
        let res = await this.$reviewItemLike(item.id);

        if (res.code == 200101) {
          this.$toast(res.message);
        } else {
          // this.$set(item, "isLike", 1);
          if (type == "like") {
            item.isLike = 1;
            item.likeNum += 1;
          } else {
            item.isLike = 0;
            if (item.likeNum > 0) {
              item.likeNum -= 1;
              return;
            }
            item.likeNum = 0;
          }
        }
      } catch (error) {
        throw new Error("详情页点赞" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(this.data, "传进来的图片");
  },
  watch: {
    // 监测变化
  },
};
